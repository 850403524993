// export  function changLang(lang) {
//   this.lang = localStorage.setItem("language",
//  lang)

// }

export const languages = {
  vi: {
    //bannner
    banner_web_1: "img/banner_new/Banner1/1-1920x200-V.jpg",

    banner_web_2: "img/banner_new/Banner1/2-1920x1080-V.jpg",

    // banner_web_3: "img/banner_new/Banner1/3-1920x200-V.jpg",

    

    banner_phone_1: "img/banner_new/Banner1/1-500x200-V.jpg",

    banner_phone_2: "img/banner_new/Banner1/2-500x200-V.jpg",

    Slide_docTiep: "Đọc tiếp",

    // // #top     menu dung chung
    header_text_gioiThieu: "Giới thiệu",

    header_text_QRAmThanh: "QR Âm thanh",

    header_text_ThuTienBanHang: "Thu hộ qua QR âm thanh",

    header_text_ThuTienQR: "Thu tiền qua QR âm thanh",

    header_text_huongDan: "Hướng dẫn",

    header_text_phiDichVu: "Phí dịch vụ",

    header_text_taiChinh: "Tài chính",

    header_text_lienHe: "Liên hệ",

    header_text_hopDong: "Hợp đồng",

    header_text_chuKySo: "Chữ ký số",

    header_text_hoaDonDienTu: "Hóa đơn điện tử",

    header_text_hopDongDienTu: "Hợp đồng điện tử",

    header_text_linhVuc: "Lĩnh vực",

    // // #top     menu chi tiet bai viet
    header_text_sanPham: "Sản phẩm",

    header_text_viDienTu: "Ví điện tử Vimass",

    header_text_theDaNangDauDoc: "Thẻ đa năng & đầu đọc",

    header_text_dichVu: "Dịch vụ",

    header_text_khachHang: "Khách hàng",

    header_text_qrThanhToan: "  QR Thanh toán",

    header_text_congThanhToan: "Cổng thanh toán",

    header_text_dichVuCong: "Dịch vụ công",

    header_text_nhanTienBangQRamThah:"Nhận tiền bằng QR âm thanh",
    header_text_banHangBangQRdong:"Bán hàng bằng QR động",
    header_text_thuChiHoQuaAPI:"Thu chi hộ qua API",
    header_text_giaiPhapChuyenDoiSo:"Ví điện tử, Điểm thanh toán, Điểm định danh",
    header_text_dinhDanhXacThuc:"Định danh, thanh toán không điện thoại",
    header_text_Gd_Yte_Gt_Dvc:"Giáo dục, Y tế, Giao thông, Dịch vụ công",
    header_text_diemThanhToan_DiemDinhDanh:"Điểm thanh toán, Điểm định danh",
    header_text_thietBi_Dichvu:"Máy chủ, thiết bị",




    // // #top     menu The Da Nang
    header_text_theDaNang_CTTaiCho: "Tài trợ",

    header_text_theDaNang_CTThiDiem: "Chương trình thí điểm",

    header_text_theDaNang_HTDonvi: "Hệ thống đơn vị",

    header_text_theDaNang_NganHangTaiCho: "Ngân hàng đồng hành",

    header_text_theDaNang_phoCap: "Phổ cập",

    header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho: "Bệnh viện Tỉnh",

    header_text_theDaNang_QuanHuyen: "Bệnh viện Huyện",

    header_text_theDaNang_phoCap_boyte: "Cơ sở y tế xã",

    header_text_theDaNang_phoCap_yte: " Y tế",

    header_text_theDaNang_TCNguoiNgheo: "Tài trợ huyện nghèo",

    header_text_theDaNang_HuyenNgheo: "74 Huyện nghèo",

    header_text_theDaNang_theDongThuongHieu: "Thẻ đồng thương hiệu",

    header_text_theDaNang_ctThiDiem: "Trường thí điểm",
    header_text_theDaNang_ctTaiTro: " Trường tài trợ",

    header_text_theDaNang_mamNonTieuHoc: "Tiểu học",

    header_text_theDaNang_thcsThpt: "THCS",

    header_text_theDaNang_Thpt: " THPT",

    header_text_theDaNang_daiHocCaoDang: "Cao đẳng",

    header_text_theDaNang_daiHoc: "Đại học",

    header_text_theDaNang_bvTinhTp: "Bệnh viện",

    header_text_theDaNang_bvBinhDuong: "Đa Khoa Bình Dương",

    header_text_theDaNang_dichViCong: "Dịch vụ công",

    header_text_theDaNang_daiDong: "THCS Đại Đồng",

    header_text_theDaNang_phoCap_phocapthedanang: "Phổ cập thẻ đa năng",

    header_text_theDaNang_phoCap_bogiaoduc: "Giáo dục",

    header_text_theDaNang_QuanHuyen_qThanhXuan: "Quận Thanh Xuân",

    header_text_theDaNang_QuanHuyen_xaDienBan: "Thị xã Điện Bàn",

    header_text_theDaNang_QuanHuyen_ThachThat: "Huyện Thạch Thất",

    header_text_theDaNang_QuanHuyen_xaSonTay: "Thị xã Sơn Tây",

    header_text_theDaNang_TCNguoiNgheo_CaoBang: "Tỉnh Cao Bằng",

    header_text_TraCuuThongKe: "Thống kê",

    header_text_saoKe: "Sao kê",

    header_text_chuyenDoiSo_QRdong: "QR động",

    header_text_chuyenDoiSo_caiDat: "Hệ thống",

    header_text_chuyenDoiSo_GiaiPhap: "Giải pháp",

    header_text_chuyenDoiSo_dichVu: "Dịch vụ",

    header_text_chuyenDoiSo_HTtudong: "HT tự động",

    header_text_gd: "Giao dịch",

    header_text_chuyenDoiSo_moHinhThanhToan: "Đơn vị thí điểm",

    // // #top     menu Diem Thanh Toan",

    header_text_diemThanhToan_ganNhat: "Gần nhất",

    header_text_diemThanhToan_cuaHang: "Cửa hàng",

    header_text_diemThanhToan_xangGas: "Xăng gaz",

    header_text_diemThanhToan_sucKhoe: "Y tế",

    header_text_diemThanhToan_cuaHangMau: "Cửa hàng mẫu",

    header_text_diemThanhToan_congTacVien: "Cộng tác viên",

    header_text_diemThanhToan_dienMay: "Điện máy",

    header_text_diemThanhToan_vanHoa: "Văn hóa",

    header_text_diemThanhToan_trucTuyen: "Trực tuyến",

    header_text_diemThanhToan_diChuyen: "Giao thông",

    header_text_diemThanhToan_anUong: "Ăn uống",

    header_text_diemThanhToan_giaoDuc: "Giáo dục",

    // // #top     menu Ung Dung",

    header_text_ungDung_gioithieu: "Giới thiệu",

    header_text_ungDung_theDaNang: "Thẻ Vimass",

    header_text_ungDung_gpChuyenDoiSo: "Giải pháp chuyển đổi số",
    header_text_ungDung_htThanhToan: "Hệ thống thanh toán",
    header_text_ungDung_htDinhDanh: "Hệ thống định danh",

    header_text_ungDung_vCard: "VCard",

    header_text_ungDung_vpass: "VPass",

    header_text_ungDung_viVimass: "Ví Vimass",

    header_text_ungDung_qrVietnam: "QR Vietnam",

    // # top menu diem ra vao
    header_text_ungDung_diemravao: "Điểm định danh",

    header_text_ungDung_mohinh: "Mô hình",

    header_text_ungDung_Khoadientu: "Khóa chốt điện từ",

    header_text_ungDung_Barrier: "Barrier",

    // // #top     menu Thiet Bi",

    header_text_thietBi_Maychu: "Hệ thống máy chủ",

    header_text_thietBi_thietBi: "Thiết bị",

    header_text_thietBi_daiLy: "Đại lý",

    header_text_thietBi_phanPhoi: "Phân phối",
 
    header_text_thietBi_htMayChu: "HT Máy chủ",
    header_text_thietBi_htThanhToan: "HT Thanh toán",

    header_text_thietBi_htVpass: "HT Định danh ",

    header_text_HeThongTruSo: "HT Trụ Sở",

    header_text_thietBi_htTraSoatThongKe: "HT Tra soát thống kê",

    header_text_thietBi_htHoaDonDienTu: "HT Hóa đơn điện tử",

    header_text_thietBi_htHopDongDienTu: "HT Hợp đồng điện tử",

    header_text_thietBi_htChuKySo: "HT Chữ ký số",

    // // #top     menu Phan Mem",

    header_text_phanMem_qlGiaoDich: "QL Giao dịch",

    header_text_phanMem_qlThongTin: "QL Thông tin",

    header_text_phanMem_qlToaDo: "QL Tọa độ",

    // // #top     menu Khach Hang",

    header_text_khachHang_khachHang: "Khách hàng",

    header_text_khachHang_doiTac: "Đối tác",

    header_text_khachHang_tuyenDung: "Tuyển dụng",

    header_text_khachHang_hoiDap: "Hỏi đáp",

    // // #top     menu Cong Ty",

    header_text_congTy_coDong: "Cổ đông",

    header_text_congTy_hoiDongQuanTri: "Hội đồng quản trị",

    header_text_congTy_banKiemSoat: "Ban kiểm soát",

    header_text_congTy_banDieuHanh: "Ban điều hành",

    header_text_congTy_chiNhanh: "Chi nhánh",

    // // #top     footer row_footer1",

    footer_text_row_footer1_nganHang: " NGÂN HÀNG NHÀ NƯỚC VIỆT NAM",

    footer_text_row_footer1_giayPhep:
      " Giấy phép trung gian thanh toán 41/GP-NHNN",

    footer_text_row_footer1_giayPhepXuatNhapKhau:
      "2. Giấy phép xuất khẩu, nhập khẩu sản phẩm mật mã dân sự",

    footer_text_row_footer1_giayPhepKinhDoanh:
      " 1. Giấy phép kinh doanh sản phẩm, dịch vụ mật mã dân sự",

    footer_text_row_footer1_downloadQrVietnam: " Tải ứng dụng QR Việt Nam",

    footer_text_row_footer1_downloadViViMass: " Tải ứng dụng Ví Vimass",

    footer_text_row_footer1_downloadVCard: " Tải ứng dụng VCard",

    footer_text_row_footer1_xemThem: "Xem thêm :",

    // // #top     footer",

    footer_text_tenCty: " CÔNG TY CỔ PHẦN DỊCH VỤ NỀN DI ĐỘNG VIỆT NAM",

    footer_text_hoiSoHaNoi:
      " Hội sở: Phòng 505, Tầng 5, Tòa nhà The Golden Palm, 21 Lê Văn Lương, Q.Thanh Xuân, Hà Nội - ĐT 0243.9416205, Email: cskh.vimass@gmail.com",
      footer_text_hoiSoHaNoiQRVN:
      " Hội sở: Phòng 506, Tầng 5, Tòa nhà The Golden Palm, 21 Lê Văn Lương, Q.Thanh Xuân, Hà Nội - ĐT 0243.9416205, Email: cskh.vimass@gmail.com",
    footer_text_cnHCM:
      "CN Hồ Chí Minh: Tầng 4, 121 Hoàng Hoa Thám, Phường 6, Q.Bình Thạnh - ĐT 0986609344",

    footer_text_cnDN:
      "CN Đà Nẵng: Tầng 9, 38 Yên Bái, Q.Hải Châu - ĐT 0918568518",

    footer_text_cnCT:
      "CN Cần Thơ: Tầng 4, 30A Hoàng Văn Thái, Q.Cái Răng - ĐT 0907978098",

    footer_text_cnHP:
      "CN Hải Phòng: Tầng 8, 68 Phạm Văn Đồng, Q.Dương Kinh - ĐT 0971937026",

    footer_text_cnHue: "CN Huế: 28 Lý Thường Kiệt, TP.Huế - ĐT 0932524546",

    footer_text_cnQN:
      "CN Quảng Ninh: Tầng 7, Toà Viettel, 70 Nguyễn Văn Cừ, TP. Hạ Long",

    footer_text_cnBDinh:
      "CN Bình Định: Số 37 Nguyễn Huệ, P.Hải Cảng, TP. Quy Nhơn - ĐT 0984922872",

    footer_text_cnNA:
      " CN Nghệ An: Tầng 14,Tháp A, Tòa tháp dầu khí Việt Nam, 7 Quang Trung, TP. Vinh - ĐT 0942707888",

    footer_text_cnTH:
      "CN Thanh Hóa:  Tầng 9, Toà nhà Golden Đức Phát, 9 Hạc Thành, P.Điện Biên, TP. Thanh Hoá - ĐT 0946469704",

    footer_text_cnBD:
      "CN Bình Dương:  Tầng 4 tòa nhà BW SCC Office, Khu liên hợp Công nghiệp - Dịch vụ - Đô thị Bình Dương, P.Hoà Phú, TP. Thủ Dầu Một",

    footer_text_ctyTrucThuoc: "CÔNG TY TRỰC THUỘC ViMASS",

    footer_text_ctyQRVN: "CÔNG TY CỔ PHẦN QR VIỆT NAM",

    footer_text_theDaNang: "Thẻ đa năng",

    footer_text_diemThanhToan: "Điểm thanh toán",

    footer_text_ungDung: "Ứng dụng",

    footer_text_thietBi: "Thiết bị",

    footer_text_phanMem: "Phần mềm",

    footer_text_dichVu: "Dịch vụ",

    footer_text_khachHang: "Khách hàng",

    footer_text_cty: "Công ty",

    footer_text_chuongTrinhTaiTro: "Chương trình tài trợ",

    footer_text_giaiPhapChuyenDoiSo: "Giải pháp chuyển đổi số",

    footer_text_diemGiaoDich: "Điểm giao dịch",

    footer_text_diemRaVao: "Điểm định danh",
    footer_text_banHangBangVietQR: "Bán hàng bằng VietQR",



    item_footer_0:"QR âm thanh",
    item_footer_1:"Thu chi hộ qua API",
    item_footer_2:"Ví & thẻ Vimass, Điểm thanh toán, Điểm định danh",
    item_footer_3:"Định danh, Xác thực không dùng điện thoại",
    item_footer_4:"Giáo dục",
    item_footer_5:"Y tế, Giao thông, Dịch vụ công",
    item_footer_6:"Máy chủ, Thiết bị, dịch vụ",
    item_footer_7:"Công ty",



    // # body_text_title",

    body_title_congty_codong: " CÔNG TY, CỔ ĐÔNG, GIẤY PHÉP",

    // # body_text_congty_codong",

    body_text_congty_codong_231027QuyetDinhThanhLapBanAnToanThongTin:
      "- Quy đinh về Hệ thống thông tin Vimass ngày 12/12/2023",

    body_text_congty_codong_23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le:
      "- Thông báo về việc đăng ký vốn điều lệ ngày 20/9/2023",

    body_text_congty_codong_23_9_5_Quyet_dinh_phan_cong_nhiem_vu:
      "- Quyết định phân công nhiệm vụ ngày 01/12/2023",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay20072023:
      "- Biên bản họp Đại hội cổ đông bất thường ngày 20/07/2023",

    body_text_congty_codong_NghiQuyetHoiDongQuanTri16032023:
      "- Nghị quyết HĐQT Vimass ngày 13/06/2023",

      body_text_congty_codong_BienbanhopHDQTVimassngay05082004:
      "- Biên bản họp HĐQT Vimass ngày 05/08/2024", 

    body_text_congty_codong_BienbanhopHDQTVimassngay13062023:
      "- Biên bản họp HĐQT Vimass ngày 06/07/2023",

    body_text_congty_codong_HoatDongKinhDoanh15032023:
      "- Hoạt động kinh doanh 6 tháng đầu năm ngày 11/07/2023",

    body_text_congty_Hoatdongkinhdoanh2024:
      "- Hoạt động kinh doanh năm 2024 (01/10/2024)",

    body_text_congty_Hoatdongkinhdoanh: "-  Hoạt động kinh doanh năm 2023",

    body_text_congty_codong_23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi:
      "- Quyết định về giá bán thiết bị ngày 03/07/2023",
      body_text_congty_codong_HoSoCapDoAnToanHTTTVIMASS: '- Hồ sơ cấp độ an toàn Hệ thống thông tin trụ sở Vimass 22/03/2024',
    body_text_congty_codong_Danhsachcodongvavongopngay04052023:
      "- Danh sách cổ đông và vốn góp ngày 03/10/2024",
      body_text_congty_codong_ChuCapHoiToanHoc:  
      "- Nghị quyết HĐQT về tài trợ Quỹ Giải thưởng Hội Toán học Việt Nam ngày 07/08/2024",
    body_text_congty_codong_Bien_ban_hop_HDQT:
      "- Biên bản họp Hội đồng quản trị ngày 26/01/2024",

      body_text_congty_codong_BienbanhopHDQTVimassngay17042023:
      "- Biên bản họp HĐQT Vimass ngày 17/04/2023",  

    body_text_congty_codong_phongchongruatien:
      "- Ban hành quy định về Phòng chống rửa tiền 01/05/2023",
      body_text_congty_codong_QDBanHanhbotieuchi:
      "- Quyết định ban hành bộ tiêu chí nhận diện các ví điện tử có dấu hiệu nghi ngờ gian lận, lừa đảo, vi phạm pháp luật ngày 18/07/2024",

      body_text_congty_codong_ThucHienQuyDinhChinhPhu:
      "- Quyết định ban hành thực hiện các quy định theo Chính phủ và NHNN 18/07/2024",

      body_text_congty_codong_ThucHienXoaTaiKhoanVi:
      "- Quyết định về việc xóa tài khoản Ví không hoạt động 01/01/2022",

      body_text_congty_codong_DVTGTT:
      "- QĐ của TGĐ về ban hành quy định về quản trị rủi ro cung ứng DVTGTT 18/07/2024",

    body_text_congty_codong_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      "- Quyết định về cộng tác viên tạo điểm thanh toán và phân phối thiết bị ngày 17/04/2023",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay07042023:
      "- Biên bản họp Đại hội đồng cổ đông thường niên 2023 ngày 07/04/2023",

      body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay25042024:
      "- Biên bản họp Đại hội đồng cổ đông thường niên 2024 ngày 25/04/2024",

      body_text_congty_codong_ThongtuNHNN:
      "- Thông tư 40/2024/TT-NHNN của Ngân hàng Nhà nước Việt Nam quy định về hoạt động cung ứng dịch vụ trung gian thanh toán 17/07/2024",

    body_text_congty_codong_BienbanhopHDQTVimassngay17032023:
      "- Biên bản họp HĐQT Vimass ngày 17/03/2023",

    body_text_congty_codong_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      "- Báo cáo hoạt động năm 2022, kết quả 2 tháng 2023 và kế hoạch 2023 ngày 14/03/2022",

    body_text_congty_codong_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      "- Hoạt động kinh doanh 2 tháng đầu năm 2023 ngày 14/03/2022",

    body_text_congty_codong_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      "- Báo cáo kiểm toán 2022, Công Ty Cổ Phần Dịch Vụ Nền Di Động Việt Nam ngày 08/03/2022",

    body_text_congty_codong_Baocaokiemtoan2023CongTyCoPhanDichVuNenDiDongVietNamn:
      "- Báo cáo kiểm toán 2023, Công ty cổ phần dịch vụ nền di động Việt Nam (11/03/2024)",

    body_text_congty_codong_Quyetdinhvenhomcongtacvienngay22022023:
      "- Quyết định về nhóm cộng tác viên ngày 22/02/2023",

    body_text_congty_codong_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      "- Hợp đồng cung cấp dịch vụ thu và chi hộ Vimass  QRVN ngày 02/02/2023",

    body_text_congty_codong_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "- Quyết định về phí dịch vụ tài chính của Vimass 01/02/2023",

    body_text_congty_codong_QuyetdinhtrienkhaithunghiemthedanangtaiVietNamngay1712023:
      "- Quyết định triển khai thử nghiệm thẻ đa năng tại Việt Nam ngày 17/1/2023",

    body_text_congty_codong_Danhsachcodongvavongopngay16012023:
      "- Danh sách cổ đông và vốn góp ngày 16/01/2023",

    body_text_congty_codong_QuyetdinhcapthevatemchocanbonhanvienVimassngay1612023:
      "- Quyết định cấp thẻ và tem cho cán bộ nhân viên Vimass ngày 16/1/2023",

    body_text_congty_codong_BienbanthoathuansudungtenvalogocuaHoihuunghiVietNamHungaryngay09122022:
      "- Biên bản thỏa thuận sử dụng tên và logo của Hội hữu nghị Việt Nam  Hungary ngày 09/12/2022",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay050224:
      "- Giấy phép xuất khẩu, nhập khẩu sản phẩm mật mã dân sự ngày 05/02/2024",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay29112022:
      "- Giấy phép xuất khẩu, nhập khẩu sản phẩm mật mã dân sự ngày 29/11/2022",


    body_text_congty_codong_Dangkykinhdoanhlanthu22ngay11102022:
      "- Đăng ký kinh doanh lần thứ 22 ngày 11/10/2022",

    body_text_congty_codong_Dangkykinhdoanhlanthu25:
      "- Đăng ký kinh doanh lần thứ 25 ngày 05/10/2023",

    body_text_congty_codong_QuyetdinhgiamgiolamCBNV:
      "- Quyết định giảm giờ làm và thu nhập 19 CBNV ngày 01/10/2023",

    body_text_congty_codong_Dangkykinhdoanhlanthu24ngay6723:
      "- Đăng ký kinh doanh lần thứ 24 ngày 06/07/2023",

    body_text_congty_codong_BienbanhopHDQTVimassngay06102022:
      "- Biên bản họp HĐQT Vimass ngày 06/10/2022",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay31082022:
      "- Biên bản họp Đại hội cổ đông bất thường ngày 31/08/2022",

    body_text_congty_codong_BienbanhopHDQTVimassngay02082022:
      "- Biên bản họp HĐQT Vimass ngày 02/08/2022",

    body_text_congty_codong_HopdonghoptaccungcapdichvuthuvachihoViMASSQRVNngay11072022:
      "- Hợp đồng hợp tác cung cấp dịch vụ thu và chi hộ ViMASS  QRVN ngày 11/07/2022",

    body_text_congty_codong_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "- Quyết định cung cấp trang thiết bị cho CBNV ngày 01/07/2022",

    body_text_congty_codong_BienbanhopHDQTngay31052022:
      "- Biên bản họp HĐQT ngày 31/05/2022",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhNgheAnngay2752022:
      "- Đăng ký kinh doanh lần đầu của chi nhánh Nghệ An ngày 27/5/2022",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhQuangNinhngay20052022:
      "- Đăng ký kinh doanh lần đầu của chi nhánh Quảng Ninh ngày 20/05/2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHuengay18052022:
      "- Đăng ký kinh doanh lần đầu chi nhánh Huế ngày 18/05/2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHaiPhongngay07052022:
      "- Đăng ký kinh doanh lần đầu chi nhánh Hải Phòng ngày 07/05/2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhCanThongay29042022:
      "- Đăng ký kinh doanh lần đầu chi nhánh Cần Thơ ngày 29/04/2022",

    body_text_congty_codong_BienbanhopHDQTngay28042022:
      "- Biên bản họp HĐQT ngày 28/04/2022",

    body_text_congty_codong_BaocaohoatdongquyIvakehoach2022ngay26042022:
      "- Báo cáo hoạt động quý I và kế hoạch 2022 ngày 26/04/2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhDaNangngay22032022:
      "- Đăng ký kinh doanh lần đầu chi nhánh Đà Nẵng ngày 22/03/2022",

    body_text_congty_codong_Dangkykinhdoanhlanthu21ngay11032022:
      "- Đăng ký kinh doanh lần thứ 21 ngày 11/03/2022(pdf)",

    body_text_congty_codong_BienbanhopDaihoicodongthuongnien2022ngay22022022:
      "- Biên bản họp Đại hội cổ đông thường niên 2022 ngày 22/02/2022",

    body_text_congty_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022:
      "- Báo cáo của CT HĐQT tại ĐHĐCĐ thường niên 21/02/2022",

    body_text_congty_codong_BaocaokiemtoanVimass2021ngay27012022:
      "- Báo cáo kiểm toán Vimass 2021 ngày 27/01/2022",

    body_text_congty_codong_BienbanhopHDQT2512022:
      "- Biên bản họp HĐQT 25/1/2022(pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu20ngay31122021:
      "- Đăng ký kinh doanh lần thứ 20 ngày 31/12/2021(pdf)",

    body_text_congty_codong_BienbanhopHDQTVimassngay30112021:
      "- Biên bản họp HĐQT Vimass ngày 30/11/2021 (pdf)",

    body_text_congty_codong_BienbanhopHDQTVimassngay28102021:
      "- Biên bản họp HĐQT Vimass ngày 28/10/2021 (pdf)",

    body_text_congty_codong_Bangcandoiketoanvabaocaoketquakinhdoanh9thangdaunam202122102021:
      "- Bảng cân đối kế toán và báo cáo kết quả kinh doanh 9 tháng đầu năm 2021  22/10/2021(pdf)",

    body_text_congty_codong_Giayphepkinhdoanhsanphamdichvumatmadansu4102021:
      "- Giấy phép kinh doanh sản phẩm, dịch vụ mật mã dân sự(pdf)  4/10/2021",

    body_text_congty_codong_BienbanhopHoidongquantriVimassngay2262021:
      "- Biên bản họp Hội đồng quản trị Vimass ngày 22/6/2021 (pdf)",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2021ngay2742021:
      "- Biên bản họp Đại hội đồng cổ đông thường niên 2021 ngày 27/4/2021 (pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu19ngay23032021:
      "- Đăng ký kinh doanh lần thứ 19 ngày 23/03/2021(pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu18ngay15102020:
      "- Đăng ký kinh doanh lần thứ 18 ngày 15/10/2020(pdf)",

    body_text_congty_codong_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      "- Phân công cán bộ triển khai cung cấp dịch vụ chứng thực CKS công cộng ngày 12/4/2019",

    body_text_congty_codong_BienbanvanghiquyethopDHDCDthuongnien2019ngay442019:
      "- Biên bản và nghị quyết họp ĐHĐCĐ thường niên 2019 ngày 4/4/2019",

    body_text_congty_codong_NghiquyetHoidongquantriVimassngay242019:
      "- Nghị quyết Hội đồng quản trị Vimass ngày 2/4/2019",

    body_text_congty_codong_Giaymoihopdaihoicodongthuongnien2019ngay2132019:
      "- Giấy mời họp đại hội cổ đông thường niên 2019 ngày 21/3/2019",

    body_text_congty_codong_Danhsachemailcuacodongngay2132019:
      "- Danh sách email của cổ đông ngày 21/3/2019",

    body_text_congty_codong_NghiquyetHDQTthongquaviecxincapgiayphepchungthucCKSngay2522019:
      "- Nghị quyết HĐQT thông qua việc xin cấp giấy phép chứng thực CKS ngày 25/2/2019",

    body_text_congty_codong_NghiquyetHoidongquantriVimassveviecvayvonngay3012019:
      "- Nghị quyết Hội đồng quản trị Vimass về việc vay vốn ngày 30/1/2019",

    body_text_congty_codong_GiayphepnhapkhauthietbiMMDSngay2812019:
      "- Giấy phép nhập khẩu thiết bị MMDS ngày 28/1/2019",

    body_text_congty_codong_DKKDlan6cuachinhanhHCMngay2412019:
      "- ĐKKD lần 6 của chi nhánh HCM ngày 24/1/2019",

    body_text_congty_codong_BienbanhopHDQTlan1ngay2312019:
      "- Biên bản họp HĐQT lần 1 ngày 23/1/2019",

    body_text_congty_codong_NghiquyetHDQTso1201dQDHDQTHuydongvongop10ngay18122018:
      "- Nghị quyết HĐQT số 1201d_QĐHĐQT  Huy động vốn góp 10% ngày 18/12/2018",

    body_text_congty_codong_MauhopdongchuyennhuongcophanVimass:
      "- Mẫu Hợp đồng chuyển nhượng cổ phần Vimass (doc)",

    body_text_congty_codong_MaubienbanthanhlyHDCNPVGVimass:
      "- Mẫu Biên bản thanh lý HĐCNPVG Vimass (doc)",

    body_text_congty_codong_DieulecongtycophandichvunendidongVietNam:
      "- Điều lệ công ty cổ phần dịch vụ nền di động Việt Nam (doc)",

    body_text_congty_codong_Dangkykinhdoanhlan17ngay492018:
      "- Đăng ký kinh doanh lần 17 ngày 4/9/2018",

    body_text_congty_codong_Dangkykinhdoanhlan16ngay1362018:
      "- Đăng ký kinh doanh lần 16 ngày 13/6/2018",

    body_text_congty_codong_BienbanhopHoidongthanhvienthuongnienVimassngay952018:
      "- Biên bản họp Hội đồng thành viên thường niên Vimass ngày 9/5/2018",

    body_text_congty_codong_DangkykinhdoanhVimasslan15ngay442018:
      "- Đăng ký kinh doanh Vimass lần 15 ngày 4/4/2018",

    body_text_congty_codong_Giayphep41GPNHNNcuaNganhangnhanuoc1232018:
      "- Giấy phép 41/GPNHNN của Ngân hàng nhà nước 12/3/2018",

    body_text_congty_codong_DangkykinhdoanhVimasslan14ngay412018:
      "- Đăng ký kinh doanh Vimass lần 14 ngày 4/1/2018",

    body_text_congty_codong_Dangkykinhdoanhlan6ngay19122013:
      "- Đăng ký kinh doanh lần 6 ngày 19/12/2013",

    body_text_congty_codong_Dangkykinhdoanhlandaungay3152012:
      "- Đăng ký kinh doanh lần đầu ngày 31/5/2012",

    // # body_text_congty ban dieu han
    body_text_congty_bdh_BANDIEUHANH: "BAN ĐIỀU HÀNH",

    body_text_congty_bdh_ChutichHDQT: "Chủ tịch HĐQT",

    body_text_congty_bdh_Tonggiamdoc: "Tổng giám đốc",

    body_text_congty_bdh_Photonggiamdocthuongtruc:
      "Phó tổng giám đốc thường trực",

    body_text_congty_bdh_Photonggiamdocphutrachtaichinh:
      "Phó tổng giám đốc phụ trách tài chính",

    body_text_congty_bdh_Photonggiamdocphutrachcacchinhanh:
      "Phó tổng giám đốc phụ trách các chi nhánh",

    body_text_congty_bdh_Photonggiamdocphutrachthietbivathe:
      "Phó tổng giám đốc phụ trách thiết bị và thẻ",

    body_text_congty_bdh_Truongphongmaychu: "Trưởng phòng máy chủ",

    body_text_congty_bdh_PhongWebDesktopMiniVUHF:
      "Phòng Web, Desktop, Mini, VUHF",

    body_text_congty_bdh_TruongphonglaptrinhiPhone:
      "Trưởng phòng lập trình iPhone",

    body_text_congty_bdh_TruongphonglaptrinhAndroidVPhonevaVTablet:
      "Trưởng phòng lập trình Android, VPhone và VTablet",

    body_text_congty_bdh_Chanhvanphong: "Chánh văn phòng",

    body_text_congty_bdh_TruongphongMaketing: "Trưởng phòng Maketing",

    body_text_congty_bdh_Danhsachcodongvavongopngay04052023:
      "- Danh sách cổ đông và vốn góp ngày 19/05/2023",

    body_text_congty_bdh_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      "- Hợp đồng cung cấp dịch vụ thu và chi hộ Vimass  QRVN ngày 02/02/2023",

    body_text_congty_bdh_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "- Quyết định về phí dịch vụ tài chính của Vimass 01/02/2023",

    body_text_congty_bdh_QuyetdinhbonhiemPhotonggiamdocngay31012023:
      "- Quyết định bổ nhiệm Phó tổng giám đốc ngày 31/01/2023",

    body_text_congty_bdh_QuyetdinhbonhiemTruongphongThietbingay31012023:
      "- Quyết định bổ nhiệm Trưởng phòng Thiết bị ngày 31/01/2023",

    body_text_congty_bdh_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "- Quyết định cung cấp trang thiết bị cho CBNV ngày 01/07/2022",

    body_text_congty_bdh_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      "- Quyết định xử phạt đối với CBNV không đáp ứng nhiệm vụ tối thiểu ngày 30/06/2022",

    body_text_congty_bdh_Quydinhvelichhopngay28062022:
      "- Quy định về lịch họp ngày 28/06/2022",

    body_text_congty_bdh_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      "- Quyết định của TGĐ về tuyển dụng CBNV ViMASS tại Hội sở và các chi nhánh ngày 23/06/2022",

    body_text_congty_bdh_QDcuaTGDvecackhoanchitaiCNngay03062022:
      "- QĐ của TGĐ về các khoản chi tại CN ngày 03/06/2022",

    body_text_congty_bdh_QuydinhcuaTGDthaydoimucthunhapnhanvien02042022:
      "- Quy định của TGĐ thay đổi mức thu nhập nhân viên 02/04/2022",

    body_text_congty_bdh_QuydinhveviectaosualuuVimassQR1822022:
      "- Quy định về việc tạo, sửa, lưu, Vimass QR  18/2/2022",

    body_text_congty_bdh_Quyetdinhvephidichvudiemthuongvadiemmay1122022:
      "- Quyết định về phí dịch vụ, điểm thưởng và điểm may  11/2/2022",

    body_text_congty_bdh_QuyetdinhxuphatCBNVkhonghoanthanhnhiemvutoithieu2612022:
      "- Quyết định xử phạt CBNV không hoàn thành nhiệm vụ tối thiểu  26/1/2022",

    body_text_congty_bdh_BienbanhopHDQTVimassngay30112021pdf:
      "- Biên bản họp HĐQT Vimass ngày 30/11/2021 (pdf)",

    body_text_congty_bdh_QuyetdinhcuaTongGiamDocveviectuanthutheoThongtuso232019TTNHNN:
      "- Quyết định của Tổng Giám Đốc về việc tuân thủ theo Thông tư số 23/2019/TTNHNN",

    body_text_congty_bdh_GopychoduthaoThongtusuadoiThongtu392014TTNHNNngay252019:
      "- Góp ý cho dự thảo Thông tư sửa đổi Thông tư 39/2014/TTNHNN ngày 2/5/2019",

    body_text_congty_bdh_Phancongcanbohotrokhachhangngay2542019:
      "- Phân công cán bộ hỗ trợ khách hàng ngày 25/4/2019",

    body_text_congty_bdh_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      "- Phân công cán bộ triển khai cung cấp dịch vụ chứng thực CKS công cộng ngày 12/4/2019",

    // # body_text_congty hoi dong quan tri

    body_text_congty_hdqt_HOTEN: " HỌ TÊN",

    body_text_congty_hdqt_CHUCVU: " CHỨC VỤ",

    body_text_congty_hdqt_ChutichHDQTkiemTonggiamdoccongtycophandichvunendidongVietNam:
      " Chủ tịch HĐQT kiêm Tổng giám đốc công ty cổ phần dịch vụ nền di động Việt Nam -",

    body_text_congty_hdqt_PhochutichHDQT: " Phó chủ tịch HĐQT",

    body_text_congty_hdqt_ThanhvienHDQT: " Thành viên HĐQT",

    body_text_congty_hdqt_HOIDONGQUANTRI: " HỘI ĐỒNG QUẢN TRỊ",

    body_text_congty_hdqt_truongBanKiemSoat: "Trưởng ban kiểm soát",

    body_text_congty_hdqt_thanhVienBanKiemSoat: "Thành viên ban kiểm soát",

    // # body_text_congty chi nhanh

    body_text_congty_cn_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      "- Quyết định về cộng tác viên tạo điểm thanh toán và phân phối thiết bị ngày 17/04/2023",

    body_text_congty_cn_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      "- Báo cáo hoạt động năm 2022, kết quả 2 tháng 2023 và kế hoạch 2023 ngày 14/03/2022",

    body_text_congty_cn_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      "- Hoạt động kinh doanh 2 tháng đầu năm 2023 ngày 14/03/2022",

    body_text_congty_cn_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      "- Báo cáo kiểm toán 2022, Công Ty Cổ Phần Dịch Vụ Nền Di Động Việt Nam ngày 08/03/2022",

    body_text_congty_cn_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "- Quyết định về phí dịch vụ tài chính của Vimass 01/02/2023",

    body_text_congty_cn_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "- Quyết định cung cấp trang thiết bị cho CBNV ngày 01/07/2022",

    body_text_congty_cn_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      "- Quyết định xử phạt đối với CBNV không đáp ứng nhiệm vụ tối thiểu ngày 30/06/2022",

    body_text_congty_cn_Quydinhvelichhopngay28062022:
      "- Quy định về lịch họp ngày 28/06/2022",

    body_text_congty_cn_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      "- Quyết định của TGĐ về tuyển dụng CBNV ViMASS tại Hội sở và các chi nhánh ngày 23/06/2022",

    body_text_congty_cn_QDcuaTGDvecackhoanchitaiCNngay03062022:
      "- QĐ của TGĐ về các khoản chi tại CN ngày 03/06/2022",

    body_text_congty_cn_KehoachphattrienChinhanh20222024:
      "- Kế hoạch phát triển Chi nhánh 2022 2024",

    body_text_dichvu_pdv_TT: " TT",

    body_text_dichvu_pdv_PHIDICHVUTAICHINH: " PHÍ DỊCH VỤ TÀI CHÍNH",

    body_text_dichvu_pdv_PHITHUDOIVOIBENNHANTHANHTOAN:
      " PHÍ THU ĐỐI VỚI BÊN NHẬN THANH TOÁN",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQR:
      " Dùng Ví Vimass, Thẻ đa năng thanh toán đến ví chuyên thu hoặc quét VNPAY QR",

    body_text_dichvu_pdv_Ruttoanbotientuvichuyenthuvetaikhoannganhangluc6h8h10h12h14h16h18hsotientoithieu:
      " Rút toàn bộ tiền từ ví chuyên thu về tài khoản ngân hàng lúc 6h, 8h, 10h, 12h, 14h, 16h, 18h (% số tiền, tối thiểu 500 đồng)",

    body_text_dichvu_pdv_DungungdungnganhangquetVietQRthanhtoanngaydentaikhoannganhangsotientoithieu:
      " Dùng ứng dụng ngân hàng quét VietQR thanh toán ngay đến tài khoản ngân hàng (% số tiền, tối thiểu 750 đồng)",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang:
      " Dùng Ví Vimass, Thẻ đa năng thanh toán ngay đến tài khoản ngân hàng",

    body_text_dichvu_pdv_PHITHUDOIVOIBENTHANHTOAN:
      " PHÍ THU ĐỐI VỚI BÊN THANH TOÁN",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQRsotientoithieu:
      " Dùng Ví Vimass, Thẻ đa năng thanh toán đến ví chuyên thu hoặc quét VNPAY QR (% số tiền, tối thiểu 250 đồng)",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang2:
      "Dùng Ví Vimass, Thẻ đa năng thanh toán ngay đến tài khoản ngân hàng (% số tiền, tối thiểu 750 đồng)",

    body_text_dichvu_pdv_YEUCAUCHUYENTIENCHUYENTIEN:
      " YÊU CẦU CHUYỂN TIỀN/ CHUYỂN TIỀN",

    body_text_dichvu_pdv_Chuyentientuvidenvisotientoithieu200dongBaogomvicanhanvidoanhnghiepthedanangvichuyenthucuacuahang:
      " Chuyển tiền từ ví đến ví (% số tiền, tối thiểu 250 đồng Bao gồm ví cá nhân, ví doanh nghiệp, thẻ đa năng, ví chuyên thu của cửa hàng)",

    body_text_dichvu_pdv_Chuyentiennhanhdentaikhoannganhanghoacsothemoigiaodichtoida300trieudong:
      " Chuyển tiền nhanh đến tài khoản ngân hàng hoặc số thẻ (mỗi giao dịch tối đa 300 triệu đồng, % số tiền, tối thiểu 750 đồng)",

    body_text_dichvu_pdv_NaptienvaoviquaQRhoacquataikhoansotientoithieu:
      " Nạp tiền vào ví qua QR hoặc qua tài khoản (% số tiền, tối thiểu 750 đồng)",

    body_text_dichvu_pdv_Ruttientuvivetaikhoannganhangmoigiaodichtoida300trieudong:
      " Rút tiền từ ví về tài khoản ngân hàng (mỗi giao dịch tối đa 300 triệu đồng, % số tiền, tối thiểu 750 đồng)",

    body_text_dichvu_pdv_THUHOtheohopdong: " THU HỘ, CHI HỘ (theo hợp đồng)",

    body_text_dichvu_pdv_ChihoVNPOST:
      "  a) Thu hộ tại VNPOST, 1000đ trên 1 giao dịch",

    body_text_dichvu_pdv_KhidungungdungnganhangquetQRmattruocthesotientoithieu200dong:
      "  b) Khi dùng ứng dụng ngân hàng quét QR mặt trước thẻ: % số tiền, tối thiểu 250 đồng",

    body_text_dichvu_pdv_KhidungungdungQRVietnamquetQRmattruoctheThuhoquaThedanangsotientoithieu200dong:
      " c) Khi dùng ứng dụng quét QR mặt trước thẻ, Thu hộ qua Thẻ đa năng: % số tiền, tối thiểu 250 đồng",

    body_text_dichvu_pdv_CHIHOtheohopdong: " CHI HỘ (theo hợp đồng)",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichduoi1trieudong:
      " d) Bên nhận là ví Vimass, giá trị giao dịch dưới 1 triệu đồng",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtu1trieuden20trieudong:
      " 	e) Bên nhận là ví Vimass, giá trị giao dịch từ 1 triệu đến 20 triệu đồng",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtren20trieudong:
      " f) Bên nhận là ví Vimass, giá trị giao dịch trên 20 triệu đồng",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangduoi100000:
      " g) Bên nhận là tài khoản, số giao dịch một tháng dưới 100.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu100000den200000:
      " h) Bên nhận là tài khoản, số giao dịch một tháng từ 100.000 đến 200.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu200000den500000:
      " i) Bên nhận là tài khoản, số giao dịch một tháng từ 200.000 đến 500.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu500000den1000000:
      " j) Bên nhận là tài khoản, số giao dịch một tháng từ 500.000 đến 1.000.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtren1000000:
      " k) Bên nhận là tài khoản, số giao dịch một tháng trên 1.000.000",

    body_text_dichvu_pdv_CONGTHANHTOANTHE: " CỔNG THANH TOÁN THẺ",

    body_text_dichvu_pdv_Dungthenoidiathanhtoanchodonvihoatdongtronglinhvucytegiaoducgiaothongcongcongdichvucongthuekinhdoanhxangdau:
      " a) Dùng thẻ nội địa thanh toán cho đơn vị hoạt động trong lĩnh vực y tế, giáo dục, giao thông công cộng, dịch vụ công, thuế, kinh doanh xăng dầu",

    body_text_dichvu_pdv_DungthenoidiacuanganhangcoketnoithanhtoanthevoiVimass:
      " b) Dùng thẻ nội địa của ngân hàng có kết nối thanh toán thẻ với Vimass",

    body_text_dichvu_pdv_Dungthenoidiacuanganhangchuaketnoi:
      " c) Dùng thẻ nội địa của ngân hàng chưa kết nối",

    body_text_dichvu_pdv_DungtheVISAMasterCardJCBAMEX:
      " d) Dùng thẻ VISA, MasterCard, JCB, AMEX",

    body_text_dichvu_pdv_TIETKIEMTRUCTUYEN: " NGÂN HÀNG TRỰC TUYẾN",

    body_text_dichvu_pdv_HuyDong:
      " Huy động (Theo quy định của Ngân hàng Nhà nước)",

    body_text_dichvu_pdv_Chovay:
      " Cho vay (Theo quy định của Ngân hàng Nhà nước)",

    body_text_dichvu_pdv_Dangcapnhat: " Đang cập nhật",

    body_text_dichvu_pdv_Thoathuan: " Thỏa thuận",

    body_text_dichvu_pdv_MUCPHI: "MỨC PHÍ",

    body_text_dichvu_cks_STT: "TT",

    body_text_dichvu_cks_phiVAT: "Phí (gồm VAT)",

    body_text_dichvu_cks_diemThuong: "  Điểm thưởng VPoint",

    body_text_dichvu_cks_diemMay: "  Điểm may LPoint",

    body_text_dichvu_cks_menhGia: "đồng",

    body_text_dichvu_cks_DICHVUCHUKYSOsudungtheSIMkysoVimass:
      " DỊCH VỤ CHỮ KÝ SỐ (sử dụng thẻ/SIM ký số Vimass)",

    body_text_dichvu_cks_CHUKYSOTHEONAM: " CHỮ KÝ SỐ THEO NĂM",

    body_text_dichvu_cks_Giathuebaochukysocanhan1nam:
      " Giá thuê bao chữ ký số cá nhân 1 năm",

    body_text_dichvu_cks_Giathuebaochukysocanhan2nam:
      " Giá thuê bao chữ ký số cá nhân 2 năm",

    body_text_dichvu_cks_Giathuebaochukysocanhan3nam:
      " Giá thuê bao chữ ký số cá nhân 3 năm",

    body_text_dichvu_cks_Giathuebaochukysotochuc1nam:
      " Giá thuê bao chữ ký số tổ chức 1 năm",

    body_text_dichvu_cks_Giathuebaochukysotochuc2nam:
      " Giá thuê bao chữ ký số tổ chức 2 năm",

    body_text_dichvu_cks_Giathuebaochukysotochuc3nam:
      " Giá thuê bao chữ ký số tổ chức 3 năm",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc1nam:
      " Gia hạn chữ ký số dành cho tổ chức 1 năm",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc2nam:
      " Gia hạn chữ ký số dành cho tổ chức 2 năm",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc3nam:
      " Gia hạn chữ ký số dành cho tổ chức 3 năm",

    body_text_dichvu_cks_CHUKYSOTUNGLAN: " CHỮ KÝ SỐ TỪNG LẦN",

    body_text_dichvu_cks_Gia1lankyneuduoi1000lannamkhongduoi8lan:
      " Giá 1 lần ký nếu dưới 1.000 lần /năm (không dưới 8 lần)",

    body_text_dichvu_cks_Gia1lankyneutu1000den10000lannam:
      " Giá 1 lần ký nếu từ 1.000 đến 10.000 lần/năm",

    body_text_dichvu_cks_Gia1lankyneutu10000den100000lannam:
      " Giá 1 lần ký nếu từ 10.000 đến 100.000 lần/năm",

    body_text_dichvu_cks_Gia1lankyneutu100000den1000000lannam:
      " Giá 1 lần ký nếu từ 100.000 đến 1.000.000 lần/năm",

    body_text_dichvu_cks_Gia1lankyneutren1000000lannam:
      " Giá 1 lần ký nếu trên 1.000.000 lần/năm",

    body_text_dichvu_cks_CHUKYSOTUXA: " CHỮ KÝ SỐ TỪ XA",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrong24gio:
      " Giá 1 lần ký từ xa không quá 10 lần trong 24 giờ",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrongthang:
      " Giá 1 lần ký từ xa không quá 10 lần trong tháng",

    body_text_dichvu_cks_Gia10lankytuxathang: " Giá 10 lần ký từ xa / tháng",

    body_text_dichvu_cks_Gia20lankytuxathang: " Giá 20 lần ký từ xa / tháng",

    body_text_dichvu_cks_Gia50lankytuxathang: " Giá 50 lần ký từ xa / tháng",

    body_text_dichvu_cks_Gia100lankytuxathang: " Giá 100 lần ký từ xa / tháng",

    body_text_dichvu_cks_Giakytuxakhongqua200lannam:
      " Giá ký từ xa không quá 200 lần / năm",

    body_text_dichvu_cks_DICHVUCHUKYSORemoteSigning:
      " DỊCH VỤ CHỮ KÝ SỐ (Remote Signing)",

    body_text_dichvu_cks_Chukyso24hKhoitaovakychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong24hketukhicap:
      " Chữ ký số 24h - Khởi tạo và ký (cho cá nhân thực hiện các giao dịch số_ Khởi tạo và ký không quá 10 lần trong vòng 24h kể từ khi cấp)",

    body_text_dichvu_cks_Chukysothoihan1thangvoi10luotkychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong30ngayketukhicap:
      " Chữ ký số thời hạn 1 tháng với 10 lượt ký (cho cá nhân thực hiện các giao dịch số_ Khởi tạo và ký không quá 10 lần trong vòng 30 ngày kể từ khi cấp)",

    body_text_dichvu_cks_Goibosung10luotkythang:
      " Gói bổ sung 10 lượt ký / tháng",

    body_text_dichvu_cks_Goibosung20luotkythang:
      " Gói bổ sung 20 lượt ký / tháng",

    body_text_dichvu_cks_Goibosung50luotkythang:
      " Gói bổ sung 50 lượt ký / tháng",

    body_text_dichvu_cks_Goibosung100luotkythang:
      " Gói bổ sung 100 lượt ký / tháng",

    body_text_dichvu_cks_Chukyso1namkhongqua200luotnam:
      " Chữ ký số 1 năm (không quá 200 lượt/ năm)",

    main_images_theDaNang:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=theDaNang-V.jpg",

    main_images_chuongTrinhTaiTro: "img/8cuc/cuc2/thidiemtaitro.jpg",

    main_images_qrAMtHANH: "img/8cuc/cuc1/QRamthanh-v.jpg",

    main_images_diemravao:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=diemdinhdanh-V.jpg",

    main_images_diemThanhToan:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=diemThanhToan-V.jpg",

    // main_images_home_ungDung: "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1706699322931Je7JF",

    main_images_home_ungDung: "img/8cuc/cuc1/giai-phap-cds-V.jpg",    

    main_images_home_giaoduc: "img/8cuc/cuc1/Giaoduc-v.jpg",

    main_images_home_yte: "img/8cuc/cuc1/Thuchiho-v.jpg",

    main_images_home_giaothong: "img/8cuc/cuc1/YteDichvucongGiaothong-v.jpg",

    main_images_home_GD_YTE: "img/8cuc/cuc1/Giaoduc-v.jpg",

    main_images_home_diemgiaodich: "img/8cuc/cuc1/diemthanhtoan-v.jpg",

    main_images_thietBi:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630689759KTtgF",

    main_images_phanMem:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=phanMem-V.jpg",

    main_images_dichVu: "img/8cuc/cuc1/thietbivadichvu-v.jpg",

    main_images_khachHang:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=khachHang-V.jpg",

    main_images_congTy: "img/8cuc/cuc1/congty.png",

    // #LinkAnhBanner
    main_images_bannerWeb1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb1-V.jpg",

    main_images_bannerWeb2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb2-V.jpg",

    main_images_bannerMobile1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile1-V.jpg",

    main_images_bannerMobile2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile2-V.jpg",

    // #HopDong
    body_HopDong_text_title: "Hợp đồng",

    body_HopDong_text_hopDongDaKy: "HỢP ĐỒNG ĐÃ KÝ",

    body_HopDong_text_ThoathuanhoptacCMCVIMASS:
      "- THỎA THUẬN HỢP TÁC CMC - VIMASS",

    body_HopDong_text_ThoathuanhoptacVimassPaytech:
      "- HỢP ĐỒNG HỢP TÁC CUNG CẤP DỊCH VỤ THANH TOÁN PAYTECH - VIMASS",

    body_HopDong_text_hopdonghoptackinhdoanhdichvuxacthucbangkhuonmatVIMASSvPSPACES:
      "- HỢP ĐỒNG HỢP TÁC KINH DOANH DỊCH VỤ XÁC THỰC BẰNG KHUÔN MẶT VIMASS - VPSPACES",

    body_HopDong_text_HoptacvoiVAIS:
      "- HỢP ĐỒNG HỢP TÁC KINH DOANH DỊCH VỤ XÁC THỰC BẰNG TIẾNG NÓI VIMASS - VAIS",

    body_HopDong_text_hopdonghoptacVimassNanosoft:
      "- HỢP ĐỒNG HỢP TÁC VIMASS - NANOSOFT",

    body_HopDong_text_hopdongcungcapdichvuVimassTiem:
      "- HỢP ĐỒNG CUNG CẤP DỊCH VỤ VIMASS - TIEM",

    body_HopDong_text_thoathaphoptacthanhtoankhongdungtienmatvimassvafpt:
      "- THỎA THUẬN HỢP TÁC THANH TOÁN KHÔNG DÙNG TIỀN MẶT VÀ PHỔ CẬP TÀI CHÍNH CHO CÁC TRƯỜNG CỦA CTY GIÁO DỤC FPT VIMASS - FPT",

    body_HopDong_text_thoathuanhoptackhongdungtienmattaibenhvientpbvavimass:
      "- THỎA THUẬN HỢP TÁC TRIỂN KHAI THANH TOÁN KHÔNG DÙNG TIỀN MẶT TẠI BỆNH VIỆN VIMASS - TPBANK",

    body_HopDong_text_hopdongbidvvimass:
      "- HỢP ĐỒNG HỢP TÁC CHI HỘ VIMASS - BIDV",

    body_HopDong_text_HopdongdichvuthuhoVpbankVimass:
      "- HỢP ĐỒNG HỢP TÁC THU HỘ VIMASS - VPB",

    body_HopDong_text_DichVuChihovoiVPBankVimass:
      "- HỢP ĐỒNG HỢP TÁC CHI HỘ VIMASS - VPB",

    body_HopDong_text_HopdongVimassIntergate:
      "- HỢP ĐỒNG HỢP TÁC VIMASS - INTERGATE",

    body_HopDong_text_hopdongVimassNapas: "- HỢP ĐỒNG HỢP TÁC VIMASS - NAPAS",

    body_HopDong_text_hoptacvimassvavnca: "- HỢP ĐỒNG HỢP TÁC VIMASS - VINA CA",

    body_HopDong_text_thongbaogopvon:
      "- Thông báo về góp vốn tháng 08/2023 và quyền góp tháng 09/2023",

    // #thietbi
    body_text_thietbi_tb_LinkNXP:
      "https://vimass.vn/vmbank/services/media/viewFile?id=NXPbrochure-vie.pdf",

    body_text_thietbi_tb_Ten: "Tên",

    body_text_thietbi_tb_Thietbibaohanh2nam: "Thiết bị",

    body_text_thietbi_tb_Ungdung: "Vimass POS",

    body_text_thietbi_tb_Tinhnangkythuat: "Tính năng kỹ thuật",

    body_text_thietbi_tb_The2chip: "Thẻ 2 chip",

    body_text_thietbi_tb_Gia100000160000dongthe:
      "Giá đại lý (gồm VAT): 80.000 - 160.000 đồng/thẻ",

    body_text_thietbi_tb_Tuytheodaily: "Gía đại lý:",

    body_text_thietbi_tb_Giataitro80000:
      "Giá chương trình tài trợ (gồm VAT): 80.000 đồng/thẻ",

    body_text_thietbi_tb_Tangkembaodungthe: "Tặng bao đựng thẻ",

    body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh:
      "- NXP Kết nối an toàn cho một thế giới thông minh",

    body_text_thietbi_tb_NXPDinhhinhchochinhphudientu:
      "NXP - Định hình cho chính phủ điện tử",

    body_text_thietbi_tb_UngdungcuachipNXPUCODE:
      "- Ứng dụng của chip NXP UCODE",

    body_text_thietbi_tb_Chitiettunhasanxuat: " - Công ty NXP",

    body_text_thietbi_tb_ChitiettunhasanxuatAdvanced:
      " - Công ty Advanced Card Systems Ltd",

    body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile:
      "- MIFARE DESFire EV3 Application Key & File",

    body_text_thietbi_tb_MIFAREDESFireEV3ICBlockdiagram:
      "- MIFARE DESFire EV3 IC Block diagram",

    body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB:
      "- Tài liệu kỹ thuật Mifare DESFire EV3 8KB",

    body_text_thietbi_tb_TailieukythuatveUCODE9:
      "- Tài liệu kỹ thuật về UCODE 9",

    body_text_thietbi_tb_TinhNangBaoMatEV3: "- Tính năng bảo mật của EV3",

    body_text_thietbi_tb_TailieukythuatveDNA: "- Tài liệu kỹ thuật về DNA",

    body_text_thietbi_tb_The3chipkyso: "Thẻ 3 chip ký số",

    body_text_thietbi_tb_Gia150000210000dongthe:
      "Giá đại lý (gồm VAT): 130.000 - 210.000 đồng/thẻ",

    body_text_thietbi_tb_Giataitro130000:
      "Giá chương trình tài trợ (gồm VAT): 130.000 đồng/thẻ",

    body_text_thietbi_tb_UngdungcuachipkysoACOS564: "- Chip ký số ACOS5-64",

    body_text_thietbi_tb_baohanhhainam: "Bảo hành 2 năm",

    body_text_thietbi_tb_baohanhmotnam: "Bảo hành 1 năm",

    body_text_thietbi_tb_baohanhbanam: "Bảo hành 3 năm",

    body_text_thietbi_tb_TinhnangkythuatveUCODE9:
      "- Tính năng kỹ thuật về UCODE 9",

    body_text_thietbi_tb_TemUCODERFid: "Tem RFID",

    body_text_thietbi_tb_GiagomVAT30000den60000dong:
      "Giá (gồm VAT): 20.000 đến 60.000 đồng",

    body_text_thietbi_tb_Giataitro20000:
      "Giá chương trình tài trợ (gồm VAT): 20.000 đồng/tem",

    body_text_thietbi_tb_Bankemthedanang1themuatoida2nhan:
      "Bán kèm thẻ đa năng (1 thẻ mua tối đa 2 nhãn)",

    body_text_thietbi_tb_SudungdanotoxemmaycothethaythetheVETCEpassbihong:
      "Sử dụng dán ô tô, xem máy (có thể thay thế thẻ VETC, Epass ___ bị hỏng)",

    body_text_thietbi_tb_DungungdungQRVietnamdeghimaEPC:
      "Dùng ứng dụng  để ghi mã EPC:",

    body_text_thietbi_tb_KhongtrungvoimaEPCdacocuaVimass:
      "- Không trùng với mã EPC đã có của Vimass",

    body_text_thietbi_tb_PhaitrungvoimakhachdangsudungcuadonvikhacVETCEpass:
      "- Phải trùng với mã khách đang sử dụng của đơn vị khác ( VETC, Epass ___)",

    body_text_thietbi_tb_Kichthuocanten70x14mm: "- Kích thước anten: 70x14mm",

    body_text_thietbi_tb_ChatlieuGiaytrang: "- Chất liệu: Giấy tráng",

    body_text_thietbi_tb_ThietbiphatUHFculy7mxuly1theUHFva20theUHF:
      "Thiết bị phát UHF cự ly 7m xử lý 1 thẻ UHF và 20 thẻ UHF",

    body_text_thietbi_tb_GiagomVAT2500000dong: "Giá (gồm VAT): 2.500.000 đồng",

    body_text_thietbi_tb_thongsokythuat: "- Thông số kỹ thuật",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy7mxuly1the:
      "- Thông số kỹ thuật thiết bị thu phát UHF cự ly 7m xử lý 1 thẻ",

    body_text_thietbi_tb_ThongsokythuatthietthubiphatUHFculy7mxuly200the:
      "- Thông số kỹ thuật thiết thu bị phát UHF cự ly 7m xử lý 200 thẻ",

    body_text_thietbi_tb_ThietbiphatUHFculy20mxuly1theUHFva200theUHF:
      "Thiết bị phát UHF cự ly 20m xử lý 1 thẻ UHF và 200 thẻ UHF",

    body_text_thietbi_tb_GiagomVAT4400000dong: "Giá (gồm VAT): 4.400.000 đồng",

    body_text_thietbi_tb_thongsokythuatthietbithuphatUHF:
      "- Thông số kỹ thuật thiết bị thu phát UHF",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly1the:
      "- Thông số kỹ thuật thiết bị thu phát UHF cự ly 20m xử lý 1 thẻ",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly200the:
      "- Thông số kỹ thuật thiết bị thu phát UHF cự ly 20m xử lý 200 thẻ",

    body_text_thietbi_tb_VPhone22: "VPhone 22",

    body_text_thietbi_tb_GiagomVAT3000000dong: "Giá (gồm VAT): 3.000.000 đồng",

    body_text_thietbi_tb_GiabantaitroVphone:
      "Giá chương trình tài trợ (gồm VAT): 2.100.000 đồng",

    body_text_thietbi_tb_GiabantaitroVtab:
      "Giá chương trình tài trợ(gồm VAT): 2.240.000 đồng",

    body_text_thietbi_tb_VPhone22GSMAssociation: "- VPhone 22 GSM Association",

    body_text_thietbi_tb_thongsokythuatVPhone22: "- thông số kỹ thuật VPhone22",

    body_text_thietbi_tb_MT6762DCPU: "- MT6762D CPU",

    body_text_thietbi_tb_TechnicaldiagramMT6762: "- Technical diagram MT6762",

    body_text_thietbi_tb_CompareCPUMT6762DwithSC9863:
      "- Compare CPU MT6762D with SC9863",

    body_text_thietbi_tb_UserManual: "- User Manual",

    body_text_thietbi_tb_MotsodienthoaicoNFC: "Một số điện thoại có NFC :",

    body_text_thietbi_tb_SamsungGalaxyM22: "- Samsung Galaxy M22",

    body_text_thietbi_tb_SamsungGalaxyA32: "- Samsung Galaxy A32",

    body_text_thietbi_tb_DienthoaiSamsungGalaxyNFC:
      "- Điện thoại Samsung Galaxy NFC:",

    body_text_thietbi_tb_SamsungGalaxyS6trolen: "+ Samsung Galaxy S6 trở lên",

    body_text_thietbi_tb_IPhone7trolen: "+ IPhone 7 trở lên",

    body_text_thietbi_tb_VPhone23: "VPhone 23",

    body_text_thietbi_tb_dtphankhucre: "(Điện thoại phân khúc rẻ)",

    body_text_thietbi_tb_GiagomVAT1200000dong: "Giá (gồm VAT): 1.500.000 đồng",

    body_text_thietbi_tb_GiaThamKhao3000000dong:
      "Giá chương trình tài trợ(chưa VAT): 1.200.000 đồng",

    body_text_thietbi_tb_Manhinh: "- Màn Hình 6_0Inch, độ phân giải 1440x3200",

    body_text_thietbi_tb_Camera: "- Camera: trước 16MP, sau 32MP",

    body_text_thietbi_tb_Dungluong: "- Dung lượng 8GB + 256GB (thẻ nhớ)",

    body_text_thietbi_tb_VTablet22: "VTablet 22",

    body_text_thietbi_tb_XiaomiRedmi12: "Xiaomi Redmi 12",

    body_text_thietbi_tb_Gia4290000: "Giá: 4.290.000 đồng",

    body_text_thietbi_tb_ManhinhIPSLCD679FullHD:
      "-	Màn hình: IPS LCD6_79 Full HD+",

    body_text_thietbi_tb_CamerasauChinh50MPPhu8MP2MPCameratruoc8MP:
      " - Camera sau: Chính 50 MP & Phụ 8 MP, 2 MP; Camera trước: 8 MP",

    body_text_thietbi_tb_RAM4GB: "     - RAM: 4 GB",

    body_text_thietbi_tb_Dungluongluutru128GB:
      "     -	Dung lượng lưu trữ:128 GB",

    body_text_thietbi_tb_XiaomiRedmi12lik:
      "- https://cellphones.com.vn/xiaomi-redmi-note-12.html?gad:1&gclid=CjwKCAjwt52mBhB5EiwA05YKo8oeLMcIHaLeuBRK1fWG7ET1LYQr_ICtaqit-Mdc5rbTzewNjQd4xBoChToQAvD_BwE",

    body_text_thietbi_tb_SamsungGalaxyA33: " 	Samsung Galaxy A33",

    body_text_thietbi_tb_gia5490000: "    Giá: 5.490.000 đồng",

    body_text_thietbi_tb_Manhinh64inchSuperAMOLEDFHD:
      "        -	Màn hình: 6.4 inch, Super AMOLED, FHD+,",

    body_text_thietbi_tb_Camerasau480MP80MP50MP20MPCameratruoc130MP:
      "    -	Camera sau: 48_0 MP + 8_0 MP + 5_0 MP + 2.0 MP ; Camera trước: 13_0 MP",

    body_text_thietbi_tb_RAM6GB: "-	RAM:	6 GB",

    body_text_thietbi_tb_iPhoneXcu: " iPhone X (đã qua sử dụng)",

    body_text_thietbi_tb_Gia6490000: "   Giá: 6.490.000 đồng",

    body_text_thietbi_tb_Manhinh58inch: "   -	Màn hình: 5_8 inch",

    body_text_thietbi_tb_Camerasau12MPCameratruoc7MP:
      "    -	Camera sau: 12MP ; Camera trước: 7MP",

    body_text_thietbi_tb_RAM3GB: "     -	RAM:	3 GB",

    body_text_thietbi_tb_Dungluongluutru64GB:
      "         -	Dung lượng lưu trữ: 64GB",

    body_text_thietbi_tb_httpscellphonescomvniphonex64gboldhtml:
      "       -	https://cellphones.com.vn/iphone-x-64-gb-old_html",

    body_text_thietbi_tb_linkmuahangthamkhao: "- Link mua hàng tham khảo",

    body_text_thietbi_tb_iPhone11: "IPhone 11",

    body_text_thietbi_tb_Gia10650000: "    Giá: 10_650.000",

    body_text_thietbi_tb_Manhinh61inch: "  Màn hình: 6_1 inch",

    body_text_thietbi_tb_Camerasau12MPCameratruoc12MP:
      "  Camera sau: 12MP ; Camera trước: 12MP",

    body_text_thietbi_tb_MaytinCPUI7: "Máy tính CPU i7",

    body_text_thietbi_tb_Gia22374000: "    Giá (gồm VAT): 22.374.000  đồng",

    body_text_thietbi_tb_MAINBOARD: "- MAINBOARD ASUS PRIME B760M-A WIFI D4",

    body_text_thietbi_tb_CPUINTELCOREI7:
      " - CPU INTEL CORE I7-12700F (UP TO 4_8GHZ, 12 NHÂN 20 LUỒNG, 25MB CACHE, 125W) - SOCKET INTEL LGA 1700)",

    body_text_thietbi_tb_TANNHIETKHI: "- TẢN NHIỆT KHÍ DEEPCOOL AK400",

    body_text_thietbi_tb_RAMKINGTON:
      "- RAM DESKTOP KINGSTON FURY BEAST RGB 32GB (2X16GB) DDR4 3200MHZ",

    body_text_thietbi_tb_SSDKingston:
      "- SSD Kingston NV2 M_2 PCIe Gen4 x4 NVMe 2TB",

    body_text_thietbi_tb_NGUONMAYTINH: "- NGUỒN MÁY TÍNH COOLER MASTER",

    body_text_thietbi_tb_VOCASE: "- VỎ CASE KENOO ESPORT FM800",

    body_text_thietbi_tb_Maytinhmini7000000: " Máy tính mini",

    body_text_thietbi_tb_Gia7000000: "    Giá (gồm VAT): 9.000.000 đồng",

    body_text_thietbi_tb_CPUCeleronN5105Upto29GHz4Cores4:
      "  -	CPU: Celeron N5105 (Up to 2.9GHz) 4 Cores 4",

    body_text_thietbi_tb_VGAIntelUHDGraphics:
      "        -	VGA: Intel UHD Graphics",

    body_text_thietbi_tb_Ram8GB: "          -	Ram: 8GB",

    body_text_thietbi_tb_Ocung2TB: "             -	Ổ cứng: 2TB",

    body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc11essentialkitatlascanyonceleronn5105bnuc11atkc40000variantid69490990:
      "       -	https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-11-essential-kit-atlas-canyon-celeron-n5105-bnuc11atkc40000?variantid=69490990",

    body_text_thietbi_tb_MaytinhminiGia15000000: "   	Máy tính mini",

    body_text_thietbi_tb_Gia15000000: "     Giá: 15.000.000 đồng",

    body_text_thietbi_tb_CPUIntelCorei71260PUpto48GHz12nhan16luong:
      "   -	CPU: Intel Core i7-1260P (Up to 4_8GHz) 12 nhân 16 luồng",

    body_text_thietbi_tb_VGAIrisXeGraphics: "      -	VGA: Iris Xe Graphics",

    body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc12prowallstreetcanyoni71260prnuc12wshi70000variantid85683670:
      "     https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-12-pro-wall-street-canyon-i7-1260p-rnuc12wshi70000?variantid=85683670",

    body_text_thietbi_tb_ThongsokythuatVTablet22:
      "- Thông số kỹ thuật VTablet 22",

    body_text_thietbi_tb_ManHinh101inch19201200HD:
      "- Màn hình 10.1 inch 1920*1200 HD,",

    body_text_thietbi_tb_Front5MPBack8MP: "    - CAMERA trước 5MP+ sau 8MP",

    body_text_thietbi_tb_Dungluongluutru32GB: " -	Dung lượng lưu trữ: 32GB",

    body_text_thietbi_tb_VReader: "VReader",

    body_text_thietbi_tb_nfc: "- Có NFC",

    body_text_thietbi_tb_giaThamKhao43: "Giá (gồm VAT): 4.390.000 đồng",

    body_text_thietbi_tb_GiagomVAT3200000dong: "Giá (gồm VAT): 3.200.000 đồng",

    body_text_thietbi_tb_giaThamKha549: "Giá (gồm VAT): 5.490.000 đồng",

    body_text_thietbi_tb_giaThamKhao649: "Giá (gồm VAT): 6.490.000 đồng",

    body_text_thietbi_tb_giaThamKhao10790: "Giá (gồm VAT): 10.790.000 đồng",

    body_text_thietbi_tb_GiagomVAT1850000dong: "Giá (gồm VAT): 1.850.000 đồng",

    body_text_thietbi_tb_NXPPN7642AU: "- NXP-PN7642AU",

    body_text_thietbi_tb_NXPPN7462: " NXP-PN7462",

    body_text_thietbi_tb_NXPAN12752: "  NXP-AN12752",

    body_text_thietbi_tb_NXPAN12057: "  NXP-AN12057",

    body_text_thietbi_tb_NXPAN10834: " NXP-AN10834",

    body_text_thietbi_tb_ModulmaytinhNFCmahoaAES3DES:
      ": Modul máy tính NFC, mã hóa AES, 3-DES_",

    body_text_thietbi_tb_ESPWROOM32: "- ESP-WROOM-32",

    body_text_thietbi_tb_ModulemaytinhnangluongthapketnoiWifiBluetoothRS485UARTWeigandvv:
      ": Module máy tính năng lượng thấp, kết nối Wifi, Bluetooth, RS485, UART, Weigand v_v_",

    body_text_thietbi_tb_FS01: "- FS-1",

    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida1000mauvantay:
      ": Cảm biến vân tay 5 mức độ bảo mật, lưu trữ đến 1000 mẫu vân tay (500 người)_",

    body_text_thietbi_tb_GiagomVAT2200000dong: "Giá (gồm VAT): 2.200.000 đồng",

    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida3000mauvantay:
      "- Lưu trữ đến 3000 mẫu vân tay (1500 người)_",

    body_text_thietbi_tb_ip67: "- Đạt chuẩn IP67",

    body_text_thietbi_tb_AngtenNFCwifidieukhiendongmodongcuaBluetoothRS485UARTWeigandnguon220V12V2A:
      "   - Ăng ten NFC, wifi, điều khiển đóng/mở đóng cửa, Bluetooth, RS485, UART, Weigand, nguồn 220V-12V, 2A_",

    body_text_thietbi_tb_PhanmemtraodoidulieuvoithevadienthoaiketnoiWifiBluetoothRS485UARTWeiganddichvuchuyentienthanhtoandichvunganhang:
      "  - Phần mềm: trao đổi dữ liệu với thẻ và điện thoại, kết nối Wifi, Bluetooth, RS485, UART, Weigand, dịch vụ chuyển tiền, thanh toán, dịch vụ ngân hàng_",

    body_text_thietbi_tb_ThenhomicroHikvisionHSTFC132GBClass1092MBs:
      "Thẻ nhớ micro Hikvision HS-TF-C1 32GB Class 10 (92MB/s)",

    body_text_thietbi_tb_GiagomVAT139000dong: "Giá (gồm VAT): 139.000 đồng",

    body_text_thietbi_tb_Thongtinchitiet: "Thông tin chi tiết",

    body_text_thietbi_tb_Maytinhmini: "Máy tính mini",

    body_text_thietbi_tb_GiagomVAT9190000dong:
      "Giá tham khảo (gồm VAT): 9_190.000 đồng",

    body_text_thietbi_tb_BochiaTypeCra7congUSB30:
      "Bộ chia Type C ra 7 cổng USB 3_0",

    body_text_thietbi_tb_GiagomVAT329000dong:
      "Giá tham khảo (gồm VAT): 329.000 đồng",

    body_text_thietbi_tb_OcungSSDSamsung870QVO2TBSATAIII25inch:
      "Ổ cứng SSD Samsung 870 QVO 2TB SATA III 2.5 inch",

    body_text_thietbi_tb_GiagomVAT4899000dong:
      "Giá tham khảo (gồm VAT): 4.899.000 đồng",

    body_text_thietbi_tb_RamLaptopKingstonKVR26S19S68KVR26S19S888GB1x8GBDDR42666MHz:
      "Ram Laptop Kingston (KVR26S19S6/8 / KVR26S19S8/8) 8GB (1x8GB) DDR4 2666MHz",

    body_text_thietbi_tb_GiagomVAT759000dong:
      "Giá tham khảo (gồm VAT): 759.000 đồng",

    body_text_thietbi_tb_CapdulieuUSB20noidai: "Cáp dữ liệu USB 2.0 nối dài",

    body_text_thietbi_tb_Gia5m190000dongGia10m290000dong:
      "Giá tham khảo 5m: 190.000 đồng - Giá 10m: 290.000 đồng",

    body_text_thietbi_tb_Thongtinchitietcap5m: "Thông tin chi tiết cap 5m",

    body_text_thietbi_tb_Thongtinchitietcap10m: "Thông tin chi tiết cap 10m",

    body_text_thietbi_tb_ThekysoACSACOS564: "Thẻ ký số ACS ACOS5-64",

    body_text_thietbi_tb_GiagomVAT50000dong: "Giá (gồm VAT): 50.000 đồng",

    body_text_thietbi_tb_Chinhsachbaomat: "- Chính sách bảo mật",

    body_text_thietbi_tb_DaunoikhongdayVimassUSBTypeA:
      "Đầu nối không dây Vimass USB Type-A",

    body_text_thietbi_tb_GiagomVAT100000dong: "Giá (gồm VAT): 100.000 đồng",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe:
      "Đầu nối có dây 1m Vimass USB Type-A 1 khe đọc thẻ",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe1khedocSIM:
      "Đầu nối có dây 1m Vimass USB Type-A 1 khe đọc thẻ, 1 khe đọc SIM",

    body_text_congty_cn_23517QuyetdinhvedailyvadongiathietbicuaVimass:
      "- Quyết định về đại lý và đơn giá thiết bị của Vimass ngày 17/05/2023",

    body_text_congty_hdh_ntt: "Nguyễn Trọng Thành",

    body_text_congty_hdh_nmv: "Nguyễn Mạnh Vũ",

    body_text_congty_hdh_cvc: "Chu Văn Cường",

    body_text_congty_hdh_tvt: "Trần Việt Trung",

    body_text_congty_hdh_ntnh: "Nguyễn Thị Ngọc Hà",

    body_text_congty_hdh_dht: "Đinh Huy Toàn",

    body_text_congty_hdh_ndt: "Nguyễn Duy Thịnh",

    body_text_congty_hdh_nvt: "Nguyễn Văn Tú",

    body_text_congty_hdh_httt: "Hoàng Thị Thiên Trang",

    body_text_congty_hdh_tvtinh: "Trịnh Văn Tính",

    body_text_congty_hdh_tnt: "Trần Nam Trung",

    body_text_congty_hdh_tth: "Trần Thị Hiền",

    body_text_congty_hdh_lvt: "Lê Việt Tấn",

    body_text_congty_hdh_thb: "Trần Hoài Bắc",

    body_text_congty_hdh_tvh: "Trần Việt Hưng",

    body_text_ungdung_hethongthongtin: "Hệ thống thông tin",
  },

  en: {
    //banner
    banner_web_1: "img/banner_new/Banner1/1-1920x200-E.jpg",

    banner_web_2: "img/banner_new/Banner1/2-1920x200-E.jpg",
    banner_web_3: "img/banner_new/Banner1/3-1920x200-E.jpg",

    banner_phone_1: "img/banner_new/Banner1/1-500x200-E.jpg",

    banner_phone_2: "img/banner_new/Banner1/2-500x200-E.jpg",

    Slide_docTiep: "Read more",

    header_text_gioiThieu: " Introduction",

    header_text_QRAmThanh: "QR Sound",
    header_text_ThuTienBanHang: "Collect money, sell goods by audio QR",
    header_text_ThuTienQR: "Collect money via audio QR",

    header_text_huongDan: " Tutorial",

    header_text_phiDichVu: " Service fee",

    header_text_taiChinh: " Service fee",

    header_text_lienHe: " Contact",

    header_text_hopDong: " Contract",

    header_text_chuKySo: " Digital signature",

    header_text_hoaDonDienTu: " Electronic invoice",

    header_text_hopDongDienTu: " Field",

    header_text_linhVuc: "Lĩnh vực",

    header_text_sanPham: " Product",

    header_text_viDienTu: " Vimass Wallet",

    header_text_theDaNangDauDoc: " Multifunction card & reader",

    header_text_dichVu: " Service",

    header_text_khachHang: " Customer",

    header_text_qrThanhToan: " QR Payment",

    header_text_congThanhToan: " Payment gateway",

    header_text_dichVuCong: " Public service",

    header_text_nhanTienBangQRamThah:"Receive money by audio QR",
header_text_banHangBangQRdong:"Sell by dynamic QR",
header_text_thuChiHoQuaAPI:"Receive and pay on behalf via API",
header_text_giaiPhapChuyenDoiSo:"Digital transformation solution",
header_text_dinhDanhXacThuc:"Identification, payment without phone",
header_text_Gd_Yte_Gt_Dvc:"Education, Health, Transportation, Public Services",
header_text_diemThanhToan_DiemDinhDanh:"Payment Point & Identification Point",
header_text_thietBi_Dichvu:"Equipment & Services",

    body_text_congty_Hoatdongkinhdoanh2024:
      "- Business activities 2024 (Oct 1, 2024)",

    body_text_congty_Hoatdongkinhdoanh:
      "- Business operations until November 2023",

    header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho: "Provincial Hospital",

    header_text_theDaNang_QuanHuyen: "District Hospital",

    header_text_theDaNang_phoCap_boyte: " Commune health facility",

    header_text_theDaNang_phoCap: " Popularization",

    header_text_theDaNang_TCNguoiNgheo: " Poor District Funding",

    header_text_theDaNang_theDongThuongHieu: " Co-branded card",
    header_text_theDaNang_ctThiDiem: "Pilot School",
    header_text_theDaNang_ctTaiTro: "Sponsored School",
    header_text_theDaNang_mamNonTieuHoc: " Primary school",

    header_text_theDaNang_thcsThpt: " Secondary",

    header_text_theDaNang_daiDong: " Dai Dong Secondary",

    header_text_theDaNang_Thpt: " High school",

    header_text_theDaNang_daiHocCaoDang: "  College",

    header_text_theDaNang_daiHoc: " University",

    header_text_theDaNang_bvTinhTp: " Provincial Hospital, City",

    header_text_theDaNang_bvBinhDuong: " Binh Duong Hospital",

    header_text_theDaNang_dichViCong: " Public Service Province, City",

    header_text_theDaNang_phoCap_phocapthedanang: " Universalize Smart Card",

    header_text_theDaNang_phoCap_bogiaoduc: " Ministry of Education",

    header_text_theDaNang_QuanHuyen_qThanhXuan: "  Thanh Xuan District",

    header_text_theDaNang_QuanHuyen_xaDienBan: " Dien Ban Town",

    header_text_theDaNang_QuanHuyen_ThachThat: "  Thach That District",

    header_text_theDaNang_QuanHuyen_xaSonTay: " Son Tay Town",

    header_text_theDaNang_TCNguoiNgheo_CaoBang: " Cao Bang Province",

    header_text_theDaNang_HuyenNgheo: "74 Poor district",

    header_text_TraCuuThongKe: " Statistical",

    header_text_chuyenDoiSo_caiDat: " System",

    header_text_chuyenDoiSo_GiaiPhap: "Solution",

    header_text_saoKe: " Statement",

    header_text_chuyenDoiSo_QRdong: " Dynamic QR",

    header_text_chuyenDoiSo_dichVu: " Service",

    header_text_chuyenDoiSo_HTtudong: " Automatic system",

    header_text_gd: " Transaction",

    header_text_chuyenDoiSo_moHinhThanhToan: " Pilot model",

    body_text_thietbi_tb_GiagomVAT2200000dong:
      " Price (including VAT): 2,200,000 VND",

    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida3000mauvantay:
      " - Store up to 3000 fingerprint samples (1500 people)_",

    body_text_thietbi_tb_ip67: " - IP67 certified",

    header_text_diemThanhToan_ganNhat: " Nearby",

    header_text_diemThanhToan_cuaHang: " Shop",

    header_text_diemThanhToan_xangGas: " Petrol",

    header_text_diemThanhToan_sucKhoe: " Health",
    header_text_diemThanhToan_cuaHangMau: "Sample Store",
    header_text_diemThanhToan_congTacVien: "Contributor",

    header_text_diemThanhToan_dienMay: " Phone",

    header_text_diemThanhToan_vanHoa: " Culture",

    header_text_diemThanhToan_trucTuyen: " Online",

    header_text_diemThanhToan_diChuyen: " Traffic",

    header_text_diemThanhToan_anUong: " F&B",

    header_text_diemThanhToan_giaoDuc: " Education",

    header_text_theDaNang_CTTaiCho: " Sponsor",


    header_text_theDaNang_HTDonvi: " Unit system",

    header_text_theDaNang_NganHangTaiCho: "Companion Bank",

    header_text_ungDung_theDaNang: " Vimass Card",

    header_text_ungDung_gpChuyenDoiSo: "Digital conversion solution",
    header_text_ungDung_htThanhToan: "Payment System",
    header_text_ungDung_htDinhDanh: "Identity system",


    header_text_ungDung_vCard: " VCard",

    header_text_ungDung_vpass: "VPass",

    header_text_ungDung_viVimass: " Vimass Wallet",

    header_text_ungDung_qrVietnam: " QR Vietnam",

    header_text_ungDung_diemravao: " Identification Point",

    header_text_thietBi_thietBi: " Device",

    header_text_thietBi_Maychu: "Server system",

    header_text_thietBi_daiLy: " Agent",

    header_text_thietBi_phanPhoi: "Distribution",
    header_text_thietBi_htMayChu: "Server System",

    header_text_thietBi_htThanhToan: " Payments",

    header_text_thietBi_htVpass: "Unit VPass system",

    header_text_HeThongTruSo: "Headquarters",

    header_text_thietBi_htTraSoatThongKe: "  Check statistics",

    header_text_thietBi_htHoaDonDienTu: "  Electronic Invoice",

    header_text_thietBi_htHopDongDienTu: "  Electronic Contract",

    header_text_thietBi_htChuKySo: "  Digital Signature",

    body_text_thietbi_tb_GiabantaitroVphone:
      " Sponsored selling price (excluding VAT): VND 2,100,000",

    body_text_thietbi_tb_GiabantaitroVtab:
      " Sponsored selling price (excluding VAT): VND 2,240,000",

    header_text_phanMem_qlGiaoDich: " Manage Transaction",

    header_text_phanMem_qlThongTin: " Manage Info",

    header_text_phanMem_qlToaDo: " Manage Coordinates",

    header_text_khachHang_khachHang: " Customer",

    header_text_khachHang_doiTac: " Partner",

    header_text_khachHang_tuyenDung: " Recruitment",

    header_text_khachHang_hoiDap: " FAQ",

    header_text_congTy_coDong: " Shareholder",

    header_text_congTy_hoiDongQuanTri: " Board of Directors",

    header_text_congTy_banKiemSoat: " Board of Supervisors",

    header_text_congTy_banDieuHanh: " Executive Board",

    header_text_congTy_chiNhanh: " Branch",

    footer_text_row_footer1_nganHang: "  Vietnam State Bank",

    footer_text_row_footer1_giayPhep: "  Payment intermediary license 41",

    footer_text_row_footer1_giayPhepXuatNhapKhau:
      " 1. License to export and import civil cryptographic products",

    footer_text_row_footer1_giayPhepKinhDoanh:
      "  2. License to trade in civil cryptographic products and services",

    footer_text_row_footer1_downloadQrVietnam:
      "  Download QR Vietnam application",

    footer_text_row_footer1_downloadViViMass: "  Download Vimass application",

    footer_text_row_footer1_downloadVCard: " Download VCard application",

    footer_text_row_footer1_xemThem: " See more:",

    footer_text_tenCty: " VIETNAM MOBILE-BASED SERVICES JOINT STOCK COMPANY",

    footer_text_hoiSoHaNoi:
      "  Head office: Room 505, 5th floor, Golden Palm Building, 21 Le Van Luong Street, Nhan Chinh, Thanh Xuan, Hanoi - Office 0243.9416205, Email: cskh.vimass@gmail.com",
      footer_text_hoiSoHaNoiQRVN:      "  Head office: Room 506, 5th floor, Golden Palm Building, 21 Le Van Luong Street, Nhan Chinh, Thanh Xuan, Hanoi - Office 0243.9416205, Email: cskh.vimass@gmail.com",

    footer_text_cnHCM:
      " Ho Chi Minh Branch: 4th Floor, 121 Hoang Hoa Tham, Ward 6, Binh Thanh District - Tel 0986609344",

    footer_text_cnDN:
      " Da Nang Branch: 9th Floor, 38 Yen Bai, Hai Chau District - Tel 0918568518",

    footer_text_cnCT:
      " Can Tho Branch: 4th Floor, 30A Hoang Van Thai, Hung Phu Ward, Cai Rang District - Tel 0907978098",

    footer_text_cnHP:
      " Hai Phong Branch: 8th floor, 68 Pham Van Dong, Anh Dung Ward, Duong Kinh District - Tel 0971937026",

    footer_text_cnHue:
      " Hue Branch: 28 Ly Thuong Kiet, Vinh Ninh, Hue City - Tel 0932524546",

    footer_text_cnQN:
      " Quang Ninh Branch: 7th floor, Viettel Building, 70 Nguyen Van Cu, Ha Long City",

    footer_text_cnBDinh:
      " Binh Dinh Branch: No_ 37 Nguyen Hue, Hai Cang Ward, City_ Quy Nhon - Tel 0984922872",

    footer_text_cnNA:
      " Nghe An Branch: 14th Floor, Tower A, PetroVietnam Tower, 7 Quang Trung, Vinh City - Tel 0942707888",

    footer_text_cnTH:
      " Thanh Hoa Branch: 9th Floor, Golden Duc Phat Building, 9 Hac Thanh, Dien Bien Ward, Thanh Hoa City - Tel 0946469704",

    footer_text_cnBD:
      " Binh Duong Branch: 4th floor of BW SCC Office building, Binh Duong Industrial - Service - Urban Complex, Hoa Phu Ward, City_ Thu Dau Mot",

    footer_text_ctyTrucThuoc: " A SUBJECT COMPANY OF VIMASS",
    footer_text_ctyQRVN: "JOINT STOCK COMPANY",

    footer_text_theDaNang: " Smart card",

    footer_text_diemThanhToan: " Payment points",

    footer_text_ungDung: " Application",

    footer_text_thietBi: " Devices",

    footer_text_phanMem: " Software",

    footer_text_dichVu: " Service",

    footer_text_khachHang: " Clients",

    footer_text_cty: " Company",

    footer_text_chuongTrinhTaiTro: " Sponsorship Program",

    footer_text_giaiPhapChuyenDoiSo: " Digital conversion",

    footer_text_diemGiaoDich: " Transaction point",

    footer_text_diemRaVao: " Entry/Exit Point",
    footer_text_banHangBangVietQR: "Selling using VietQR",


    item_footer_0: "Collection via Sound QR",
    item_footer_1: "Collection and Payment service via API",
    item_footer_2: "E-wallet, Payment points, Identification points",
    item_footer_3: "Identify, Authenticate, Payment without using phone",
    item_footer_4: "Education, Medical",
    item_footer_5: "Traffic, Public Services",
    item_footer_6: "Server, Devices, Services",
    item_footer_7: "Company",


    body_title_congty_codong: " COMPANY, SHAREHOLDER, LICENSE",

    body_text_congty_codong_231027QuyetDinhThanhLapBanAnToanThongTin:
      " - Regulations on Vimass Information System dated November 15, 2023",

    body_text_congty_codong_23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le:
      " - Announcement of charter capital registration on September 20, 2023",

    body_text_congty_codong_23_9_5_Quyet_dinh_phan_cong_nhiem_vu:
      " - Decision on assignment of tasks on December 1, 2023",

    body_text_congty_codong_NghiQuyetHoiDongQuanTri16032023:
      " - Resolution of the Board of Directors of Vimass dated 13/06/2023",

    body_text_congty_codong_BienbanhopHDQTVimassngay13062023:
      " - Minutes of Vimass's Board of Directors meeting on July 06, 2023",
      body_text_congty_codong_BienbanhopHDQTVimassngay05082004:
      "- Minutes of Vimass's Board of Directors meeting on August 05, 2024", 
    body_text_congty_codong_HoatDongKinhDoanh15032023:
      " - Business activities in the first 6 months of the year 11/07/2023",

    body_text_congty_codong_23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi:
      " - Decision on equipment selling price on 03/07/2023",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay20072023:
      "  - Minutes of the Extraordinary General Meeting of Shareholders on July 20, 2022",
      body_text_congty_codong_HoSoCapDoAnToanHTTTVIMASS: '- Safety level profile of Vimass headquarters information system March 22, 2024',
    body_text_congty_codong_Danhsachcodongvavongopngay04052023:
      "  - List of shareholders and contributed capital on Oct 03 2024",
      body_text_congty_codong_ChuCapHoiToanHoc:
      "- Resolution of the Board of Directors on funding the Vietnam Mathematical Association Award Fund dated August 7, 2024",
    body_text_congty_codong_Bien_ban_hop_HDQT:
      "- Minutes of the Board of Directors meeting on January 26, 2024",


      body_text_congty_codong_phongchongruatien:
      "- Promulgation of regulations on Anti-Money Laundering 01/05/2023",
      body_text_congty_codong_QDBanHanhbotieuchi:
"- Decision to promulgate a set of criteria for identifying e-wallets with signs of suspected fraud, scams, and violations of the law dated July 18, 2024",
      body_text_congty_codong_ThucHienQuyDinhChinhPhu:
      "- Decision to promulgate and implement regulations according to the Government and the State Bank 18/07/2024",
      
      body_text_congty_codong_ThucHienXoaTaiKhoanVi:
      "- Decision on deleting inactive Wallet accounts 01/01/2022",
      
      body_text_congty_codong_DVTGTT:
      "- Decision of the General Director on promulgating regulations on risk management of providing DVTGTT 18/07/2024", 
      
    body_text_congty_codong_BienbanhopHDQTVimassngay17042023:
      "  - Minutes of Vimass's Board of Directors meeting on April 17, 2023",

    body_text_congty_codong_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      "  - Decision on collaborators to create payment points and distribute equipment on April 17, 2023",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay07042023:
      "  - Minutes of the 2023 Annual General Meeting of Shareholders on April 7, 2023",
      body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay25042024:
      " - Minutes of the 2024 Annual General Meeting of Shareholders on April 25, 2024",
      body_text_congty_codong_ThongtuNHNN:
      "- Circular No. 40/2024/TT-NHNN of the State Bank of Vietnam issued on July 17, 2024",
    body_text_congty_codong_BienbanhopHDQTVimassngay17032023:
      "  - Minutes of Vimass Board of Directors meeting on 17/03/2023",

    body_text_congty_codong_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      "  - Report on activities in 2022, results in 2 months of 2023 and plan for 2023 on March 14, 2022",

    body_text_congty_codong_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      "  - Business activities in the first 2 months of 2023 on March 14, 2022",

    body_text_congty_codong_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      "  - Audit report 2022, Vietnam Mobile-Based Services Joint Stock Company dated 08/03/2022",

    body_text_congty_codong_Baocaokiemtoan2023CongTyCoPhanDichVuNenDiDongVietNamn:
      "- Audited Financial Statements 2023, ViMASS., JSC (11/03/2024)",

    body_text_congty_codong_Quyetdinhvenhomcongtacvienngay22022023:
      "  - Decision on the group of collaborators on 22/02/2023",

    body_text_congty_codong_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      "  - Contract for providing collection and payment services for Vimass QRVN dated 02/02/2023",

    body_text_congty_codong_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "  - Decision on financial service fee of Vimass 01/02/2023",

    body_text_congty_codong_QuyetdinhtrienkhaithunghiemthedanangtaiVietNamngay1712023:
      "  - Decision to test multifunction card in Vietnam on January 17, 2023",

    body_text_congty_codong_Danhsachcodongvavongopngay16012023:
      "  - List of shareholders and contributed capital on January 16, 2023",

    body_text_congty_codong_QuyetdinhcapthevatemchocanbonhanvienVimassngay1612023:
      "  - Decision to issue cards and stamps to Vimass employees on January 16, 2023",

    body_text_congty_codong_BienbanthoathuansudungtenvalogocuaHoihuunghiVietNamHungaryngay09122022:
      "  - Minutes of agreement to use the name and logo of the Vietnam-Hungary Friendship Association on December 9, 2022",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay050224:
      "-License to export and import civil cryptographic products on February 05, 2024",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay29112022:
      "  - License to export and import civil cryptographic products on November 29, 2022",

    body_text_congty_codong_Dangkykinhdoanhlanthu22ngay11102022:
      "  - Business regist ration for the 22nd time on 11/10/2022",

    body_text_congty_codong_Dangkykinhdoanhlanthu25:
      " - 25th business registration on 05/10/2023",

    body_text_congty_codong_QuyetdinhgiamgiolamCBNV:
      " -Decision to reduce working hours and income for 19 employees on October 1, 2023",

    body_text_congty_codong_Dangkykinhdoanhlanthu24ngay6723:
      " - 24th business registration on 06/07/2023",

    body_text_congty_codong_BienbanhopHDQTVimassngay06102022:
      "  - Minutes of Vimass's Board of Directors meeting on October 6, 2022",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay31082022:
      "  - Minutes of the Extraordinary General Meeting of Shareholders on August 31, 2022",

    body_text_congty_codong_BienbanhopHDQTVimassngay02082022:
      "  - Minutes of Vimass's Board of Directors meeting on August 2, 2022",

    body_text_congty_codong_HopdonghoptaccungcapdichvuthuvachihoViMASSQRVNngay11072022:
      "  - Cooperation contract to provide collection and payment services on behalf of ViMASS QRVN dated 11/07/2022",

    body_text_congty_codong_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "  - Decision to provide equipment for employees on July 1, 2022",

    body_text_congty_codong_BienbanhopHDQTngay31052022:
      "  - Minutes of the Board of Directors meeting on May 31, 2022",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhNgheAnngay2752022:
      "  - First business registration of Nghe An branch on May 27, 2022",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhQuangNinhngay20052022:
      "  - First business registration of Quang Ninh branch on May 20, 2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHuengay18052022:
      "  - First business registration in Hue branch on May 18, 2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHaiPhongngay07052022:
      "  - Business registration for the first time in Hai Phong branch on May 7, 2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhCanThongay29042022:
      "  - Business registration for the first time Can Tho branch on April 29, 2022",

    body_text_congty_codong_BienbanhopHDQTngay28042022:
      "  - Business registration for the first time Can Tho branch on April 29, 2022",

    body_text_congty_codong_BaocaohoatdongquyIvakehoach2022ngay26042022:
      "  - Report on activities of the first quarter and 2022 plan on April 26, 2022",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhDaNangngay22032022:
      "  - First business registration in Da Nang branch on March 22, 2022",

    body_text_congty_codong_Dangkykinhdoanhlanthu21ngay11032022:
      "  - Business registration for the 21st time on 11/03/2022(pdf)",

    body_text_congty_codong_BienbanhopDaihoicodongthuongnien2022ngay22022022:
      "  - Minutes of the 2022 Annual General Meeting of Shareholders on February 22, 2022",

    body_text_congty_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022:
      "  - Report of the Chairman of the Board of Directors at the Annual General Meeting of Shareholders on February 21, 2022",

    body_text_congty_codong_BaocaokiemtoanVimass2021ngay27012022:
      "  - Vimass 2021 audit report dated January 27, 2022",

    body_text_congty_codong_BienbanhopHDQT2512022:
      "  - Minutes of the Board of Directors meeting January 25, 2022 (pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu20ngay31122021:
      "  - 20th business registration on December 31, 2021(pdf)",

    body_text_congty_codong_BienbanhopHDQTVimassngay30112021:
      "  - Minutes of Vimass's Board of Directors meeting on November 30, 2021 (pdf)",

    body_text_congty_codong_BienbanhopHDQTVimassngay28102021:
      "  - Minutes of Vimass Board of Directors meeting on 28/10/2021 (pdf)",

    body_text_congty_codong_Bangcandoiketoanvabaocaoketquakinhdoanh9thangdaunam202122102021:
      "  - Balance sheet and income statement for the first 9 months of 2021 October 22, 2021(pdf)",

    body_text_congty_codong_Giayphepkinhdoanhsanphamdichvumatmadansu4102021:
      "  - License to trade in civil cryptographic products and services (pdf) October 4, 2021",

    body_text_congty_codong_BienbanhopHoidongquantriVimassngay2262021:
      "  - Minutes of Vimass Board of Directors meeting on June 22, 2021 (pdf)",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2021ngay2742021:
      "  - Minutes of the 2021 Annual General Meeting of Shareholders on April 27, 2021 (pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu19ngay23032021:
      "  - 19th business registration on 23/03/2021(pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu18ngay15102020:
      "  - 18th business registration on 15/10/2020(pdf)",

    body_text_congty_codong_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      "  - Assign staff to provide public CKS authentication services on April 12, 2019",

    body_text_congty_codong_BienbanvanghiquyethopDHDCDthuongnien2019ngay442019:
      "  - Minutes and resolutions of the 2019 Annual General Meeting of Shareholders on 4/4/2019",

    body_text_congty_codong_NghiquyetHoidongquantriVimassngay242019:
      "  - Resolution of Vimass Board of Directors dated 2/4/2019",

    body_text_congty_codong_Giaymoihopdaihoicodongthuongnien2019ngay2132019:
      "  - Invitation to the 2019 Annual General Meeting of Shareholders on March 21, 2019",

    body_text_congty_codong_Danhsachemailcuacodongngay2132019:
      "  - Email list of shareholders on 21/3/2019",

    body_text_congty_codong_NghiquyetHDQTthongquaviecxincapgiayphepchungthucCKSngay2522019:
      "  - Resolution of the Board of Directors approving the application for a certificate of certification of CKS dated 25/2/2019",

    body_text_congty_codong_NghiquyetHoidongquantriVimassveviecvayvonngay3012019:
      "  - Resolution of Vimass Board of Directors on borrowing capital on January 30, 2019",

    body_text_congty_codong_GiayphepnhapkhauthietbiMMDSngay2812019:
      "  - License to import MMDS equipment on January 28, 2019",

    body_text_congty_codong_DKKDlan6cuachinhanhHCMngay2412019:
      "  - The 6th business registration of the HCM branch on January 24, 2019",

    body_text_congty_codong_BienbanhopHDQTlan1ngay2312019:
      "  - Minutes of the 1st Board of Directors meeting on January 23, 2019",

    body_text_congty_codong_NghiquyetHDQTso1201dQDHDQTHuydongvongop10ngay18122018:
      "  - Board Resolution No_ 1201d_QDHDQT Raising capital contribution 10% on December 18, 2018",

    body_text_congty_codong_MauhopdongchuyennhuongcophanVimass:
      "  - Form of contract for transfer of shares of Vimass (doc)",

    body_text_congty_codong_MaubienbanthanhlyHDCNPVGVimass:
      "  - Form of Minutes of Liquidation of Contract PVG Vimass (doc)",

    body_text_congty_codong_DieulecongtycophandichvunendidongVietNam:
      "  - Charter of Vietnam Mobile Platform Services Joint Stock Company (doc)",

    body_text_congty_codong_Dangkykinhdoanhlan17ngay492018:
      "  - 17th business registration on 4/9/2018",

    body_text_congty_codong_Dangkykinhdoanhlan16ngay1362018:
      "  - 16th business registration on 13/6/2018",

    body_text_congty_codong_BienbanhopHoidongthanhvienthuongnienVimassngay952018:
      "  - Minutes of Vimass Annual Board of Members meeting on 9/5/2018",

    body_text_congty_codong_DangkykinhdoanhVimasslan15ngay442018:
      "  - Registered for the 15th Vimass business on 4/4/2018",

    body_text_congty_codong_Giayphep41GPNHNNcuaNganhangnhanuoc1232018:
      "  - License 41/GPNHNN of the State Bank March 12, 2018",

    body_text_congty_codong_DangkykinhdoanhVimasslan14ngay412018:
      "  - 14th Vimass business registration on 4/1/2018",

    body_text_congty_codong_Dangkykinhdoanhlan6ngay19122013:
      "  - 6th business registration on 19/12/2013",

    body_text_congty_codong_Dangkykinhdoanhlandaungay3152012:
      "  - First business registration on May 31, 2012",

    body_text_congty_bdh_BANDIEUHANH: " EXECUTIVE BOARD",

    body_text_congty_bdh_ChutichHDQT: " Chairman of the Board of Directors",

    body_text_congty_bdh_Tonggiamdoc: " CEO (Chief Executive Officer)",

    body_text_congty_bdh_Photonggiamdocthuongtruc:
      " Deputy CEO (Deputy Chief Executive Officer)",

    body_text_congty_bdh_Photonggiamdocphutrachtaichinh:
      " Deputy CEO in charge of Finance",

    body_text_congty_bdh_Photonggiamdocphutrachcacchinhanh:
      " Deputy CEO in charge of Branches",

    body_text_congty_bdh_Photonggiamdocphutrachthietbivathe:
      " Deputy CEO in charge of Equipment and Cards",

    body_text_congty_bdh_Truongphongmaychu: " Head of Server Department",

    body_text_congty_bdh_PhongWebDesktopMiniVUHF:
      " Web, Desktop, Mini, VUHF Department",

    body_text_congty_bdh_TruongphonglaptrinhiPhone:
      " Head of iPhone Programming Department",

    body_text_congty_bdh_TruongphonglaptrinhAndroidVPhonevaVTablet:
      " Head of Android, VPhone, and VTablet Programming Department",

    body_text_congty_bdh_Chanhvanphong: " Office Manager",

    body_text_congty_bdh_TruongphongMaketing: " Head of Marketing Department",

    body_text_congty_bdh_Danhsachcodongvavongopngay04052023:
      " - List of Shareholders and Contributions on 19/05/2023",

    body_text_congty_bdh_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      " - Contract for Provision of Collection and Payment Services for Vimass QRVN on 02/02/2023",

    body_text_congty_bdh_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - Decision on Financial Service Fees for Vimass on 01/02/2023",

    body_text_congty_bdh_QuyetdinhbonhiemPhotonggiamdocngay31012023:
      " - Decision on the Appointment of Deputy CEO on 31/01/2023",

    body_text_congty_bdh_QuyetdinhbonhiemTruongphongThietbingay31012023:
      " - Decision on the Appointment of Head of Equipment Department on 31/01/2023",

    body_text_congty_bdh_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - Decision on the Provision of Equipment to Employees on 01/07/2022",

    body_text_congty_bdh_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      " - Decision on Disciplinary Action against Employees who Fail to Meet Minimum Job Requirements on 30/06/2022",

    body_text_congty_bdh_Quydinhvelichhopngay28062022:
      " - Regulations on Meeting Schedule on 28/06/2022",

    body_text_congty_bdh_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      " - Decision of the CEO on the Recruitment of ViMASS Employees at Headquarters and Branches on 23/06/2022",

    body_text_congty_bdh_QDcuaTGDvecackhoanchitaiCNngay03062022:
      " - Decision of the CEO on the Expenses at Branches on 03/06/2022",

    body_text_congty_bdh_QuydinhcuaTGDthaydoimucthunhapnhanvien02042022:
      " - Regulations of the CEO on Changing Employee Income Levels on 02/04/2022",

    body_text_congty_bdh_QuydinhveviectaosualuuVimassQR1822022:
      " - Regulations on Creating, Editing, and Saving Vimass QR on 18/2/2022",

    body_text_congty_bdh_Quyetdinhvephidichvudiemthuongvadiemmay1122022:
      " - Decision on Service Fees, Bonus Points, and Lucky Draw Points on 11/2/2022",

    body_text_congty_bdh_QuyetdinhxuphatCBNVkhonghoanthanhnhiemvutoithieu2612022:
      " - Decision on Disciplinary Action against Employees for Failing to Meet Minimum Job Requirements on 26/1/2022",

    body_text_congty_bdh_BienbanhopHDQTVimassngay30112021pdf:
      " - Minutes of the Vimass Board of Directors Meeting on 30/11/2021 (pdf)",

    body_text_congty_bdh_QuyetdinhcuaTongGiamDocveviectuanthutheoThongtuso232019TTNHNN:
      " - Decision of the CEO on Compliance with Circular No_ 23/2019/TTNHNN",

    body_text_congty_bdh_GopychoduthaoThongtusuadoiThongtu392014TTNHNNngay252019:
      " - Comments on the Draft Amendment of Circular No_ 39/2014/TTNHNN on 2/5/2019",

    body_text_congty_bdh_Phancongcanbohotrokhachhangngay2542019:
      " - Assignment of Staff to Support Customers on 25/4/2019",

    body_text_congty_bdh_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      " - Assignment of Staff to Deploy Public Key Infrastructure (PKI) Services on 12/4/2019",

    body_text_congty_hdqt_HOTEN: " NAME",

    body_text_congty_hdqt_CHUCVU: " POSITION",

    body_text_congty_hdqt_ChutichHDQTkiemTonggiamdoccongtycophandichvunendidongVietNam:
      " Chairman of the Board of Directors cum CEO of Mobile Services Corporation, Vietnam",

    body_text_congty_hdqt_PhochutichHDQT:
      " Vice Chairman of the Board of Directors",

    body_text_congty_hdqt_ThanhvienHDQT: " Board Member",

    body_text_congty_hdqt_HOIDONGQUANTRI: " BOARD OF MANAGEMENT",

    body_text_congty_hdqt_truongBanKiemSoat:
      " Chief of Internal Audit Committee",

    body_text_congty_hdqt_thanhVienBanKiemSoat:
      " Member of Internal Audit Committee",

    body_text_congty_cn_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      " - Decision on collaborators for creating payment points and distributing devices dated April 17, 2023",

    body_text_congty_cn_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      " - Report on activities in 2022, results of the first 2 months of 2023 and plans for 2023 dated March 14, 2022",

    body_text_congty_cn_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      " - Business activities in the first 2 months of 2023 dated March 14, 2022",

    body_text_congty_cn_Baocaokiemtoan2022CongTyCoPhanDichVuDienThoaiDiDongVietNamngay08032022:
      " - Audit report 2022, Vietnam Mobile Services Joint Stock Company dated March 8, 2022",

    body_text_congty_cn_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - Decision on the financial service fee of Vimass February 1, 2023",

    body_text_congty_cn_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - Decision on providing equipment for staff dated July 1, 2022",

    body_text_congty_cn_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      " - Decision on penalty for staff failing to meet minimum responsibilities dated June 30, 2022",

    body_text_congty_cn_Quydinhvelichhopngay28062022:
      " - Regulations on meeting schedule dated June 28, 2022",

    body_text_congty_cn_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      " - CEO's decision on recruiting staff for ViMASS at Headquarters and branches dated June 23, 2022",

    body_text_congty_cn_QDcuaTGDvecackhoanchitaiCNngay03062022:
      " - CEO's decision on expenditures at branches dated June 3, 2022",

    body_text_congty_cn_KehoachphattrienChinhanh20222024:
      " - Plan for branch development 2022-2024",

    body_text_dichvu_pdv_TT: "  TT",

    body_text_dichvu_pdv_PHIDICHVUTAICHINH: "  FINANCIAL SERVICES CHARGES",

    body_text_dichvu_pdv_PHITHUDOIVOIBENNHANTHANHTOAN: "  	SERVICE FEE TO PAYEE",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQR:
      "  Use Vimass Wallet, multifunction card to pay to a collection wallet or scan VNPAY QR",

    body_text_dichvu_pdv_Ruttoanbotientuvichuyenthuvetaikhoannganhangluc6h8h10h12h14h16h18hsotientoithieu:
      "  Withdraw all money from collection wallet to bank account at 6am, 8am, 10am, 12pm, 2pm, 4pm, 6pm: (% amount, min 2 cent)",

    body_text_dichvu_pdv_DungungdungnganhangquetVietQRthanhtoanngaydentaikhoannganhangsotientoithieu:
      "  Use a bank application to scan VietQR and pay immediately to your bank account(% amount, min 3 cent))",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang:
      "  Use Vimass Wallet, multifunction card to pay immediately to your bank account",

    body_text_dichvu_pdv_PHITHUDOIVOIBENTHANHTOAN: "  SERVICE FEE TO PAYER",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQRsotientoithieu:
      "  	Use Vimass Wallet, multifunction card to pay and transfer to collection wallets or scan VNPAY QR (% amount, min 250 vnd)",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang2:
      " Use Vimass Wallet, multifunction card to pay immediately to your bank account (% amount, min 3 cent)",

    body_text_dichvu_pdv_YEUCAUCHUYENTIENCHUYENTIEN:
      "  REQUEST FOR MONEY TRANSFER OR MONEY TRANSFER",

    body_text_dichvu_pdv_Chuyentientuvidenvisotientoithieu200dongBaogomvicanhanvidoanhnghiepthedanangvichuyenthucuacuahang:
      "  Wallet-to-wallet transfer (% amount, minimum 250 VND Including personal wallet, business wallet, multifunction card, collection wallet)",

    body_text_dichvu_pdv_Chuyentiennhanhdentaikhoannganhanghoacsothemoigiaodichtoida300trieudong:
      "  	Money transfer to bank account or card number (up to 300 million VND per transaction,% amount, min 3 cent)",

    body_text_dichvu_pdv_NaptienvaoviquaQRhoacquataikhoansotientoithieu:
      "  Top up the wallet via QR or via account (% amount, min 3 cent)",

    body_text_dichvu_pdv_Ruttientuvivetaikhoannganhangmoigiaodichtoida300trieudong:
      "  Withdraw money from your wallet to your bank account (up to 300 million VND per transaction, % amount, min 3 cent)",

    body_text_dichvu_pdv_THUHOtheohopdong:
      " COLLECTION, HOUSEHOLD (under contract)",

    body_text_dichvu_pdv_ChihoVNPOST:
      " a) Collect at VNPOST, 1000 VND per transaction",

    body_text_dichvu_pdv_KhidungungdungnganhangquetQRmattruocthesotientoithieu200dong:
      "   b) When using a banking application, scan the QR code on the front of the card: % of the amount, at least 250 VND",

    body_text_dichvu_pdv_KhidungungdungQRVietnamquetQRmattruoctheThuhoquaThedanangsotientoithieu200dong:
      "  c) When using the QR Vietnam application, scan the QR code on the front of the card, Collect via multifunction card: % of the amount, minimum 250 VND",

    body_text_dichvu_pdv_CHIHOtheohopdong: "  AUTHOZIED EXPENSE (by contract)",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichduoi1trieudong:
      "  d) Recipient is Vimass wallet, transaction value is less than 1 million VND",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtu1trieuden20trieudong:
      "  	e) Recipient is Vimass wallet, transaction value from 1 million to 20 million VND",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtren20trieudong:
      "  f) Recipient is Vimass wallet, transaction value is over 20 million VND",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangduoi100000:
      "  	g) Recipient is an account, the number of transactions a month is less than 100.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu100000den200000:
      "  h) Recipient is an account, the number of transactions a month is from 100.000 to 200.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu200000den500000:
      "  	i) Recipient is an account, the number of transactions per month is from 200.000 to 500.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu500000den1000000:
      "  j) Recipient is an account, the number of transactions a month is from 500.000 to 1.000.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtren1000000:
      "  	k) Recipient is an account, the number of transactions per month is over 1.000.000",

    body_text_dichvu_pdv_CONGTHANHTOANTHE: "  CARDS PAYMENT GATE",

    body_text_dichvu_pdv_Dungthenoidiathanhtoanchodonvihoatdongtronglinhvucytegiaoducgiaothongcongcongdichvucongthuekinhdoanhxangdau:
      "  a) Using domestic cards to pay for units operating in the fields of health, education, public transport, public services, tax, petroleum business",

    body_text_dichvu_pdv_DungthenoidiacuanganhangcoketnoithanhtoanthevoiVimass:
      "  b) Use a domestic card of a bank that has a card payment connection with Vimass",

    body_text_dichvu_pdv_Dungthenoidiacuanganhangchuaketnoi:
      "  	c) Use a domestic card of an unconnected bank",

    body_text_dichvu_pdv_DungtheVISAMasterCardJCBAMEX:
      "  d) Use cards VISA, MasterCard, JCB, AMEX",

    body_text_dichvu_pdv_TIETKIEMTRUCTUYEN: "  ONLINE SAVING",

    body_text_dichvu_pdv_HuyDong:
      " Mobilization (According to regulations of the State Bank)",

    body_text_dichvu_pdv_Chovay:
      " Lending (According to regulations of the State Bank)",

    body_text_dichvu_pdv_Dangcapnhat:
      "  Online savings collection and payment service will be updated",

    body_text_dichvu_pdv_Thoathuan: "  Negotiable",

    body_text_dichvu_pdv_MUCPHI: "  FEE",

    body_text_dichvu_cks_STT: " Sequence number",

    body_text_dichvu_cks_phiVAT: " Fee (including VAT)",

    body_text_dichvu_cks_diemThuong: " VPoints",

    body_text_dichvu_cks_diemMay: " Sewing Point LPoint",

    body_text_dichvu_cks_menhGia: " vnd",

    body_text_dichvu_cks_DICHVUCHUKYSOsudungtheSIMkysoVimass:
      "  DIGITAL SIGNATURE SERVICE (using Vimass digital signing card/SIM)",

    body_text_dichvu_cks_CHUKYSOTHEONAM: "  DIGITAL SIGNATURE BY YEARS",

    body_text_dichvu_cks_Giathuebaochukysocanhan1nam:
      "  Price for registration of personal digital signature for 1 year",

    body_text_dichvu_cks_Giathuebaochukysocanhan2nam:
      "  Price for registration of personal digital signature for 2 years",

    body_text_dichvu_cks_Giathuebaochukysocanhan3nam:
      "  Price for registration of personal digital signature for 3 years",

    body_text_dichvu_cks_Giathuebaochukysotochuc1nam:
      "  Price for digital signature subscription for 1 year",

    body_text_dichvu_cks_Giathuebaochukysotochuc2nam:
      "  Price for digital signature subscription for 2 years",

    body_text_dichvu_cks_Giathuebaochukysotochuc3nam:
      "  Price for digital signature subscription for 3 years",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc1nam:
      "  Renewal of digital signatures for organizations for 1 year",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc2nam:
      "  Renewal of digital signatures for organizations for 2 years",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc3nam:
      "  Renewal of digital signatures for organizations for 3 years",

    body_text_dichvu_cks_CHUKYSOTUNGLAN: "  DIGITAL SIGNATURE EVERY TIME",

    body_text_dichvu_cks_Gia1lankyneuduoi1000lannamkhongduoi8lan:
      "  Price for 1 sign if less than 1.000 times/year (no less than 8 times",

    body_text_dichvu_cks_Gia1lankyneutu1000den10000lannam:
      "  Price of 1 sign if from 1.000 to 10.000 times/year",

    body_text_dichvu_cks_Gia1lankyneutu10000den100000lannam:
      "  Price of 1 sign if from 10.000 to 100.000 times/year",

    body_text_dichvu_cks_Gia1lankyneutu100000den1000000lannam:
      "  Price of 1 sign if from 100.000 to 1.000.000 times/year",

    body_text_dichvu_cks_Gia1lankyneutren1000000lannam:
      "  Price for 1 sign if more than 1.000.000 times/year",

    body_text_dichvu_cks_CHUKYSOTUXA: "  DIGITAL SIGNATURE FROM REMOTE",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrong24gio:
      "  Price for 1 time to sign remotely, no more than 10 times in 24 hours",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrongthang:
      "  Price for 1 time signing remotely no more than 10 times a month",

    body_text_dichvu_cks_Gia10lankytuxathang:
      "  Price 10 times of remote signin /month",

    body_text_dichvu_cks_Gia20lankytuxathang:
      "  Price for 20 remote signings/month",

    body_text_dichvu_cks_Gia50lankytuxathang:
      "  Price 50 times of remote signing/month",

    body_text_dichvu_cks_Gia100lankytuxathang:
      "  Price for 100 remote signings/month",

    body_text_dichvu_cks_Giakytuxakhongqua200lannam:
      "  The price of remote signing is not more than 200 times/year",

    body_text_dichvu_cks_DICHVUCHUKYSORemoteSigning:
      "  DIGITAL SIGNATURE SERVICE (Remote Signing)",

    body_text_dichvu_cks_Chukyso24hKhoitaovakychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong24hketukhicap:
      "  24h digital signature - Initialization and signing (for individuals performing digital transactions_ Initialize and sign no more than 10 times within 24 hours from the date of issue)",

    body_text_dichvu_cks_Chukysothoihan1thangvoi10luotkychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong30ngayketukhicap:
      "  Digital signature valid for 1 month with 10 signatures (for individuals performing digital transactions_ Initialize and sign no more than 10 times within 30 days from the date of issuance)",

    body_text_dichvu_cks_Goibosung10luotkythang:
      "  Additional package 10 signings/month",

    body_text_dichvu_cks_Goibosung20luotkythang:
      "  Additional package of 20 signups/month",

    body_text_dichvu_cks_Goibosung50luotkythang:
      "  Additional package 50 sign/month",

    body_text_dichvu_cks_Goibosung100luotkythang:
      "  Additional pack of 100 signups/month",

    body_text_dichvu_cks_Chukyso1namkhongqua200luotnam:
      "  Signature number 1 year (no more than 200 times/year)",

    main_images_chuongTrinhTaiTro: "img/8cuc/cuc2/taitrothidiemE.jpg",
    main_images_qrAMtHANH: "img/8cuc/cuc1/QRamthanh-e.jpg",

    main_images_theDaNang:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695627265332XTN1F",

    main_images_diemravao:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695627296108f09IF",

    main_images_diemThanhToan:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695627320226VORSF",

    // main_images_home_ungDung: "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17065165099555GJoF",

    main_images_home_ungDung: "img/8cuc/cuc1/giai-phap-cds-e.jpg",

    main_images_home_giaoduc:
    "img/8cuc/cuc1/VPosE.jpg",

    main_images_home_yte: "img/8cuc/cuc1/Thuchiho-e.jpg",

    main_images_home_giaothong: "img/8cuc/cuc1/YteDichvucongGiaothong-e.jpg",
    main_images_home_GD_YTE: "img/8cuc/cuc1/Giaoduc-e.jpg",

    main_images_home_diemgiaodich:
      "img/8cuc/cuc1/diemthanhtoan-e.jpg",

    main_images_thietBi:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630388433Xxc8F",

    main_images_phanMem:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630404090P1EKF",

    main_images_dichVu:
      "img/8cuc/cuc1/thietbivadichvu-e.jpg",

    main_images_khachHang:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630435108WOgsF",

    main_images_congTy:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630447718qqbiF",

    main_images_bannerWeb1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb1-E.jpg",

    main_images_bannerWeb2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb2-E.jpg",

    main_images_bannerMobile1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile1-E.jpg",

    main_images_bannerMobile2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile2-E.jpg",

    body_HopDong_text_title: " Contract",

    body_HopDong_text_hopDongDaKy: " SIGNED CONTRACT",

    body_HopDong_text_ThoathuanhoptacCMCVIMASS:
      " - CMC - VIMASS COOPERATION AGREEMENT",

    body_HopDong_text_ThoathuanhoptacVimassPaytech:
      " - PAYTECH - VIMASS SERVICE COOPERATION AGREEMENT",

    body_HopDong_text_hopdonghoptackinhdoanhdichvuxacthucbangkhuonmatVIMASSvPSPACES:
      " - VIMASS - VPSPACES BUSINESS COOPERATION AGREEMENT FOR FACIAL RECOGNITION SERVICES",

    body_HopDong_text_HoptacvoiVAIS:
      " - VIMASS - VAIS BUSINESS COOPERATION AGREEMENT FOR VOICE AUTHENTICATION SERVICES",

    body_HopDong_text_hopdonghoptacVimassNanosoft:
      " - VIMASS - NANOSOFT COOPERATION AGREEMENT",

    body_HopDong_text_hopdongcungcapdichvuVimassTiem:
      " - VIMASS - TIEM SERVICE SUPPLY AGREEMENT",

    body_HopDong_text_thoathaphoptacthanhtoankhongdungtienmatvimassvafpt:
      " - VIMASS - FPT COOPERATION AGREEMENT ON NON-CASH PAYMENT AND FINANCIAL INCLUSION FOR FPT EDUCATION COMPANY",

    body_HopDong_text_thoathuanhoptackhongdungtienmattaibenhvientpbvavimass:
      " - VIMASS - TPBANK AGREEMENT ON DEPLOYMENT OF NON-CASH PAYMENT AT VIMASS HOSPITAL",

    body_HopDong_text_hopdongbidvvimass:
      " - VIMASS - BIDV SUBSIDIARY COOPERATION AGREEMENT",

    body_HopDong_text_HopdongdichvuthuhoVpbankVimass:
      " - VIMASS - VPB AGREEMENT ON COLLECTION SERVICES",

    body_HopDong_text_DichVuChihovoiVPBankVimass:
      " - VIMASS - VPB AGREEMENT ON SUBSIDIARY DISBURSEMENT SERVICES",

    body_HopDong_text_HopdongVimassIntergate:
      " - VIMASS - INTERGATE COOPERATION AGREEMENT",

    body_HopDong_text_hopdongVimassNapas:
      " - VIMASS - NAPAS COOPERATION AGREEMENT",

    body_HopDong_text_hoptacvimassvavnca:
      " - VIMASS - VINA CA COOPERATION AGREEMENT",

    body_HopDong_text_thongbaogopvon:
      "- Announcement of capital contribution in August 2023 and contribution rights in September 2023",

    body_text_thietbi_tb_TailieukythuatveDNA:
      " - Technical documentation on DNA",

    body_text_thietbi_tb_baohanhhainam: " 2 years warranty",

    body_text_thietbi_tb_baohanhmotnam: " 1 years warranty",

    body_text_thietbi_tb_LinkNXP:
      " https://vimass.vn/vmbank/services/media/viewFile?id=NXP_Smart_Governance_Brochure.pdf",

    body_text_thietbi_tb_Ten: "   Name",

    body_text_thietbi_tb_Thietbibaohanh2nam: "   Devices",

    body_text_thietbi_tb_Ungdung: "   Application",

    body_text_thietbi_tb_Tinhnangkythuat: "Technical features",

    body_text_thietbi_tb_The2chip: " Smart Card (2 chip)",

    body_text_thietbi_tb_Gia100000160000dongthe:
      "  Price: 80.000 - 160.000 VND/card",

    body_text_thietbi_tb_Tuytheodaily: " (Depending on dealer)",

    body_text_thietbi_tb_Tangkembaodungthe: "  Gift card bag",

    body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh:
      " - Secure connections for a smarter world",

    body_text_thietbi_tb_NXPDinhhinhchochinhphudientu:
      " NXP Shapes the eGovernment Market",

    body_text_thietbi_tb_UngdungcuachipNXPUCODE:
      " - Application of chip NXP UCODE",

    body_text_thietbi_tb_Chitiettunhasanxuat: " - NXP Semiconductors company",

    body_text_thietbi_tb_ChitiettunhasanxuatAdvanced:
      "  - Advanced Card Systems Ltd",

    body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile:
      " - MIFARE DESFire EV3 Application Key & File",

    body_text_thietbi_tb_MIFAREDESFireEV3ICBlockdiagram:
      " - MIFARE DESFire EV3 IC Block diagram",

    body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB:
      " - Technical Documentation of Mifare DESFire EV3 Chip 8KB",

    body_text_thietbi_tb_TailieukythuatveUCODE9:
      " - UCODE 9 technical features",

    body_text_thietbi_tb_TinhNangBaoMatEV3: " - EV3''s Features and benefits",

    body_text_thietbi_tb_The3chipkyso: " Smart Card (3 chip)",

    body_text_thietbi_tb_Gia150000210000dongthe:
      " Price: 130.000 - 210.000 VND/card",

    body_text_thietbi_tb_UngdungcuachipkysoACOS564: " - ACOS5-64",

    body_text_thietbi_tb_TinhnangkythuatveUCODE9:
      " - UCODE 9 technical features",

    body_text_thietbi_tb_TemUCODERF: "Tag RFID",

    body_text_thietbi_tb_GiagomVAT30000den60000dong:
      " Price (including VAT): 20.000 - 60.000 VND",

    body_text_thietbi_tb_GiagomVAT1200000dong:
      " Price (including VAT): 1.500.000 VND",

    body_text_thietbi_tb_GiaThamKhao3000000dong:
      " Sponsored selling price (excluding VAT): 1,200,000 VND",

    body_text_thietbi_tb_giaThamKhao: " Reference price",

    body_text_thietbi_tb_giaThamKhao43: " Reference price: 4.390.000 VND",

    body_text_thietbi_tb_giaThamKha549: " Reference price: 5.490.000 VND",

    body_text_thietbi_tb_giaThamKhao649: " Reference price: 6.490.000 VND",

    body_text_thietbi_tb_giaThamKhao10790: " Reference price: 10.790.000 VND",

    body_text_thietbi_tb_Bankemthedanang1themuatoida2nhan:
      " Sold with multifuntion card (1 card buy up to 2 labels)",

    body_text_thietbi_tb_SudungdanotoxemmaycothethaythetheVETCEpassbihong:
      " Use car stickers, watch machines (can replace broken VETC, Epass ___ cards)",

    body_text_thietbi_tb_DungungdungQRVietnamdeghimaEPC:
      " Use app mobile to write EPC code :",

    body_text_thietbi_tb_KhongtrungvoimaEPCdacocuaVimass:
      "  -Not the same as the existing EPC code of Vimass",

    body_text_thietbi_tb_PhaitrungvoimakhachdangsudungcuadonvikhacVETCEpass:
      " - Must match the customer code being used by other units (VETC, Epass ___)",

    body_text_thietbi_tb_Kichthuocanten70x14mm: " - Antenna size: 70x14mm",

    body_text_thietbi_tb_ChatlieuGiaytrang: " - Material: Coated paper",

    body_text_thietbi_tb_ThietbiphatUHFculy7mxuly1theUHFva20theUHF:
      "  UHF transceiver with a range of 7m handles 1 UHF card and 20 UHF cards",

    body_text_thietbi_tb_GiagomVAT2500000dong:
      " Price (including VAT): 2.500.000 VND",

    body_text_thietbi_tb_thongsokythuat: " - UHF transmitter specifications",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy7mxuly1the:
      " - Specifications of UHF transceiver with a range of 7m, handling 1 card",

    body_text_thietbi_tb_ThongsokythuatthietthubiphatUHFculy7mxuly200the:
      "  - Specifications of UHF transceiver with a range of 7m, handling 200 card",

    body_text_thietbi_tb_ThietbiphatUHFculy20mxuly1theUHFva200theUHF:
      " UHF transceiver 20m range handles 1 UHF card and 200 UHF cards",

    body_text_thietbi_tb_GiagomVAT4400000dong:
      " Price (including VAT): 4.400.000 VND",

    body_text_thietbi_tb_thongsokythuatthietbithuphatUHF:
      "  - UHF transmitter specifications",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly1the:
      " - Specifications of UHF transceiver with a range of 20m, handling 1 card",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly200the:
      " - Specifications of UHF transceiver with a range of 20m, handling 200 card",

    body_text_thietbi_tb_VPhone22: " VPhone 22",

    body_text_thietbi_tb_GiagomVAT3000000dong:
      " Price (including VAT): 3.000.000 VND",

    body_text_thietbi_tb_VPhone22GSMAssociation: " - VPhone 22 GSM Association",

    body_text_thietbi_tb_thongsokythuatVPhone22: " - Specifications VPhone22",

    body_text_thietbi_tb_MT6762DCPU: " - MT6762D CPU",

    body_text_thietbi_tb_TechnicaldiagramMT6762: " - Technical diagram MT6762",

    body_text_thietbi_tb_CompareCPUMT6762DwithSC9863:
      " - Compare CPU MT6762D with SC9863",

    body_text_thietbi_tb_UserManual: " - User Manual",

    body_text_thietbi_tb_MotsodienthoaicoNFC: " Some phones have NFC :",

    body_text_thietbi_tb_SamsungGalaxyM22: "   - Samsung Galaxy M22",

    body_text_thietbi_tb_SamsungGalaxyA32: "  - Samsung Galaxy A32",

    body_text_thietbi_tb_DienthoaiSamsungGalaxyNFC:
      " - Samsung Galaxy S phones with NFC from Samsung",

    body_text_thietbi_tb_SamsungGalaxyS6trolen: " - Galaxy S6 or later",

    body_text_thietbi_tb_IPhone7trolen:
      " - iPhone with NFC from iPhone 7 or later",

    body_text_thietbi_tb_VTablet22: " VTablet 22",

    body_text_thietbi_tb_XiaomiRedmi12: " Xiaomi Redmi 12",

    body_text_thietbi_tb_Gia4290000: " Price: 4,290,000 VND",

    body_text_thietbi_tb_ManhinhIPSLCD679FullHD:
      " - Screen: IPS LCD6_79Full HD+",

    body_text_thietbi_tb_CamerasauChinh50MPPhu8MP2MPCameratruoc8MP:
      " - Rear camera: Main 50 MP & Secondary 8 MP, 2 MP; Front camera: 8 MP",

    body_text_thietbi_tb_RAM4GB: " - RAM: 4 GB",

    body_text_thietbi_tb_Dungluongluutru128GB: " - Storage capacity:128 GB",

    body_text_thietbi_tb_SamsungGalaxyA33: " Samsung Galaxy A33",

    body_text_thietbi_tb_gia5490000: " Price: 5,490,000 VND",

    body_text_thietbi_tb_Manhinh64inchSuperAMOLEDFHD:
      " - Screen: 6.4 inch, Super AMOLED, FHD+,",

    body_text_thietbi_tb_Camerasau480MP80MP50MP20MPCameratruoc130MP:
      " - Rear camera: 48_0 MP + 8_0 MP + 5_0 MP + 2.0 MP ; Front camera: 13_0 MP",

    body_text_thietbi_tb_RAM6GB: " - RAM: 6 GB",

    body_text_thietbi_tb_iPhoneXcu: " iPhone X (used)",

    body_text_thietbi_tb_Gia6490000: " Price: 6,490,000 VND",

    body_text_thietbi_tb_Manhinh58inch: " - Screen: 5_8 inch",

    body_text_thietbi_tb_Camerasau12MPCameratruoc7MP:
      " - Rear camera: 12MP ; Front camera: 7MP",

    body_text_thietbi_tb_RAM3GB: " - RAM: 3 GB",

    body_text_thietbi_tb_Dungluongluutru64GB: " - Storage capacity: 64GB",

    body_text_thietbi_tb_httpscellphonescomvniphonex64gboldhtml:
      " - https://cellphones.com.vn/iphone-x-64-gb-old_html",

    body_text_thietbi_tb_iPhone11: " IPhone 11",

    body_text_thietbi_tb_Gia10650000: " Price: 10,650,000 VND",

    body_text_thietbi_tb_Manhinh61inch: " Screen: 6_1 inch",

    body_text_thietbi_tb_Camerasau12MPCameratruoc12MP:
      " Rear camera: 12MP ; Front camera: 12MP",

    body_text_thietbi_tb_Maytinhmini7000000: " Mini Calculator",

    body_text_thietbi_tb_Gia7000000: " Price (including VAT): 9,000,000 VND",

    body_text_thietbi_tb_VPhone23: " VPhone 23",

    body_text_thietbi_tb_dtphankhucre: " (Cheap segment phone)",

    body_text_thietbi_tb_Manhinh: " - 6_0 inch screen, resolution 1440x3200",

    body_text_thietbi_tb_Camera: " - Camera: 16MP front, 32MP rear",

    body_text_thietbi_tb_Dungluong: " - Capacity 8GB + 256GB (memory card)",

    body_text_thietbi_tb_linkmuahangthamkhao: " Reference purchase link",

    body_text_thietbi_tb_CPUCeleronN5105Upto29GHz4Cores4:
      " - CPU: Celeron N5105 (Up to 2.9GHz) 4 Cores 4",

    body_text_thietbi_tb_VGAIntelUHDGraphics: " - VGA: Intel UHD Graphics",

    body_text_thietbi_tb_Ram8GB: " - Ram: 8GB",

    body_text_thietbi_tb_Ocung2TB: " - Hard drive: 2TB",

    body_text_thietbi_tb_MaytinhminiGia15000000: " Mini Calculator",

    body_text_thietbi_tb_Gia15000000: " Price: 15,000,000 VND",

    body_text_thietbi_tb_CPUIntelCorei71260PUpto48GHz12nhan16luong:
      " - CPU: Intel Core i7-1260P (Up to 4_8GHz) 12 cores 16 threads",

    body_text_thietbi_tb_VGAIrisXeGraphics: " - VGA: Iris Xe Graphics",

    body_text_thietbi_tb_GiagomVAT3200000dong:
      " Price (including VAT): 3.200.000 VND",

    body_text_thietbi_tb_nfc: " NFC",

    body_text_thietbi_tb_ThongsokythuatVTablet22:
      " - Specifications VTablet 22",

    body_text_thietbi_tb_Giataitro80000:
      " Sponsorship program price (including VAT): 80,000 VND/card",

    body_text_thietbi_tb_Giataitro130000:
      " Sponsor program price (including VAT): 130,000 VND/card",

    body_text_thietbi_tb_Giataitro20000:
      " Sponsorship program price (including VAT): 20,000 VND/tem",

    body_text_thietbi_tb_ManHinh101inch19201200HD:
      " - 10.1 inch 1920*1200 HD screen,",

    body_text_thietbi_tb_Front5MPBack8MP: " - 5MP front + 8MP back CAMERA",

    body_text_thietbi_tb_Dungluongluutru32GB: " - Storage capacity: 32GB",

    body_text_thietbi_tb_VReader: " VReader",

    body_text_thietbi_tb_GiagomVAT1850000dong:
      " Price (including VAT): 1.850.000 VND",

    body_text_thietbi_tb_NXPPN7642AU: " - NXP-PN7642AU",

    body_text_thietbi_tb_NXPPN7462: " , NXP-PN7462",

    body_text_thietbi_tb_NXPAN12752: " , NXP-AN12752",

    body_text_thietbi_tb_NXPAN12057: " , NXP-AN12057",

    body_text_thietbi_tb_NXPAN10834: " , NXP-AN10834",

    body_text_thietbi_tb_ModulmaytinhNFCmahoaAES3DES:
      " : NFC computer module, AES encryption, 3-DES_",

    body_text_thietbi_tb_ESPWROOM32: " - ESP-WROOM-32",

    body_text_thietbi_tb_ModulemaytinhnangluongthapketnoiWifiBluetoothRS485UARTWeigandvv:
      " : Low energy computer module, Wifi, Bluetooth, RS485, UART, Weigand etc_",

    body_text_thietbi_tb_FS01: " - FS-1",

    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida1000mauvantay:
      " : Fingerprint sensor with 5 levels of security, storing up to 1000 fingerprint samples_",

    body_text_thietbi_tb_AngtenNFCwifidieukhiendongmodongcuaBluetoothRS485UARTWeigandnguon220V12V2A:
      " - NFC antenna, wifi, door open/close control, Bluetooth, RS485, UART, Weigand, 220V-12V, 2A power_",

    body_text_thietbi_tb_PhanmemtraodoidulieuvoithevadienthoaiketnoiWifiBluetoothRS485UARTWeiganddichvuchuyentienthanhtoandichvunganhang:
      " - Software: exchange data with card and phone, connect Wifi, Bluetooth, RS485, UART, Weigand, money transfer service, payment_",

    body_text_thietbi_tb_ThenhomicroHikvisionHSTFC132GBClass1092MBs:
      " Hikvision HS-TF-C1 32GB Class 10 Micro Memory Card (92MB/s)",

    body_text_thietbi_tb_GiagomVAT139000dong:
      " Price (including VAT): 139.000 VND",

    body_text_thietbi_tb_Thongtinchitiet: " Details",

    body_text_thietbi_tb_Maytinhmini: " PC mini",

    body_text_thietbi_tb_GiagomVAT9190000dong:
      "Reference price (including VAT): 9_190.000 VND",

    body_text_thietbi_tb_BochiaTypeCra7congUSB30:
      " Type C splitter to 7 USB 3_0 ports",

    body_text_thietbi_tb_GiagomVAT329000dong:
      " Reference price (including VAT): 329.000 VND",

    body_text_thietbi_tb_OcungSSDSamsung870QVO2TBSATAIII25inch:
      "  Samsung 870 QVO 2TB SATA III 2.5-inch SSD hard drive",

    body_text_thietbi_tb_GiagomVAT4899000dong:
      "  Reference price (including VAT): 4.899.000 VND",

    body_text_thietbi_tb_RamLaptopKingstonKVR26S19S68KVR26S19S888GB1x8GBDDR42666MHz:
      "  Ram Laptop Kingston (KVR26S19S6/8 / KVR26S19S8/8) 8GB (1x8GB) DDR4 2666MHz",

    body_text_thietbi_tb_GiagomVAT759000dong:
      "  Reference price (including VAT): 759.000 VND",

    body_text_thietbi_tb_CapdulieuUSB20noidai: " extension USB 2.0 data cable",

    body_text_thietbi_tb_Gia5m190000dongGia10m290000dong:
      " Reference price 5m: 190.000 VND - Price 10m: 290.000 VND",

    body_text_thietbi_tb_Thongtinchitietcap5m: " 5m _ cap details",

    body_text_thietbi_tb_Thongtinchitietcap10m: " 10m _ cap details",

    body_text_thietbi_tb_ThekysoACSACOS564:
      "  ACS ACOS5-64 digital signature card",

    body_text_thietbi_tb_GiagomVAT50000dong:
      " Price (including VAT): 50.000 VND",

    body_text_thietbi_tb_Chinhsachbaomat: " - Security Policy",

    body_text_thietbi_tb_DaunoikhongdayVimassUSBTypeA:
      " Vimass USB Type-A wireless Card reader",

    body_text_thietbi_tb_GiagomVAT100000dong:
      " Price (including VAT): 100.000 VND",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe:
      "  Wired connector 1m Vimass USB Type-A 1 card reader slot",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe1khedocSIM:
      " Wired connector 1m Vimass USB Type-A 1 card reader slot, 1 SIM reader slot",

    body_text_congty_cn_23517QuyetdinhvedailyvadongiathietbicuaVimass:
      " - Decision on agent and unit price of Vimass equipment dated 17/05/2023",

    body_text_congty_hdh_ntt: " Tony Nguyen",

    body_text_congty_hdh_nmv: " Phillip Nguyen",

    body_text_congty_hdh_cvc: " Kevin Chu",

    body_text_congty_hdh_tvt: " Tran Viet Trung",

    body_text_congty_hdh_ntnh: " Nguyen Thi Ngoc Ha",

    body_text_congty_hdh_dht: " Dinh Huy Toan",

    body_text_congty_hdh_ndt: " Nguyen Duy Thinh",

    body_text_congty_hdh_nvt: " Nguyen Van Tu",

    body_text_congty_hdh_httt: " Hoang Thi Thien Trang",

    body_text_congty_hdh_tvtinh: " Trinh Van Tinh",

    body_text_congty_hdh_tnt: " Tran Nam Trung",

    body_text_congty_hdh_tth: " Tran Thi Hien",

    body_text_congty_hdh_lvt: " Le Viet Tan",

    body_text_congty_hdh_thb: " Tran Hoai Bac",

    body_text_congty_hdh_tvh: " Tran Viet Hung",

    body_text_thietbi_tb_MaytinCPUI7: " Computer CPU i7",

    body_text_thietbi_tb_Gia22374000: " Price (including VAT): 22,374,000 VND",

    body_text_thietbi_tb_MAINBOARD: "- MAINBOARD ASUS PRIME B760M-A WIFI D4",

    body_text_thietbi_tb_CPUINTELCOREI7:
      " - CPU INTEL CORE I7-12700F (UP TO 4_8GHZ, 12 TIMES, 25MB CACHE, 125W) - SOCKET INTEL LGA 1700)",

    body_text_thietbi_tb_TANNHIETKHI: " - DEEPCOOL AK400",

    body_text_thietbi_tb_RAMKINGTON:
      "- RAM DESKTOP KINGSTON FURY BEAST RGB 32GB (2X16GB) DDR4 3200MHZ",

    body_text_thietbi_tb_SSDKingston:
      "- Kingston NV2 M_2 PCIe Gen4 x4 NVMe SSD 2TB",

    body_text_thietbi_tb_NGUONMAYTINH: "- COOLER MASTER COMPUTER SOURCE",

    body_text_thietbi_tb_VOCASE: " - CASE KENOO ESPORT FM800",

    body_text_thietbi_tb_baohanhbanam: " 3 year warranty",

    body_text_ungdung_hethongthongtin: " Information system",
  },

  cn: {
    banner_web_1: "img/banner_new/Banner1/1-1920x200-C.jpg",

    banner_web_2: "img/banner_new/Banner1/2-1920x200-C.jpg",

    banner_web_3: "img/banner_new/Banner1/3-1920x200-C.jpg",

    banner_phone_1: "img/banner_new/Banner1/1-500x200-C.jpg",

    banner_phone_2: "img/banner_new/Banner1/2-500x200-C.jpg",

    Slide_docTiep: "阅读更多",

    // // #top     menu dung chung",

    header_text_gioiThieu: "介绍",

    header_text_QRAmThanh: "QR 声音",

    header_text_ThuTienBanHang: "用音频二维码收钱、卖货",
    header_text_ThuTienQR: "通过音频二维码收钱",

    header_text_huongDan: "指南",

    header_text_phiDichVu: "服务费",

    header_text_taiChinh: "财务",

    header_text_lienHe: "联系",

    header_text_hopDong: "合同",

    header_text_chuKySo: "数字签名",

    header_text_hoaDonDienTu: "电子发票",

    header_text_hopDongDienTu: "电子合同",

    header_text_linhVuc: "场地",

    // // #top     menu chi tiet bai viet",

    header_text_sanPham: "产品",

    header_text_viDienTu: "Vimass 电子钱包",

    header_text_theDaNangDauDoc: "多功能卡和读卡器",

    header_text_dichVu: "服务",

    header_text_khachHang: "客户",

    header_text_qrThanhToan: "二维码支付",

    header_text_congThanhToan: "支付网关",

    header_text_dichVuCong: "公共服务",

    header_text_nhanTienBangQRamThah:"透過音訊二維碼收款",
 header_text_banHangBangQRdong:"使用動態二維碼出售",
 header_text_collectionChiHoQuaAPI:"透過API收款與付款",
 header_text_giaiPhapChuyenDoiSo:"數位轉換解決方案",
 header_text_dinhDanhXacThuc:"身份验证，无需手机即可支付",
 header_text_Gd_Yte_Gt_Dvc:"教育、健康、交通、公共服務",
 header_text_diemThanhToan_DiemDinhDanh:"支付點和識別點",
 header_text_thietBi_Dichvu:"設備與服務",

    // // #top     menu The Da Nang",

    header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho: "省立医院",

    header_text_theDaNang_QuanHuyen: "地区医院",

    header_text_theDaNang_phoCap_boyte: "公社卫生设施",

    header_text_theDaNang_CTTaiCho: "赞助计划",


    header_text_theDaNang_HTDonvi: "单位系统",

    header_text_theDaNang_NganHangTaiCho: "赞助",

    header_text_theDaNang_phoCap: "普及卡",

    header_text_theDaNang_TCNguoiNgheo: "贫困县资金",

    header_text_theDaNang_theDongThuongHieu: "同品牌卡",
    header_text_theDaNang_ctThiDiem: "飞行员学校",
    header_text_theDaNang_ctTaiTro: "赞助学校",

    header_text_theDaNang_mamNonTieuHoc: "幼儿园,小学",

    header_text_theDaNang_thcsThpt: "中学",

    header_text_theDaNang_Thpt: "高中",

    header_text_theDaNang_daiHocCaoDang: " 学院",

    header_text_theDaNang_daiHoc: " 大学",

    header_text_theDaNang_bvTinhTp: "市立医院",

    header_text_theDaNang_bvBinhDuong: "医院 Binh Duong",

    header_text_theDaNang_dichViCong: "公共服务省,市",

    body_text_thietbi_tb_GiagomVAT2200000dong:
      "价格（含增值税）：1,300,000 VND",

    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida3000mauvantay:
      "- 存储多达 3000 个指纹样本（1500 人）。",

    body_text_thietbi_tb_ip67: "- IP67 认证",

    header_text_theDaNang_phoCap_phocapthedanang: "万能卡通用",

    header_text_theDaNang_phoCap_bogiaoduc: "教育部",

    header_text_theDaNang_QuanHuyen_qThanhXuan: "区 Thanh Xuan",

    header_text_theDaNang_QuanHuyen_xaDienBan: "城镇 Dien Ban",

    header_text_theDaNang_QuanHuyen_ThachThat: "区 Thach That",

    header_text_theDaNang_QuanHuyen_xaSonTay: "城镇 Son Tay",

    header_text_theDaNang_TCNguoiNgheo_CaoBang: "省份 Cao Bang",

    header_text_TraCuuThongKe: "统计",

    header_text_chuyenDoiSo_caiDat: "设置",

    header_text_chuyenDoiSo_GiaiPhap: "解决方案",

    header_text_chuyenDoiSo_dichVu: "服务",

    header_text_saoKe: "陈述",

    header_text_chuyenDoiSo_QRdong: "动态二维码",

    header_text_chuyenDoiSo_HTtudong: "自动系统",

    header_text_gd: "交易",

    header_text_chuyenDoiSo_moHinhThanhToan: "试点模型",

    header_text_theDaNang_HuyenNgheo: "贫困区",

    // // #top     menu Diem Thanh Toan",

    header_text_diemThanhToan_ganNhat: "最近的",

    header_text_diemThanhToan_cuaHang: "商店",

    header_text_diemThanhToan_xangGas: "加油站",

    header_text_diemThanhToan_sucKhoe: "医疗",

    header_text_diemThanhToan_cuaHangMau: "示例商店",
    header_text_diemThanhToan_congTacVien: "贡献者",

    header_text_diemThanhToan_dienMay: "电器",

    header_text_diemThanhToan_vanHoa: "文化",

    header_text_diemThanhToan_trucTuyen: "在线",

    header_text_diemThanhToan_diChuyen: "交通",

    header_text_diemThanhToan_anUong: "饮食",

    header_text_diemThanhToan_giaoDuc: "教育",

    // // #top     menu Ung Dung",

    header_text_ungDung_theDaNang: "Vimass 能卡",

    header_text_ungDung_gpChuyenDoiSo: "数字化转型解决方案",
    header_text_ungDung_htThanhToan: "支付系统",
    header_text_ungDung_htDinhDanh: "识别系统",

    header_text_ungDung_vCard: "VCard",

    header_text_ungDung_vpass: "VPass",

    header_text_ungDung_viVimass: "Vimass电子钱包",

    header_text_ungDung_qrVietnam: "越南QR码",

    // # top menu diem ra vao",

    header_text_ungDung_diemravao: "识别点",

    // // #top     menu Thiet Bi",

    header_text_thietBi_thietBi: "设备",

    header_text_thietBi_Maychu: "服务器",

    header_text_thietBi_daiLy: "代理",

    header_text_thietBi_phanPhoi: "分布",

    header_text_thietBi_htMayChu: "服务器系统",

    header_text_thietBi_htThanhToan: "支付设备",

    header_text_thietBi_htVpass: "单位VPass系统",

    header_text_thietBi_htThongtin: "信息",

    header_text_thietBi_htTruso: "总部",

    header_text_thietBi_htTraSoatThongKe: "统计监控设备",

    header_text_thietBi_htHoaDonDienTu: "电子发票设备",

    header_text_thietBi_htHopDongDienTu: "电子合同设备",

    header_text_thietBi_htChuKySo: "数字签名设备",

    // // #top     menu Phan Mem",

    header_text_phanMem_qlGiaoDich: "交易管理软件",

    header_text_phanMem_qlThongTin: "信息管理软件",

    header_text_phanMem_qlToaDo: "坐标管理软件",

    // // #top     menu Khach Hang",

    header_text_khachHang_khachHang: "客户",

    header_text_khachHang_doiTac: "合作伙伴",

    header_text_khachHang_tuyenDung: "招聘",

    header_text_khachHang_hoiDap: "常见问题解答",

    // // #top     menu Cong Ty",

    header_text_congTy_coDong: "股东",

    header_text_congTy_hoiDongQuanTri: "董事会",

    header_text_congTy_banKiemSoat: "监事会",

    header_text_congTy_banDieuHanh: "执行董事会",

    header_text_congTy_chiNhanh: "分支机构",

    // // #top     footer row_footer1",

    footer_text_row_footer1_nganHang: "越南国家银行",

    footer_text_row_footer1_giayPhep: "支付中介许可证 41/GP-NHNN",

    footer_text_row_footer1_giayPhepXuatNhapKhau:
      "1_ 军民密码产品出口入口许可证",

    footer_text_row_footer1_giayPhepKinhDoanh:
      "2_ 军民密码产品和服务经营许可证",

    footer_text_row_footer1_downloadQrVietnam: "下载越南QR码应用",

    footer_text_row_footer1_downloadViViMass: "下载Vimass电子钱包应用",

    footer_text_row_footer1_downloadVCard: "下载VCard应用",

    footer_text_row_footer1_xemThem: "查看更多：",

    // // #top     footer",

    footer_text_tenCty: "越南移动服务股份有限公司",

    footer_text_hoiSoHaNoi:
      "总公司：5楼，金棕榈大厦, 21 Le Van Luong，Nhan Chinh，Thanh Xuan，河内 - 办公室 0243_9416205_ 销售 0247_1069662, Email: cskh.vimass@gmail.com",
      footer_text_hoiSoHaNoiQRVN:           "总公司：5楼，金棕榈大厦, 21 Le Van Luong，Nhan Chinh，Thanh Xuan，河内 - 办公室 0243_9416205_ 销售 0247_1069662, Email: cskh.vimass@gmail.com",


    footer_text_cnHCM:
      "胡志明市分公司：胡志明市黄花街121号4楼，第6区，平壤 - Tel 0986609344",

    footer_text_cnDN:
      "岘港分公司：岘港市仁宝街38号9楼，海洲区 - Tel 0918568518",

    footer_text_cnCT:
      "申歌分公司：申歌市皇皇太街30A号4楼，兴福区 - Tel 0907978098",

    footer_text_cnHP:
      "海防分公司：海防市范文东街68号8楼，安东区 - Tel 0971937026",

    footer_text_cnHue: "順化分公司：順化市永寧鎮李常街28号 - Tel 0932524546",

    footer_text_cnQN: "广宁分公司：下龙市越泰大厦70号维特尔楼7楼",

    footer_text_cnBDinh:
      "平定分行：没有。 市海沧区阮惠37号 归仁 - Tel 0984922872",

    footer_text_cnNA:
      "宜宁分公司：宜宁市广中街7号越南石油大厦A楼14层 - Tel 0942707888",

    footer_text_cnTH:
      "河南分公司：塔塔建华市席士镇9号金都金色大厦9楼 - Tel 0946469704",

    footer_text_cnBD: "平阳分公司：平阳省琳比省平扶区平阳市连巴公园办公室4楼",

    footer_text_ctyTrucThuoc: "ViMASS所属公司",

    footer_text_ctyQRVN: "股份公司",

    footer_text_theDaNang: "多功能卡",

    footer_text_diemThanhToan: "支付点",

    footer_text_ungDung: "应用程序",

    footer_text_thietBi: "设备",

    footer_text_phanMem: "软件",

    footer_text_dichVu: "服务",

    footer_text_khachHang: "客户",

    footer_text_cty: "公司",

    footer_text_chuongTrinhTaiTro: "赞助计划",

    footer_text_giaiPhapChuyenDoiSo: "数字转换",

    footer_text_diemGiaoDich: "交易点",

    body_text_thietbi_tb_giaThamKhao: "参考价格",

    body_text_thietbi_tb_giaThamKhao43: "参考价格: 4.390.000 VND",

    body_text_thietbi_tb_giaThamKha549: "参考价格: 5.490.000 VND",

    body_text_thietbi_tb_giaThamKhao649: "参考价格: 6.490.000 VND",

    body_text_thietbi_tb_giaThamKhao10790: "参考价格: 10.790.000 VND",

    footer_text_diemRaVao: "入口/出口点",
    footer_text_banHangBangVietQR: "使用 VietQR 进行销售",
    // #body_text_title",

    item_footer_0: "通过声音二维码收款",
    item_footer_1: "通过API收款和支付",
    item_footer_2: "电子钱包、支付点、身份认证点",
    item_footer_3: "身份认证、无手机支付",
    item_footer_4: "教育、医疗",
    item_footer_5: "交通、公共服务",
    item_footer_6: "服务器、设备、服务",
    item_footer_7: "公司",

    body_title_congty_codong: "公司，股东，许可证",

    //  // #body_text_congty_codong",

    body_text_congty_codong_231027QuyetDinhThanhLapBanAnToanThongTin:
      "- 2023 年 12 月 12 日《Vimass 信息系统条例》",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay20072023:
      "- 2023年7月20日特别股东大会会议记录",

    body_text_congty_codong_NghiQuyetHoiDongQuanTri16032023:
      "- 2023 年 6 月 13 日 Vimass 董事會決議",

    body_text_congty_codong_BienbanhopHDQTVimassngay13062023:
      "- 2023年7月6日Vimass董事會會議紀要",
      body_text_congty_codong_BienbanhopHDQTVimassngay05082004:
      "-2023年8月5日Vimass董事會會議紀要", 
    body_text_congty_codong_HoatDongKinhDoanh15032023:
      "- 今年前6個月的業務活動 11/07/2023",

    body_text_congty_codong_23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi:
      "- 2023年3月7日关于设备售价的决定",
      body_text_congty_codong_HoSoCapDoAnToanHTTTVIMASS: '- 2024 年 3 月 22 日 Vimass 总部信息系统安全级别概况',
    body_text_congty_codong_Danhsachcodongvavongopngay04052023:
      "- 2024 年 03 月 10 日股東名單和出資",
      body_text_congty_codong_ChuCapHoiToanHoc:
      "- 2024 年 8 月 7 日关于赞助越南数学协会奖金的董事会决议",
    body_text_congty_codong_BienbanhopHDQTVimassngay17042023:
      "- 2023年4月17日Vimass董事会会议记录",

    body_text_congty_codong_Bien_ban_hop_HDQT:
      "- 2024 年 1 月 26 日董事会会议纪要",

      body_text_congty_codong_phongchongruatien:
 "- 2023年5月1日颁布反洗钱条例",

 body_text_congty_codong_ThucHienQuyDinhChinhPhu:
 "- 2024 年 7 月 18 日根据政府和 SBV 颁布实施法规的决定",

 body_text_congty_codong_QDBanHanhbotieuchi:
 "- 决定于 2024 年 7 月 18 日颁布一套标准，以识别具有涉嫌欺诈、诈骗或违法迹象的电子钱包",
 body_text_congty_codong_ThucHienXoaTaiKhanVi:
 "- 2022 年 1 月 1 日删除不活跃钱包账户的决定",

 body_text_congty_codong_DVTGTT:
 "- 2024年7月18日总干事关于颁布提供世界一流服务风险管理条例的决定",

    body_text_congty_codong_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      "- 2023年4月17日有关创建支付点和分销设备的合作伙伴决定",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay07042023:
      "- 2023年4月7日股东常年大会会议记录",

      body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay25042024:
      "- 2024年4月25日股东常年大会会议记录",
      body_text_congty_codong_ThongtuNHNN:
 "- 越南国家银行于 2024 年 7 月 17 日发布的第 40/2024/TT-NHNN 号通知",
    body_text_congty_codong_BienbanhopHDQTVimassngay17032023:
      "- 2023年3月17日Vimass董事会会议记录",

    body_text_congty_codong_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      "- 2022年活动报告，2023年2个月的结果和2023年计划，日期为2022年3月14日",

    body_text_congty_codong_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      "- 2023年头2个月的经营活动，日期为2022年3月14日",

    body_text_congty_codong_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      "- 2022年审计报告，越南移动服务股份有限公司，日期为2022年3月8日",

    body_text_congty_codong_Baocaokiemtoan2023CongTyCoPhanDichVuNenDiDongVietNamn:
      "- 2023 年经审计的财务报表，ViMASS., JSC (11/03/2024)",
    body_text_congty_codong_Quyetdinhvenhomcongtacvienngay22022023:
      "- 关于合作伙伴小组的决定，日期为2023年2月22日",

    body_text_congty_codong_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      "- 2023年2月2日Vimass QRVN支付点服务供应合同",

    body_text_congty_codong_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "- 2023年2月1日关于Vimass金融服务费的决定",

    body_text_congty_codong_QuyetdinhtrienkhaithunghiemthedanangtaiVietNamngay1712023:
      "- 2023年1月17日在越南试行多功能卡的决定",

    body_text_congty_codong_Danhsachcodongvavongopngay16012023:
      "- 2023年1月16日股东名单和出资情况",

    body_text_congty_codong_QuyetdinhcapthevatemchocanbonhanvienVimassngay1612023:
      "- 2023年1月16日关于为Vimass员工发放卡片和标签的决定",

    body_text_congty_codong_BienbanthoathuansudungtenvalogocuaHoihuunghiVietNamHungaryngay09122022:
      "- 2022年12月9日越南匈牙利友好会议使用名称和标志的协议记录",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay050224:
      "- 2024年02月05日军民密码产品出口入口许可证",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay29112022:
      "- 2022年11月29日军民密码产品出口入口许可证",

    body_text_congty_codong_Dangkykinhdoanhlanthu22ngay11102022:
      "- 2022年10月11日第22次营业注册",

    body_text_congty_codong_Dangkykinhdoanhlanthu24ngay6723:
      "- 2023年6月7日第24次商业登记",

    body_text_congty_codong_BienbanhopHDQTVimassngay06102022:
      "- 2022年10月6日Vimass董事会会议记录",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay31082022:
      "- 2022年8月31日特别股东大会会议记录",

    body_text_congty_codong_BienbanhopHDQTVimassngay02082022:
      "- 2022年8月2日Vimass董事会会议记录",

    body_text_congty_codong_HopdonghoptaccungcapdichvuthuvachihoViMASSQRVNngay11072022:
      "- 2022年7月11日ViMASS QRVN支付点服务合作协议",

    body_text_congty_codong_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "- 2022年7月1日为员工提供设备的决定",

    body_text_congty_codong_BienbanhopHDQTngay31052022:
      "- 2022年5月31日董事会会议记录",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhNgheAnngay2752022:
      "- 2022年5月27日，Nghệ An分公司首次注册经营",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhQuangNinhngay20052022:
      "- 2022年5月20日，Quảng Ninh分公司首次注册经营",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHuengay18052022:
      "- 2022年5月18日，Huế分公司首次注册经营",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHaiPhongngay07052022:
      "- 2022年5月7日，Hải Phòng分公司首次注册经营",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhCanThongay29042022:
      "- 2022年4月29日，Cần Thơ分公司首次注册经营",

    body_text_congty_codong_BienbanhopHDQTngay28042022:
      "- 2022年4月28日，董事会会议记录",

    body_text_congty_codong_BaocaohoatdongquyIvakehoach2022ngay26042022:
      "- 2022年4月26日季度活动报告和2022年计划",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhDaNangngay22032022:
      "- 2022年3月22日，Đà Nẵng分公司首次注册经营",

    body_text_congty_codong_Dangkykinhdoanhlanthu21ngay11032022:
      "- 2022年3月11日，第21次注册经营",

    body_text_congty_codong_BienbanhopDaihoicodongthuongnien2022ngay22022022:
      "- 2022年2月22日，年度股东大会会议记录",

    body_text_congty_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022:
      "- 2022年2月21日，年度股东大会董事会报告",

    body_text_congty_codong_BaocaokiemtoanVimass2021ngay27012022:
      "- 2022年1月27日，Vimass审计报告",

    body_text_congty_codong_BienbanhopHDQT2512022:
      "- 2022年1月25日，董事会会议记录",

    body_text_congty_codong_Dangkykinhdoanhlanthu20ngay31122021:
      "- 第20次注册经营，日期：2021年12月31日（pdf）",

    body_text_congty_codong_BienbanhopHDQTVimassngay30112021:
      "- Vimass董事会会议记录，日期：2021年11月30日（pdf）",

    body_text_congty_codong_BienbanhopHDQTVimassngay28102021:
      "- Vimass董事会会议记录，日期：2021年10月28日（pdf）",

    body_text_congty_codong_Bangcandoiketoanvabaocaoketquakinhdoanh9thangdaunam202122102021:
      "- 资产负债表和2021年前9个月经营业绩报告，日期：2021年10月22日（pdf）",

    body_text_congty_codong_Giayphepkinhdoanhsanphamdichvumatmadansu4102021:
      "- 密码民用产品和服务经营许可证，日期：2021年10月4日（pdf）",

    body_text_congty_codong_BienbanhopHoidongquantriVimassngay2262021:
      "- Vimass董事会管理委员会会议记录，日期：2021年6月22日（pdf）",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2021ngay2742021:
      "- 2021年股东年度大会会议记录，日期：2021年4月27日（pdf）",

    body_text_congty_codong_Dangkykinhdoanhlanthu19ngay23032021:
      "- 第19次注册经营，日期：2021年3月23日（pdf）",

    body_text_congty_codong_Dangkykinhdoanhlanthu18ngay15102020:
      "- 第18次注册经营，日期：2020年10月15日（pdf）",

    body_text_congty_codong_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      "- 分派人员以提供公共CKS认证服务，日期：2019年4月12日",

    body_text_congty_codong_BienbanvanghiquyethopDHDCDthuongnien2019ngay442019:
      "- 2019年股东常规大会记录和决议，日期：2019年4月4日",

    body_text_congty_codong_NghiquyetHoidongquantriVimassngay242019:
      "- Vimass董事会管理委员会决议，日期：2019年4月2日",

    body_text_congty_codong_Giaymoihopdaihoicodongthuongnien2019ngay2132019:
      "- 邀请参加2019年股东常年大会的通知，日期为2019年3月21日",

    body_text_congty_codong_Danhsachemailcuacodongngay2132019:
      "- 2019年3月21日股东电子邮件名单",

    body_text_congty_codong_NghiquyetHDQTthongquaviecxincapgiayphepchungthucCKSngay2522019:
      "- 决议通过申请颁发CKS认证许可证的决议，日期为2019年2月25日",

    body_text_congty_codong_NghiquyetHoidongquantriVimassveviecvayvonngay3012019:
      "- 决议关于Vimass董事会进行融资的决议，日期为2019年1月30日",

    body_text_congty_codong_GiayphepnhapkhauthietbiMMDSngay2812019:
      "- MMDS设备进口许可证，日期为2019年1月28日",

    body_text_congty_codong_DKKDlan6cuachinhanhHCMngay2412019:
      "- HCM分公司第6次经营注册，日期为2019年1月24日",

    body_text_congty_codong_BienbanhopHDQTlan1ngay2312019:
      "- 第1次董事会会议记录，日期为2019年1月23日",

    body_text_congty_codong_NghiquyetHDQTso1201dQDHDQTHuydongvongop10ngay18122018:
      "- 决议1201d_QDHDQTHuydongvongop10关于10%出资的决议，日期为2018年12月18日",

    body_text_congty_codong_MauhopdongchuyennhuongcophanVimass:
      "- Vimass股份转让合同样本（doc）",

    body_text_congty_codong_MaubienbanthanhlyHDCNPVGVimass:
      "- Vimass HĐCNPVG解散决议样本（doc）",

    body_text_congty_codong_DieulecongtycophandichvunendidongVietNam:
      "- 越南移动服务股份公司章程（doc）",

    body_text_congty_codong_Dangkykinhdoanhlan17ngay492018:
      "- 第17次经营注册，日期为2018年9月4日",

    body_text_congty_codong_Dangkykinhdoanhlan16ngay1362018:
      "- 第16次经营注册，日期为2018年6月13日",

    body_text_congty_codong_BienbanhopHoidongthanhvienthuongnienVimassngay952018:
      "- Vimass年度董事会成员会议记录，日期为2018年5月9日",

    body_text_congty_codong_DangkykinhdoanhVimasslan15ngay442018:
      "- Vimass第15次经营注册，日期为2018年4月4日",

    body_text_congty_codong_Giayphep41GPNHNNcuaNganhangnhanuoc1232018:
      "- 国家银行批准的41/GPNHNN许可证，日期为2018年3月12日",

    body_text_congty_codong_DangkykinhdoanhVimasslan14ngay412018:
      "- Vimass第14次经营注册，日期为2018年1月4日",

    body_text_congty_codong_Dangkykinhdoanhlan6ngay19122013:
      "- 第6次经营注册，日期为2013年12月19日",

    body_text_congty_codong_Dangkykinhdoanhlandaungay3152012:
      "- 首次经营注册，日期为2012年5月31日",

    //  // #body_text_congty ban dieu han",

    body_text_congty_bdh_BANDIEUHANH: "执行委员会",

    body_text_congty_bdh_ChutichHDQT: "董事会主席",

    body_text_congty_bdh_Tonggiamdoc: "总经理",

    body_text_congty_bdh_Photonggiamdocthuongtruc: "常驻副总经理",

    body_text_congty_bdh_Photonggiamdocphutrachtaichinh: "负责财务的副总经理",

    body_text_congty_bdh_Photonggiamdocphutrachcacchinhanh:
      "负责分支机构的副总经理",

    body_text_congty_bdh_Photonggiamdocphutrachthietbivathe:
      "负责设备和卡的副总经理",

    body_text_congty_bdh_Truongphongmaychu: "服务器部门经理",

    body_text_congty_bdh_PhongWebDesktopMiniVUHF: "网络,桌面,Mini,VUHF部门",

    body_text_congty_bdh_TruongphonglaptrinhiPhone: "iPhone编程部门经理",

    body_text_congty_bdh_TruongphonglaptrinhAndroidVPhonevaVTablet:
      "Android,VPhone和VTablet编程部门经理",

    body_text_congty_bdh_Chanhvanphong: "行政总监",

    body_text_congty_bdh_TruongphongMaketing: "市场部经理",

    body_text_congty_codong_23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le:
      "- 2023年9月20日注册资本登记公告",

    body_text_congty_codong_23_9_5_Quyet_dinh_phan_cong_nhiem_vu:
      "- 2023年1月12日任务分配决定",

    body_text_congty_codong_QuyetdinhgiamgiolamCBNV:
      "- 2023年10月1日决定减少19名员工的工作时间和收入",

    body_text_congty_bdh_Danhsachcodongvavongopngay04052023:
      "- 2023年9月5日股东和出资名单",

    body_text_congty_bdh_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      "- 2023年2月2日提供Vimass QR-VN收支服务合同",

    body_text_congty_bdh_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "- 2023年2月1日有关Vimass金融服务费的决定",

    body_text_congty_bdh_QuyetdinhbonhiemPhotonggiamdocngay31012023:
      "- 2023年1月31日任命副总经理的决定",

    body_text_congty_bdh_QuyetdinhbonhiemTruongphongThietbingay31012023:
      "- 2023年1月31日任命设备部门经理的决定",

    body_text_congty_bdh_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "- 2022年7月1日向员工提供设备的决定",

    body_text_congty_bdh_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      "- 2022年6月30日对未能履行最低职责的员工进行处罚的决定",

    body_text_congty_bdh_Quydinhvelichhopngay28062022:
      "- 2022年6月28日会议日程安排规定",

    body_text_congty_bdh_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      "- 总经理关于在总部和各分支机构招聘ViMASS员工的决定，日期为2022年6月23日",

    body_text_congty_bdh_QDcuaTGDvecackhoanchitaiCNngay03062022:
      "- 总经理关于在CN（分公司）进行费用支出的决定，日期为2022年6月3日",

    body_text_congty_bdh_QuydinhcuaTGDthaydoimucthunhapnhanvien02042022:
      "- 总经理关于调整员工薪资水平的规定，日期为2022年4月2日",

    body_text_congty_bdh_QuydinhveviectaosualuuVimassQR1822022:
      "- 关于创建,修改,保存Vimass QR的规定，日期为2022年2月18日",

    body_text_congty_bdh_Quyetdinhvephidichvudiemthuongvadiemmay1122022:
      "- 关于服务费,奖励点和幸运点的决定，日期为2022年2月11日",

    body_text_congty_bdh_QuyetdinhxuphatCBNVkhonghoanthanhnhiemvutoithieu2612022:
      "- 关于处罚未完成最低任务的员工的决定，日期为2022年1月26日",

    body_text_congty_bdh_BienbanhopHDQTVimassngay30112021pdf:
      "- Vimass董事会会议记录，日期为2021年11月30日（pdf文件）",

    body_text_congty_bdh_QuyetdinhcuaTongGiamDocveviectuanthutheoThongtuso232019TTNHNN:
      "- 总经理关于遵守2019年23号NHNN通知的工作安排的决定",

    body_text_congty_bdh_GopychoduthaoThongtusuadoiThongtu392014TTNHNNngay252019:
      "- 关于2019年5月2日对2014年39号NHNN通知进行修改的草案的意见反馈",

    body_text_congty_bdh_Phancongcanbohotrokhachhangngay2542019:
      "- 分派员工提供客户支持的任务，日期为2019年4月25日",

    body_text_congty_bdh_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      "- 分派员工开展公共电子签名证书认证服务的任务，日期为2019年4月12日",

    //  // #body_text_congty hoi dong quan tri",

    body_text_congty_hdqt_HOTEN: "姓名",

    body_text_congty_hdqt_CHUCVU: "职务",

    body_text_congty_hdqt_ChutichHDQTkiemTonggiamdoccongtycophandichvunendidongVietNam:
      "董事长兼任总经理，移动服务股份有限公司",

    body_text_congty_hdqt_PhochutichHDQT: "副董事长",

    body_text_congty_hdqt_ThanhvienHDQT: "董事会成员",

    body_text_congty_hdqt_HOIDONGQUANTRI: "董事会",

    body_text_congty_hdqt_truongBanKiemSoat: "监事会主席",

    body_text_congty_hdqt_thanhVienBanKiemSoat: "监事会成员",

    //  // #body_text_congty chi nhanh",

    body_text_congty_cn_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      "关于合作伙伴创建支付点和分销设备的决定，日期为2023年4月17日",

    body_text_congty_cn_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      "2022年年度报告,2023年前两个月的业绩和2023年计划报告，日期为2022年3月14日",

    body_text_congty_cn_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      "2023年前两个月的经营活动报告，日期为2022年3月14日",

    body_text_congty_cn_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      "2022年审计报告，越南移动服务股份有限公司，日期为2022年3月8日",

    body_text_congty_cn_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      "Vimass财务服务费决定，日期为2023年2月1日",

    body_text_congty_cn_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      "为员工提供设备的决定，日期为2022年7月1日",

    body_text_congty_cn_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      "对于未能履行最低任务的员工的处罚决定，日期为2022年6月30日",

    body_text_congty_cn_Quydinhvelichhopngay28062022:
      "会议时间安排的规定，日期为2022年6月28日",

    body_text_congty_cn_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      "总经理关于在总部和分支机构招聘ViMASS员工的决定，日期为2022年6月23日",

    body_text_congty_cn_QDcuaTGDvecackhoanchitaiCNngay03062022:
      "总经理关于在分支机构的各项支出的决定，日期为2022年6月3日",

    body_text_congty_cn_KehoachphattrienChinhanh20222024:
      "分支机构发展计划2022-2024年",

    body_text_dichvu_pdv_TT: " 命令",

    body_text_dichvu_pdv_PHIDICHVUTAICHINH: "金融服务收费",

    body_text_dichvu_pdv_PHITHUDOIVOIBENNHANTHANHTOAN: "收款人的服务费",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQR:
      "使用Vimass钱包，多功能卡支付到收藏钱包或扫描VNPAY二维码",

    body_text_dichvu_pdv_Ruttoanbotientuvichuyenthuvetaikhoannganhangluc6h8h10h12h14h16h18hsotientoithieu:
      "在上午 6 点,上午 8 点,上午 10 点,中午 12 点,下午 2 点,下午 4 点,下午 6 点将所有钱从收款钱包提取到银行账户：（% 金额，最少 2 美分）",

    body_text_dichvu_pdv_DungungdungnganhangquetVietQRthanhtoanngaydentaikhoannganhangsotientoithieu:
      "使用银行应用程序扫描 VietQR 并立即支付到您的银行帐户（% 金额，最少 3 美分））",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang:
      "使用 Vimass 钱包，多功能卡立即支付到您的银行账户",

    body_text_dichvu_pdv_PHITHUDOIVOIBENTHANHTOAN: "支付给付款人的服务费",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQRsotientoithieu:
      "使用 Vimass 钱包，多功能卡支付并转移到收藏钱包或扫描 VNPAY 二维码（% 金额，最少 250 vnd",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang2:
      "使用 Vimass 钱包，多功能卡立即支付到您的银行账户（% 金额，最少 3 美分）",

    body_text_dichvu_pdv_YEUCAUCHUYENTIENCHUYENTIEN: "请求汇款或汇款",

    body_text_dichvu_pdv_Chuyentientuvidenvisotientoithieu200dongBaogomvicanhanvidoanhnghiepthedanangvichuyenthucuacuahang:
      "钱包到钱包转账（%金额，最低 250 VND。包括个人钱包,商务钱包,多功能卡,收藏钱包）",

    body_text_dichvu_pdv_Chuyentiennhanhdentaikhoannganhanghoacsothemoigiaodichtoida300trieudong:
      "汇款至银行账户或卡号（每笔交易最多 3 亿越南盾，% 金额，最低 3 美分）",

    body_text_dichvu_pdv_NaptienvaoviquaQRhoacquataikhoansotientoithieu:
      "通过二维码或账户充值钱包（% 金额，最少 3 美分）",

    body_text_dichvu_pdv_Ruttientuvivetaikhoannganhangmoigiaodichtoida300trieudong:
      "从您的钱包中提取资金到您的银行账户（每笔交易最高 3 亿越南盾，% 金额，最低 3 美分）",

    body_text_dichvu_pdv_THUHOtheohopdong: "COLLECTION（按合同）",

    body_text_dichvu_pdv_KhidungungdungnganhangquetQRmattruocthesotientoithieu200dong:
      "a) 使用银行应用程序时，扫描卡正面的二维码：金额的百分比，至少 250 越南盾",

    body_text_dichvu_pdv_KhidungungdungQRVietnamquetQRmattruoctheThuhoquaThedanangsotientoithieu200dong:
      "b) 使用 QR Vietnam 应用程序时，扫描卡正面的 QR 码，通过多功能卡收款：金额的百分比，最低 250 VND",

    body_text_dichvu_pdv_CHIHOtheohopdong: "授权费用（按合同）",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichduoi1trieudong:
      "a) 收件人是Vimass钱包，交易金额低于100万越南盾",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtu1trieuden20trieudong:
      "b) 收件人是Vimass钱包，交易金额从100万到2000万越南盾",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtren20trieudong:
      "c) 收件人是Vimass钱包，交易金额超过2000万越南盾",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangduoi100000:
      "d) 收件人是一个账户，一个月的交易数小于100.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu100000den200000:
      "e) 收件人是一个账户，一个月的交易数从100.000到200.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu200000den500000:
      "f) 收件人是一个账户，每个月的交易数从200.000到500.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu500000den1000000:
      "g) 收件人是一个账户，一个月的交易量是500.000到1.000.000",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtren1000000:
      "h) 收件人是一个账户，每月交易数超过1.000.000",

    body_text_dichvu_pdv_CONGTHANHTOANTHE: "卡支付门",

    body_text_dichvu_pdv_Dungthenoidiathanhtoanchodonvihoatdongtronglinhvucytegiaoducgiaothongcongcongdichvucongthuekinhdoanhxangdau:
      "a) 使用国内卡支付在卫生,教育,公共交通,公共服务,税收,石油业务等领域运营的单位",

    body_text_dichvu_pdv_DungthenoidiacuanganhangcoketnoithanhtoanthevoiVimass:
      "b) 使用与Vimass有卡支付连接的国内银行卡",

    body_text_dichvu_pdv_Dungthenoidiacuanganhangchuaketnoi:
      "c) 使用未关联银行的国内卡",

    body_text_dichvu_pdv_DungtheVISAMasterCardJCBAMEX:
      "d) 使用卡 VISA, MasterCard, JCB, AMEX",

    body_text_dichvu_pdv_TIETKIEMTRUCTUYEN: "在线保存",

    body_text_dichvu_pdv_HuyDong: "动员（根据国家银行规定）",

    body_text_dichvu_pdv_Chovay: "贷款（根据国家银行规定）",

    body_text_dichvu_pdv_Dangcapnhat: "更新在线储蓄收付服务",

    body_text_dichvu_pdv_Thoathuan: "可协商",

    body_text_dichvu_pdv_MUCPHI: "费用",

    // #Phi chu ky so",

    body_text_dichvu_cks_STT: "序列号",

    body_text_dichvu_cks_phiVAT: "费用（包括增值税）",

    body_text_dichvu_cks_diemThuong: "VPoints",

    body_text_dichvu_cks_diemMay: "缝纫点 LPoint",

    body_text_dichvu_cks_menhGia: "vnd",

    body_text_dichvu_cks_DICHVUCHUKYSOsudungtheSIMkysoVimass:
      " 数字签名服务（使用 Vimass 数字签名卡/SIM）",

    body_text_dichvu_cks_CHUKYSOTHEONAM: " 数字签名年份",

    body_text_dichvu_cks_Giathuebaochukysocanhan1nam:
      " 个人数字签名注册价格1年",

    body_text_dichvu_cks_Giathuebaochukysocanhan2nam:
      " 个人数字签名注册2年价格",

    body_text_dichvu_cks_Giathuebaochukysocanhan3nam:
      " 3年个人数字签名注册价格",

    body_text_dichvu_cks_Giathuebaochukysotochuc1nam: " 数字签名订阅价格 1 年",

    body_text_dichvu_cks_Giathuebaochukysotochuc2nam: " 数字签名订阅价格 2 年",

    body_text_dichvu_cks_Giathuebaochukysotochuc3nam: " 3 年数字签名订阅价格",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc1nam:
      " 为组织更新数字签名 1 年",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc2nam:
      " 为组织更新数字签名 2 年",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc3nam:
      " 为组织更新数字签名 3 年",

    body_text_dichvu_cks_CHUKYSOTUNGLAN: " 每次都有数字签名",

    body_text_dichvu_cks_Gia1lankyneuduoi1000lannamkhongduoi8lan:
      " 1.000 次/年以下（不少于 8 次）的标牌价格",

    body_text_dichvu_cks_Gia1lankyneutu1000den10000lannam:
      " 如果从 1.000 到 10.000 次/年，则 1 个标志的价格",

    body_text_dichvu_cks_Gia1lankyneutu10000den100000lannam:
      " 如果从 10.000 到 100.000 次/年，则 1 个标志的价格",

    body_text_dichvu_cks_Gia1lankyneutu100000den1000000lannam:
      " 如果从 100.000 到 1.000.000 次/年，则 1 个标志的价格",

    body_text_dichvu_cks_Gia1lankyneutren1000000lannam:
      " 如果每年超过 1.000.000 次，则 1 个标志的价格",

    body_text_dichvu_cks_CHUKYSOTUXA: " 来自远程的数字签名",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrong24gio:
      " 远程签单1次价格，24小时内不超过10次",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrongthang:
      " 每月 1 次远程签名的价格不超过 10 次",

    body_text_dichvu_cks_Gia10lankytuxathang: " 价格10次远程登录/月",

    body_text_dichvu_cks_Gia20lankytuxathang: " 每月 20 次远程签名的价格",

    body_text_dichvu_cks_Gia50lankytuxathang: " 远程签约50次/月价格",

    body_text_dichvu_cks_Gia100lankytuxathang: " 每月 100 次远程签名的价格",

    body_text_dichvu_cks_Giakytuxakhongqua200lannam:
      " 远程签约价格不超过200次/年",

    body_text_dichvu_cks_DICHVUCHUKYSORemoteSigning:
      " 数字签名服务（远程签名）",

    body_text_dichvu_cks_Chukyso24hKhoitaovakychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong24hketukhicap:
      " 24h数字签名-初始化和签名（适用于进行数字交易的个人。自签发之日起24小时内初始化和签名不超过10次）",

    body_text_dichvu_cks_Chukysothoihan1thangvoi10luotkychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong30ngayketukhicap:
      " 数字签名有效期1个月，签名10次（适用于进行数字交易的个人。自签发之日起30天内初始化签名不超过10次）",

    body_text_dichvu_cks_Goibosung10luotkythang: " 附加套餐 10 签约/月",

    body_text_dichvu_cks_Goibosung20luotkythang: " 每月 20 个注册的附加包",

    body_text_dichvu_cks_Goibosung50luotkythang: " 附加套餐50签/月",

    body_text_dichvu_cks_Goibosung100luotkythang: " 额外包 100 个注册/月",

    body_text_dichvu_cks_Chukyso1namkhongqua200luotnam:
      " 签名次数1年（不超过200次/年）",

    body_HopDong_text_thongbaogopvon:
      "- 关于2023年8月份的资本捐款通知和2023年9月份捐款权利_",

    // #LinkAnhMain",

    main_images_chuongTrinhTaiTro: "img/8cuc/cuc2/taitrothidiemCN.jpg",
    main_images_qrAMtHANH: "img/8cuc/cuc1/QRamthanh-c.jpg",

    main_images_theDaNang:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=theDaNang-CN.jpg",

    main_images_diemravao:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=diemdinhdanh-CN.jpg",

    main_images_diemThanhToan:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=diemThanhToan-CN.jpg",

    main_images_home_ungDung:
      "img/8cuc/cuc1/giai-phap-cds-c.jpg",

    main_images_thietBi:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695634440694P48KF",

    main_images_phanMem:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695634454785KvICF",

    main_images_home_giaoduc:
    "img/8cuc/cuc1/VPosC.jpg",

    main_images_home_yte: "img/8cuc/cuc1/Thuchiho-cn.jpg",

    main_images_home_giaothong: "img/8cuc/cuc1/YteDichvucongGiaothong-c.jpg",
    main_images_home_GD_YTE: "img/8cuc/cuc1/Giaoduc-c.jpg",

    main_images_home_diemgiaodich:
      "img/8cuc/cuc1/diemthanhtoan-cn.jpg",

    main_images_dichVu:
      "img/8cuc/cuc1/thietbivadichvu-cn.jpg",

    main_images_khachHang:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=khachHang-CN.jpg",

    main_images_congTy:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695634600832AnvWF",

    // #LinkAnhBanner",

    main_images_bannerWeb1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb1-K.jpg",

    main_images_bannerWeb2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb2-K.jpg",

    main_images_bannerMobile1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile1-K.jpg",

    main_images_bannerMobile2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile2-K.jpg",

    // #HopDong",

    body_HopDong_text_title: "合同",

    body_HopDong_text_hopDongDaKy: "已签署的合同",

    body_HopDong_text_ThoathuanhoptacCMCVIMASS: "- CMC - VIMASS 合作协议",

    body_HopDong_text_ThoathuanhoptacVimassPaytech:
      "- PAYTECH - VIMASS 服务合作协议",

    body_HopDong_text_hopdonghoptackinhdoanhdichvuxacthucbangkhuonmatVIMASSvPSPACES:
      "- VIMASS - VPSPACES 人脸识别服务业务合作协议",

    body_HopDong_text_HoptacvoiVAIS: "- VIMASS - VAIS 语音认证服务业务合作协议",

    body_HopDong_text_hopdonghoptacVimassNanosoft:
      "- VIMASS - NANOSOFT 合作协议",

    body_HopDong_text_hopdongcungcapdichvuVimassTiem:
      "- VIMASS - TIEM 服务供应协议",

    body_HopDong_text_thoathaphoptacthanhtoankhongdungtienmatvimassvafpt:
      "- VIMASS - FPT 非现金支付和金融普惠合作协议（针对FPT教育公司）",

    body_HopDong_text_thoathuanhoptackhongdungtienmattaibenhvientpbvavimass:
      "- VIMASS - TPBANK 医院非现金支付部署合作协议",

    body_HopDong_text_hopdongbidvvimass: "- VIMASS - BIDV 子公司合作协议",

    body_HopDong_text_HopdongdichvuthuhoVpbankVimass:
      "- VIMASS - VPB 代收服务合作协议",

    body_HopDong_text_DichVuChihovoiVPBankVimass:
      "- VIMASS - VPB 子公司支付服务合作协议",

    body_HopDong_text_HopdongVimassIntergate: "- VIMASS - INTERGATE 合作协议",

    body_HopDong_text_hopdongVimassNapas: "- VIMASS - NAPAS 合作协议",

    body_text_congty_Hoatdongkinhdoanh2024:
      "- 2024 年业务活动（2024 年 10 月 1 日）",

    body_text_congty_Hoatdongkinhdoanh: "- 2023 年商业活动",

    body_HopDong_text_hoptacvimassvavnca: "- VIMASS - VINA CA 合作协议",

    // #thietbi",

    body_text_thietbi_tb_baohanhhainam: "2年保修",

    body_text_thietbi_tb_baohanhmotnam: "1年保修",

    body_text_thietbi_tb_LinkNXP:
      "https://web.vimass.vn/vmbank/services/media/viewFile?id=NXP_Smart_Governance_Brochure.pdf",

    body_text_thietbi_tb_Ten: "이름",

    body_text_thietbi_tb_Thietbibaohanh2nam: "장치",

    body_text_thietbi_tb_Ungdung: "애플리케이션",

    body_text_thietbi_tb_Tinhnangkythuat: "기술적 특징",

    body_text_thietbi_tb_The2chip: "스마트 카드(2칩)",

    body_text_thietbi_tb_Gia100000160000dongthe:
      "가격: 80.000 - 160.000 VND/카드",

    body_text_thietbi_tb_Tuytheodaily: "(딜러에 따라 다름)",

    body_text_thietbi_tb_Tangkembaodungthe: "기프트 카드 가방",

    body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh:
      "- 더 스마트한 세상을 위한 보안 연결",

    body_text_thietbi_tb_NXPDinhhinhchochinhphudientu:
      "전자 정부 시장을 형성하는 NXP",

    body_text_thietbi_tb_UngdungcuachipNXPUCODE: "- 칩 NXP UCODE 적용",

    body_text_thietbi_tb_Chitiettunhasanxuat: "- 恩智浦半导体公司",

    body_text_thietbi_tb_ChitiettunhasanxuatAdvanced:
      " - Advanced Card Systems Ltd",

    body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile:
      "- MIFARE DESFire EV3 애플리케이션 키 및 파일",

    body_text_thietbi_tb_MIFAREDESFireEV3ICBlockdiagram:
      "- MIFARE DESFire EV3 IC 블록 다이어그램",

    body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB:
      "- Mifare DESFire EV3 칩 8KB의 기술 문서",

    body_text_thietbi_tb_TailieukythuatveUCODE9: "- UCODE 9 기술 기능",

    body_text_thietbi_tb_TinhNangBaoMatEV3: "- EV3 的安全功能",

    body_text_thietbi_tb_The3chipkyso: "스마트 카드(3칩)",

    body_text_thietbi_tb_Gia150000210000dongthe:
      "가격: 130.000 - 210.000 VND/카드",

    body_text_thietbi_tb_UngdungcuachipkysoACOS564: "- ACOS5-64",

    body_text_thietbi_tb_TinhnangkythuatveUCODE9: "- UCODE 9 기술 기능",

    body_text_thietbi_tb_TemUCODERF: "태그 RFID",

    body_text_thietbi_tb_GiagomVAT30000den60000dong:
      "가격(VAT 포함): 20.000 - 60.000 VND",

    body_text_thietbi_tb_GiagomVAT1200000dong:
      "价格（含增值税）：1_500.000 VND",

    body_text_thietbi_tb_GiaThamKhao3000000dong:
      "赞助售价（不含增值税）：1,200,000 VND",

    body_text_thietbi_tb_Bankemthedanang1themuatoida2nhan:
      "다기능 카드와 함께 판매(카드 1개 구매 시 최대 2개 라벨)",

    body_text_thietbi_tb_SudungdanotoxemmaycothethaythetheVETCEpassbihong:
      "자동차 스티커, 시계 기계 사용(깨진 VETC, Epass ___ 카드 교체 가능)",

    body_text_thietbi_tb_DungungdungQRVietnamdeghimaEPC:
      "QR 베트남 앱을 사용하여 EPC 코드 작성:",

    body_text_thietbi_tb_KhongtrungvoimaEPCdacocuaVimass:
      "- Vimass의 기존 EPC 코드와 동일하지 않음",

    body_text_thietbi_tb_PhaitrungvoimakhachdangsudungcuadonvikhacVETCEpass:
      "- 다른 장치(VETC, Epass ___)에서 사용 중인 고객 코드와 일치해야 합니다_",

    body_text_thietbi_tb_Kichthuocanten70x14mm: "- 안테나 크기: 70x14mm",

    body_text_thietbi_tb_ChatlieuGiaytrang: "- 재질: 코팅지",

    body_text_thietbi_tb_ThietbiphatUHFculy7mxuly1theUHFva20theUHF:
      "7m 범위의 UHF 트랜시버는 1개의 UHF 카드와 20개의 UHF 카드를 처리합니다_",

    body_text_thietbi_tb_GiagomVAT2500000dong: "가격(VAT 포함): 2_500.000 VND",

    body_text_thietbi_tb_thongsokythuat: "- UHF 송신기 사양",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy7mxuly1the:
      "- 7m 범위의 UHF 송수신기 사양, 카드 1개 처리",

    body_text_thietbi_tb_ThongsokythuatthietthubiphatUHFculy7mxuly200the:
      "- 7m 범위의 UHF 송수신기 사양, 200 개 카드 처리",

    body_text_thietbi_tb_ThietbiphatUHFculy20mxuly1theUHFva200theUHF:
      "UHF 트랜시버 20m 범위는 UHF 카드 1개와 UHF 카드 200개를 처리합니다_",

    body_text_thietbi_tb_GiagomVAT4400000dong: "가격(VAT 포함): 4_400.000 VND",

    body_text_thietbi_tb_thongsokythuatthietbithuphatUHF: "- UHF 송신기 사양",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly1the:
      "- 20m 범위의 UHF 송수신기 사양, 카드 1개 처리",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly200the:
      "- 200개의 카드를 처리하는 20m 범위의 UHF 송수신기 사양",

    body_text_thietbi_tb_VPhone22: "VPhone 22",

    body_text_thietbi_tb_GiagomVAT3000000dong: "가격(VAT 포함): 3.000.000 VND",

    body_text_thietbi_tb_VPhone22GSMAssociation: "- VPhone 22 GSM 연결",

    body_text_thietbi_tb_GiabantaitroVphone:
      "赞助售价（不含增值税）：VND 2,100,000",

    body_text_thietbi_tb_GiabantaitroVtab:
      "赞助售价（不含增值税）：VND 2,240,000",

    body_text_thietbi_tb_thongsokythuatVPhone22: "- 사양 VPhone22",

    body_text_thietbi_tb_MT6762DCPU: "- MT6762D CPU",

    body_text_thietbi_tb_TechnicaldiagramMT6762: "- 기술 다이어그램 MT6762",

    body_text_thietbi_tb_CompareCPUMT6762DwithSC9863:
      "- CPU MT6762D와 SC9863 비교",

    body_text_thietbi_tb_UserManual: "- 사용 설명서",

    body_text_thietbi_tb_MotsodienthoaicoNFC: "일부 휴대폰에는 NFC가 있습니다_",

    body_text_thietbi_tb_SamsungGalaxyM22: "- 삼성 갤럭시 M22",

    body_text_thietbi_tb_SamsungGalaxyA32: "- 삼성 갤럭시 A32",

    body_text_thietbi_tb_DienthoaiSamsungGalaxyNFC:
      "- Samsung의 NFC가 탑재된 Samsung Galaxy S 휴대폰",

    body_text_thietbi_tb_SamsungGalaxyS6trolen: "- Galaxy S6 이상",

    body_text_thietbi_tb_IPhone7trolen: "- iPhone 7 이상에서 NFC가 있는 iPhone",

    body_text_thietbi_tb_VTablet22: "V태블릿 22",

    body_text_thietbi_tb_XiaomiRedmi12: "小米红米 12",

    body_text_thietbi_tb_Gia4290000: "价格：4,290,000 越南盾",

    body_text_thietbi_tb_ManhinhIPSLCD679FullHD: "- 屏幕：IPS LCD6_79全高清+",

    body_text_thietbi_tb_CamerasauChinh50MPPhu8MP2MPCameratruoc8MP:
      "- 后置摄像头：主 50 MP 和辅助 8 MP,2 MP； 前置摄像头：8 MP",

    body_text_thietbi_tb_RAM4GB: "- 内存：4 GB",

    body_text_thietbi_tb_Dungluongluutru128GB: "- 存储容量：128 GB",

    body_text_thietbi_tb_SamsungGalaxyA33: "三星 Galaxy A33",

    body_text_thietbi_tb_gia5490000: "价格：5,490,000 越南盾",

    body_text_thietbi_tb_Manhinh64inchSuperAMOLEDFHD:
      "- 屏幕：6.4 英寸，Super AMOLED，FHD+，",

    body_text_thietbi_tb_Camerasau480MP80MP50MP20MPCameratruoc130MP:
      "- 后置摄像头：48_0 MP + 8_0 MP + 5_0 MP + 2_0 MP ； 前置摄像头：13_0 MP",

    body_text_thietbi_tb_RAM6GB: "- 内存：6 GB",

    body_text_thietbi_tb_iPhoneXcu: "iPhone X（二手）",

    body_text_thietbi_tb_Gia6490000: "价格：6,490,000 越南盾",

    body_text_thietbi_tb_Manhinh58inch: "- 屏幕：5_8 英寸",

    body_text_thietbi_tb_Camerasau12MPCameratruoc7MP:
      "- 后置摄像头：12MP ； 前置摄像头：7MP",

    body_text_thietbi_tb_RAM3GB: "- 内存：3 GB",

    body_text_thietbi_tb_Dungluongluutru64GB: "- 存储容量：64GB",

    body_text_thietbi_tb_httpscellphonescomvniphonex64gboldhtml:
      "- https://cellphones.com.vn/iphone-x-64-gb-old_html",

    body_text_thietbi_tb_iPhone11: "iPhone 11",

    body_text_thietbi_tb_Gia10650000: "价格：10,650,000 越南盾",

    body_text_thietbi_tb_Manhinh61inch: "屏幕：6_1 英寸",

    body_text_thietbi_tb_Camerasau12MPCameratruoc12MP:
      "后置摄像头：12MP ； 前置摄像头：12MP",

    body_text_thietbi_tb_Maytinhmini7000000: "迷你计算器",

    body_text_thietbi_tb_Gia7000000: "价格 (VAT 포함)：9,000,000 越南盾",

    body_text_thietbi_tb_dtphankhucre: "（廉价段电话）",

    body_text_thietbi_tb_Manhinh: "- 6_0英寸屏幕，分辨率1440x3200",

    body_text_thietbi_tb_Camera: "- 摄像头：16MP前置，32MP后置",

    body_text_thietbi_tb_Dungluong: "- 容量 8GB + 256GB（存储卡）",

    body_text_thietbi_tb_linkmuahangthamkhao: "参考购买链接",

    body_text_thietbi_tb_VPhone23: "VPhone 23",

    body_text_thietbi_tb_CPUCeleronN5105Upto29GHz4Cores4:
      "- CPU：Celeron N5105（高达 2_9GHz）4 核 4",

    body_text_thietbi_tb_VGAIntelUHDGraphics: "- VGA：英特尔超高清显卡",

    body_text_thietbi_tb_Ram8GB: "- 内存：8GB",

    body_text_thietbi_tb_Ocung2TB: "- 硬盘：2TB",

    body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc11essentialkitatlascanyonceleronn5105bnuc11atkc40000variantid69490990:
      "- https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-11-essential-kit-atlas-canyon-celeron-nat5105-bneron -nat :694990990",

    body_text_thietbi_tb_MaytinhminiGia15000000: "迷你计算器",

    body_text_thietbi_tb_Gia15000000: "价格：15,000,000 越南盾",

    body_text_thietbi_tb_CPUIntelCorei71260PUpto48GHz12nhan16luong:
      "- CPU：Intel Core i7-1260P（高达 4_8GHz）12 核 16 线程",

    body_text_thietbi_tb_VGAIrisXeGraphics: "- VGA：虹膜 Xe 图形",

    body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc12prowallstreetanyoni71260prnuc12wshi70000variantid85683670:
      "https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-12-pro-wall-street-canyon-i7-1260p-rnuc12w:700 00？ 85683670",

    body_text_thietbi_tb_GiagomVAT3200000dong: "가격(VAT 포함): 3.200.000 VND",

    body_text_thietbi_tb_ThongsokythuatVTablet22: "- 规格 VTablet 22",

    body_text_thietbi_tb_ManHinh101inch19201200HD:
      "- 10_1英寸1920*1200高清屏幕，",

    body_text_thietbi_tb_Front5MPBack8MP: "- 5MP 前置 + 8MP 后置摄像头",

    body_text_thietbi_tb_Dungluongluutru32GB: "- 存储容量：32GB",

    body_text_thietbi_tb_VReader: "VReader",

    body_text_thietbi_tb_nfc: " NFC",

    body_text_thietbi_tb_GiagomVAT1850000dong: "가격(VAT 포함): 1.000.000 VND",

    body_text_thietbi_tb_NXPPN7642AU: "- NXP-PN7642AU",

    body_text_thietbi_tb_NXPPN7462: " NXP-PN7462",

    body_text_thietbi_tb_NXPAN12752: " NXP-AN12752",

    body_text_thietbi_tb_NXPAN12057: " NXP-AN12057",

    body_text_thietbi_tb_Giataitro80000:
      "赞助计划价格（含增值税）：80,000 VND/卡",

    body_text_thietbi_tb_Giataitro130000:
      "赞助商计划价格（含增值税）：130,000 越南盾/卡",

    body_text_thietbi_tb_Giataitro20000:
      "赞助计划价格（含增值税）：20,000 越南盾/TEM",

    body_text_thietbi_tb_NXPAN10834: " NXP-AN10834",

    body_text_thietbi_tb_ModulmaytinhNFCmahoaAES3DES:
      ": NFC电脑模块，AES加密，3-DES。",

    body_text_thietbi_tb_ESPWROOM32: "- ESP-WROOM-32",

    body_text_thietbi_tb_ModulemaytinhnangluongthapketnoiWifiBluetoothRS485UARTWeigandvv:
      ": 低功耗计算机模块，Wifi，蓝牙，RS485，UART，Weigand 等。",

    body_text_thietbi_tb_FS01: "- FS-1",

    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida1000mauvantay:
      ": 具有 5 个安全级别的指纹传感器，最多可存储 1000 个指纹样本。",

    body_text_thietbi_tb_AngtenNFCwifidieukhiendongmodongcuaBluetoothRS485UARTWeigandnguon220V12V2A:
      "- NFC 天线,wifi,门开/关控制,蓝牙,RS485,UART,Weigand,220V-12V,2A 电源。",

    body_text_thietbi_tb_PhanmemtraodoidulieuvoithevadienthoaiketnoiWifiBluetoothRS485UARTWeiganddichvuchuyentienthanhtoandichvunganhang:
      "- 软件：用卡和手机交换数据，连接 Wifi，蓝牙，RS485，UART，Weigand，汇款服务，支付_",

    body_text_thietbi_tb_ThenhomicroHikvisionHSTFC132GBClass1092MBs:
      "Hikvision HS-TF-C1 32GB 클래스 10 마이크로 메모리 카드(92MB/s)",

    body_text_thietbi_tb_GiagomVAT139000dong: "가격(VAT 포함): 139.000 VND",

    body_text_thietbi_tb_Thongtinchitiet: "세부 정보",

    body_text_thietbi_tb_Maytinhmini: "PC 미니",

    body_text_thietbi_tb_GiagomVAT9190000dong: "가격(VAT 포함): 9_190.000 VND",

    body_text_thietbi_tb_BochiaTypeCra7congUSB30:
      "USB 3_0 포트 7개에 대한 Type C 스플리터",

    body_text_thietbi_tb_GiagomVAT329000dong: "가격(VAT 포함): 329.000 VND",

    body_text_thietbi_tb_OcungSSDSamsung870QVO2TBSATAIII25inch:
      "Samsung 870 QVO 2TB SATA III 2_5인치 SSD 하드 드라이브",

    body_text_thietbi_tb_GiagomVAT4899000dong: "가격(VAT 포함): 4.899.000 VND",

    body_text_thietbi_tb_RamLaptopKingstonKVR26S19S68KVR26S19S888GB1x8GBDDR42666MHz:
      "램 노트북 Kingston(KVR26S19S6/8 / KVR26S19S8/8) 8GB(1x8GB) DDR4 2666MHz",

    body_text_thietbi_tb_GiagomVAT759000dong: "가격(VAT 포함): 759.000 VND",

    body_text_thietbi_tb_CapdulieuUSB20noidai: "확장 USB 2_0 데이터 케이블",

    body_text_thietbi_tb_Gia5m190000dongGia10m290000dong:
      "가격 5m: 190.000 VND - 가격 10m: 290.000 VND",

    body_text_thietbi_tb_Thongtinchitietcap5m: "5m _ 캡 세부 정보",

    body_text_thietbi_tb_Thongtinchitietcap10m: "10m _ 캡 세부 정보",

    body_text_thietbi_tb_ThekysoACSACOS564: "ACS ACOS5-64 디지털 서명 카드",

    body_text_thietbi_tb_GiagomVAT50000dong: "가격(VAT 포함): 50.000 VND",

    body_text_thietbi_tb_Chinhsachbaomat: "- 보안 정책",

    body_text_thietbi_tb_DaunoikhongdayVimassUSBTypeA:
      "Vimass USB Type-A 무선 카드 리더기",

    body_text_thietbi_tb_GiagomVAT100000dong: "가격(VAT 포함): 100.000 VND",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe:
      "유선 커넥터 1m Vimass USB Type-A 카드 리더 슬롯 1개",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe1khedocSIM:
      "유선 커넥터 1m Vimass USB Type-A 카드 판독기 슬롯 1개, SIM 판독기 슬롯 1개",

    body_text_congty_cn_23517QuyetdinhvedailyvadongiathietbicuaVimass:
      "- Decision on agent and unit price of Vimass equipment dated 17/05/2023",

    body_text_congty_hdh_ntt: "Tony Nguyen",

    body_text_congty_hdh_nmv: "Phillip Nguyen",

    body_text_congty_hdh_cvc: "Kevin Chu",

    body_text_congty_hdh_tvt: "Tran Viet Trung",

    body_text_congty_hdh_ntnh: "Nguyen Thi Ngoc Ha",

    body_text_congty_hdh_dht: "Dinh Huy Toan",

    body_text_congty_hdh_ndt: "Nguyen Duy Thinh",

    body_text_congty_hdh_nvt: "Nguyen Van Tu",

    body_text_congty_hdh_httt: "Hoang Thi Thien Trang",

    body_text_congty_hdh_tvtinh: "Trinh Van Tinh",

    body_text_congty_hdh_tnt: "Tran Nam Trung",

    body_text_congty_hdh_tth: "Tran Thi Hien",

    body_text_congty_hdh_lvt: "Le Viet Tan",

    body_text_congty_hdh_thb: "Tran Hoai Bac",

    body_text_congty_hdh_tvh: "Tran Viet Hung",

    body_text_thietbi_tb_MaytinCPUI7: "计算机 CPU i7",

    body_text_thietbi_tb_Gia22374000: "价格（含增值税）：22,374,000 VND",

    body_text_thietbi_tb_MAINBOARD: "- 主板 华硕 PRIME B760M-A WIFI D4",

    body_text_thietbi_tb_CPUINTELCOREI7:
      "- CPU INTEL CORE I7-12700F（高达 4_8GHZ，12 倍，25MB 缓存，125W） - 插槽 INTEL LGA 1700）",

    body_text_thietbi_tb_TANNHIETKHI: "- DEEPCOOL AK400",

    body_text_thietbi_tb_RAMKINGTON:
      "- 内存台式机金士顿愤怒野兽 RGB 32GB (2X16GB) DDR4 3200MHZ",

    body_text_thietbi_tb_SSDKingston:
      "- 金士顿 NV2 M_2 PCIe Gen4 x4 NVMe SSD 2TB",

    body_text_thietbi_tb_NGUONMAYTINH: "- COOLER MASTER 计算机源",

    body_text_thietbi_tb_VOCASE: "- 案例 KENOO ESPORT FM800",

    body_text_thietbi_tb_baohanhbanam: "3 年保修",

    body_text_ungdung_hethongthongtin: "信息系统",
  },

  kr: {
    banner_web_1: "img/banner_new/Banner1/1-1920x200-K.jpg",

    banner_web_2: "img/banner_new/Banner1/2-1920x200-K.jpg",

    banner_web_3: "img/banner_new/Banner1/3-1920x200-K.jpg",

    banner_phone_1: "img/banner_new/Banner1/1-500x200-K.jpg",

    banner_phone_2: "img/banner_new/Banner1/2-500x200-K.jpg",

    Slide_docTiep: "계속 읽기",

    header_text_gioiThieu: " 소개",
    header_text_QRAmThanh: "QR 소리",

    header_text_ThuTienBanHang: "오디오 QR로 돈을 모으고 상품을 판매하세요",

    header_text_ThuTienQR: "오디오 QR을 통해 돈을 모으십시오",

    header_text_huongDan: " 안내",

    header_text_phiDichVu: " 서비스 요금",

    header_text_taiChinh: " 재무",

    header_text_lienHe: " 연락처",

    header_text_hopDong: " 계약",

    header_text_chuKySo: " 전자서명",

    header_text_hoaDonDienTu: " 전자세금계산서",
    header_text_hopDongDienTu: " 전자계약서",
    header_text_linhVuc: "필드",
    // // #top     menu chi tiet bai viet",
    header_text_sanPham: " 제품",
    header_text_viDienTu: "Vimass 전자 지갑",
    header_text_theDaNangDauDoc: " 멀티펑션 카드 & 리더",
    header_text_dichVu: " 서비스",
    header_text_khachHang: " 고객",
    header_text_qrThanhToan: " QR 결제",
    header_text_congThanhToan: " 결제 게이트웨이",
    header_text_dichVuCong: " 공공 서비스",
    header_text_nhanTienBangQRamThah:"오디오 QR로 돈 받기",
 header_text_banHangBangQRdong:"동적 QR을 사용하여 판매",
 header_text_collectionChiHoQuaAPI:"API를 통한 영수증 및 결제",
 header_text_giaiPhapChuyenDoiSo:"디지털 변환 솔루션",
 header_text_dinhDanhXacThuc:"신분증, 휴대폰 없이 결제",
 header_text_Gd_Yte_Gt_Dvc:"교육, 건강, 교통, 공공 서비스",
 header_text_diemThanhToan_DiemDinhDanh:"결제 포인트 및 식별 포인트",
 header_text_thietBi_Dichvu:"장치 및 서비스",
    // // #top     menu The Da Nang",
    header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho: "성 병원",
    header_text_theDaNang_QuanHuyen: "지구 병원",
    header_text_theDaNang_phoCap_boyte: "코뮌 보건 시설",
    header_text_theDaNang_CTTaiCho: " 후원 프로그램",
    header_text_theDaNang_HTDonvi: " 단위 체계",
    header_text_theDaNang_NganHangTaiCho: "동반은행",
    header_text_theDaNang_phoCap: " 대중화",
    header_text_theDaNang_TCNguoiNgheo: " 가난한 지구를 위한 기금",
    header_text_theDaNang_theDongThuongHieu: "동상 브랜드 카드",
    header_text_theDaNang_ctTaiTro: "후원 학교",
    header_text_theDaNang_mamNonTieuHoc: "  초등학교",
    header_text_theDaNang_thcsThpt: " 중등",
    header_text_theDaNang_daiDong: " Dai Dong 중등",
    header_text_theDaNang_Thpt: "  고등학교",
    header_text_theDaNang_daiHocCaoDang: "  단과대학",
    header_text_theDaNang_daiHoc: " 대학",
    header_text_theDaNang_bvTinhTp: " 시립병원",
    header_text_theDaNang_bvBinhDuong: " 병원 Binh Duong",
    header_text_theDaNang_dichViCong: " 공공 서비스 성, 시",
    body_text_thietbi_tb_GiagomVAT2200000dong: " 가격(VAT 포함): 1,300,000 VND",
    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida3000mauvantay:
      " - 최대 3000개의 지문 샘플(1500명)을 저장합니다.",
    body_text_thietbi_tb_ip67: " - IP67 인증",
    header_text_theDaNang_phoCap_phocapthedanang: " 유니버설 카드 범용",
    header_text_theDaNang_phoCap_bogiaoduc: " 교육부",
    header_text_theDaNang_QuanHuyen_qThanhXuan: " 구역 Thanh Xuan",
    header_text_theDaNang_QuanHuyen_xaDienBan: " 마을 Dien Ban",
    header_text_theDaNang_QuanHuyen_ThachThat: " 구역 Thach That",
    header_text_theDaNang_QuanHuyen_xaSonTay: " 마을 Son Tay",
    header_text_theDaNang_TCNguoiNgheo_CaoBang: " 지방 Cao Bang",
    header_text_TraCuuThongKe: "   검색",
    header_text_chuyenDoiSo_GiaiPhap: "해결책",
    header_text_chuyenDoiSo_caiDat: "체계",
    header_text_saoKe: " 성명서",
    header_text_chuyenDoiSo_QRdong: "동적 QR",
    header_text_chuyenDoiSo_HTtudong: " 자동 시스템",
    header_text_chuyenDoiSo_dichVu: " 서비스",
    header_text_gd: " 거래",
    header_text_chuyenDoiSo_moHinhThanhToan: " 파일럿 모델",
    header_text_theDaNang_HuyenNgheo: "74 가난한 지구",
    // // #top     menu Diem Thanh Toan",
    header_text_diemThanhToan_ganNhat: " 가장 가까운",
    header_text_diemThanhToan_cuaHang: " 매장",
    header_text_diemThanhToan_xangGas: " 가스 충전소",
    header_text_diemThanhToan_sucKhoe: " 건강",
    header_text_diemThanhToan_cuaHangMau: "示例商店",
    header_text_diemThanhToan_congTacVien: "기여자",
    header_text_diemThanhToan_dienMay: " 가전제품",
    header_text_diemThanhToan_vanHoa: " 문화",
    header_text_diemThanhToan_trucTuyen: " 온라인",
    header_text_diemThanhToan_diChuyen: " 이동",
    header_text_diemThanhToan_anUong: " 식사",
    header_text_diemThanhToan_giaoDuc: " 교육",
    // // #top     menu Ung Dung",
    header_text_ungDung_theDaNang: " 다낭 카드",

    header_text_ungDung_gpChuyenDoiSo: "디지털 혁신 솔루션",
    header_text_ungDung_htThanhToan: "결제 시스템",
    header_text_ungDung_htDinhDanh: "식별 시스템",

    header_text_ungDung_vCard: " VCard",
    header_text_ungDung_vpass: "VPass",
    header_text_ungDung_viVimass: " Vimass 지갑",
    header_text_ungDung_qrVietnam: " QR 베트남",
    // # top menu diem ra vao",
    header_text_ungDung_diemravao: " 식별 포인트",
    // // #top     menu Thiet Bi",
    header_text_thietBi_thietBi: " 장비",
    header_text_thietBi_Maychu: "섬기는 사람",
    header_text_thietBi_daiLy: " 에이전트",
    header_text_thietBi_phanPhoi: "배포",
    header_text_thietBi_htThanhToan: " 지불 시스템",
    header_text_thietBi_htMayChu: "서버 시스템",
    header_text_thietBi_htVpass: "유닛 VPass 시스템",
    header_text_thietBi_htThongtin: "정보",
    header_text_thietBi_htTruso: "본부",
    header_text_thietBi_htTraSoatThongKe: " 거래 내역 시스템",
    header_text_thietBi_htHoaDonDienTu: " 전자세금계산서 시스템",
    header_text_thietBi_htHopDongDienTu: " 전자계약서 시스템",
    header_text_thietBi_htChuKySo: " 전자서명 시스템",
    body_text_thietbi_tb_GiabantaitroVphone:
      " 후원 판매 가격(VAT 제외): VND 2,100,000",
    body_text_thietbi_tb_GiabantaitroVtab:
      " 후원 판매 가격(VAT 제외): VND 2,240,000",
    // // #top     menu Phan Mem",
    header_text_phanMem_qlGiaoDich: " 거래 관리",
    header_text_phanMem_qlThongTin: " 정보 관리",
    header_text_phanMem_qlToaDo: " 좌표 관리",
    // #top     menu Khach Hang",
    header_text_khachHang_khachHang: " 고객",
    header_text_khachHang_doiTac: " 파트너",
    header_text_khachHang_tuyenDung: " 채용",
    header_text_khachHang_hoiDap: " 문의",
    // #top     menu Cong Ty",
    header_text_congTy_coDong: " 주주",
    header_text_congTy_hoiDongQuanTri: " 이사회",
    header_text_congTy_banKiemSoat: " 감사위원회",
    header_text_congTy_banDieuHanh: " 경영진",
    header_text_congTy_chiNhanh: " 지점",
    // #top     footer row_footer1",
    footer_text_row_footer1_nganHang: " 베트남 국립은행",
    footer_text_row_footer1_giayPhep: " 결제 중개자 라이센스 41 / GP-NHNN",
    footer_text_row_footer1_giayPhepXuatNhapKhau:
      " 1. 민간 암호 제품의 수출입 허가증",
    footer_text_row_footer1_giayPhepKinhDoanh:
      " 2. 민간 암호 제품 및 서비스 영업 허가증",
    footer_text_row_footer1_downloadQrVietnam: " QR 베트남 앱 다운로드",
    footer_text_row_footer1_downloadViViMass: " ViVimass 앱 다운로드",
    footer_text_row_footer1_downloadVCard: " VCard 앱 다운로드",
    footer_text_row_footer1_xemThem: " 더 보기 :",
    // #top     footer",
    footer_text_tenCty: " 베트남 모바일 서비스 주식회사",
    footer_text_hoiSoHaNoi:
      " 본사: 5층, Golden Palm Building, 21 Le Van Luong Street, Nhan Chinh, Thanh Xuan, Hanoi - 사무실 0243.9416205. 사업 0247.1069662, Email: cskh.vimass@gmail.com",
      footer_text_hoiSoHaNoiQRVN:       " 본사: 5층, Golden Palm Building, 21 Le Van Luong Street, Nhan Chinh, Thanh Xuan, Hanoi - 사무실 0243.9416205. 사업 0247.1069662, Email: cskh.vimass@gmail.com",


    footer_text_cnHCM:
      " 호치민지사: Binh Thanh 구, 6구, Hoang Hoa Tham 거리 4층 - Tel 0986609344",
    footer_text_cnDN:
      " 다낭지사: Hai Chau 구, Yen Bai 거리 9층 - Tel 0918568518",
    footer_text_cnCT:
      " Can Tho지사: Cai Rang 구, Hung Phu, Hoang Van Thai 거리 4층 - Tel 0907978098",
    footer_text_cnHP:
      " Hai Phong지사: Duong Kinh 구, Anh Dung, Pham Van Dong 거리 8층 - Tel 0971937026",
    footer_text_cnHue:
      " Hue지사: Vinh Ninh, Ly Thuong Kiet, Hue시 - Tel 0932524546",
    footer_text_cnQN:
      " Quang Ninh지사: Ha Long시, Nguyen Van Cu 거리, Viettel빌딩 7층",
    footer_text_cnBDinh:
      " Binh Dinh 지점: 아니요. 37 Nguyen Hue, Hai Cang Ward, City. 퀴논 - Tel 0984922872",
    footer_text_cnNA:
      " Nghe An지사: Vinh시, Quang Trung, Viettel Petroleum Tower A 14층 - Tel 0942707888",
    footer_text_cnTH:
      " Thanh Hoa지사: Thanh Hoa시, Dien Bien, Golden Duc Phat Building 9층 - Tel 0946469704",
    footer_text_cnBD:
      " Binh Duong지사: Thu Dau Mot시, Hoa Phu, BW SCC Office Building 4층",
    footer_text_ctyTrucThuoc: " ViMASS 계열사",
    footer_text_ctyQRVN: "합자회사",
    footer_text_theDaNang: " 다기능 카드",
    footer_text_diemThanhToan: " 지불 포인트",
    footer_text_ungDung: " 응용 프로그램",
    footer_text_thietBi: " 장치",
    footer_text_phanMem: " 소프트웨어",
    footer_text_dichVu: " 서비스",
    footer_text_khachHang: " 고객",
    footer_text_cty: " 회사",
    footer_text_chuongTrinhTaiTro: " 후원 프로그램",
    footer_text_giaiPhapChuyenDoiSo: " 디지털 변환",
    footer_text_diemGiaoDich: " 거래 포인트",
    body_text_thietbi_tb_giaThamKhao: " 참고 가격",
    footer_text_diemRaVao: " VietQR을 사용하여 판매",
    footer_text_banHangBangVietQR: "使用 VietQR 进行销售",

    item_footer_0: "음성 QR을 통한 수금",
    item_footer_1: "API를 통한 수금 및 결제",
    item_footer_2: "전자지갑, 결제 지점, 인증 지점",
    item_footer_3: "인증, 인증, 휴대폰 없는 결제",
    item_footer_4: "교육, 의료",
    item_footer_5: "교통, 공공 서비스",
    item_footer_6: "서버, 장비, 서비스",
    item_footer_7: "회사",

    body_title_congTy_codong: " 회사, 주주, 허가증",

    body_text_congTy_codong_231027QuyetDinhThanhLapBanAnToanThongTin:
      " - 2023년 12월 12일자 Vimass 정보 시스템에 관한 규정",
    body_text_congTy_codong_23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le:
      " - 2023년 9월 20일 정관 자본금 등록 발표",
    body_text_congTy_codong_NghiQuyetHoiDongQuanTri16032023:
      " - 2023년 6월 13일자 Vimass 이사회 결의",
    body_text_congTy_codong_BienbanhopHDQTVimassngay13062023:
      " - 2023년 7월 06일 Vimass 이사회 회의록",
      body_text_congty_codong_BienbanhopHDQTVimassngay05082004:
      "-2023年8月5日Vimass董事會會議紀要", 
    body_text_congTy_codong_23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi:
      " - 2023/07/03 장비 판매 가격 결정",
    body_text_congTy_codong_23_9_5_Quyet_dinh_phan_cong_nhiem_vu:
      " - 2023년 12월 1일 업무분담 결정",
    body_text_congTy_codong_HoatDongKinhDoanh15032023:
      " - 2023년 11월 12일 첫 7개월간의 사업 활동",
      body_text_congty_codong_HoSoCapDoAnToanHTTTVIMASS: '- 2024년 3월 22일 Vimass 본사 정보 시스템의 안전 수준 프로필',
      
    body_text_congTy_codong_Danhsachcodongvavongopngay04052023:
      " - 2024년 10월 03일자 주주 및 출자자본 목록",
      body_text_congty_codong_ChuCapHoiToanHoc:
 "- 2024년 8월 7일 베트남 수학협회 상금 후원에 관한 이사회 결의",
    body_text_congTy_codong_BienbanhopHDQTVimassngay17042023:
      " - 2023년 4월 17일 Vimass 이사회 회의록",
    body_text_congTy_codong_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      " - 2023년 4월 17일 결제 지점 생성 및 장비 배포에 관한 파트너 결정",
    body_text_congTy_codong_BienbanhopDaihoidongcodongthuongnien2023ngay07042023:
      " - 2023년 4월 7일 정기 주주 대회 회의록",
      body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay25042024:
      " - 2024년 4월 25일 정기 주주 대회 회의록",
      body_text_congty_codong_ThongtuNHNN:
 "- 2024년 7월 17일 발행된 베트남 중앙은행 시행규칙 No. 40/2024/TT-NHNN",
    body_text_congTy_codong_BienbanhopHDQTVimassngay17032023:
      " - 2023년 3월 17일 Vimass 이사회 회의록",
    body_text_congTy_codong_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      " - 2022년 활동 보고서, 2023년 2월 성과 및 2023년 계획 (2022년 3월 14일)",
    body_text_congTy_codong_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      " - 2023년 2월까지의 영업 활동 보고서 (2022년 3월 14일)",
    body_text_congTy_codong_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      " - 2022년 감사 보고서, 베트남 이동통신 서비스 주식회사 (2022년 3월 8일)",
    body_text_congty_codong_Baocaokiemtoan2023CongTyCoPhanDichVuNenDiDongVietNamn:
      "- 2023년 감사 재무제표, ViMASS., JSC(2024년 11월 3일)",
    body_text_congTy_codong_Quyetdinhvenhomcongtacvienngay22022023:
      " - 2023년 2월 22일 파트너 그룹 결정",
    body_text_congTy_codong_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      " - 2023년 2월 2일 Vimass QRVN 대금 수수 및 지급 서비스 계약",
    body_text_congTy_codong_BienbanhopDaihoicodongbatthuongngay20072023:
      " - 2023년 7월 20일 비정기 주주총회 회의록",
    body_text_congTy_codong_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - 2023년 2월 1일 Vimass의 금융 서비스료 결정",
    body_text_congTy_codong_QuyetdinhtrienkhaithunghiemthedanangtaiVietNamngay1712023:
      " - 2023년 1월 17일 베트남에서 다용도 카드 테스트 실행 결정",
    body_text_congTy_codong_Danhsachcodongvavongopngay16012023:
      " - 2023년 1월 16일 주주 명단 및 자본 기여",
    body_text_congTy_codong_QuyetdinhcapthevatemchocanbonhanvienVimassngay1612023:
      " - 2023년 1월 16일 Vimass 직원에게 카드 및 스탬프 발급 결정",
    body_text_congTy_codong_BienbanthoathuansudungtenvalogocuaHoihuunghiVietNamHungaryngay09122022:
      " - 2022년 12월 9일 베트남-헝가리 우정 협회의 이름 및 로고 사용에 대한 합의",
    body_text_congTy_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay29112022:
      " - 2022년 11월 29일 민간 암호 제품의 수출입 허가증",
    body_text_congTy_codong_Dangkykinhdoanhlanthu22ngay11102022:
      " - 2022년 10월 11일 22번째 사업 등록",
    body_text_congTy_codong_Dangkykinhdoanhlanthu25:
      " -  2023.05.10 25차 사업자 등록",
    body_text_congTy_codong_QuyetdinhgiamgiolamCBNV:
      " - 2023년 10월 1일 직원 19명 근로시간 및 소득 축소 결정",
    body_text_congTy_codong_Dangkykinhdoanhlanthu24ngay6723:
      " - 2023.06.07 24차 사업자 등록",
    body_text_congTy_codong_BienbanhopHDQTVimassngay06102022:
      " - 2022년 10월 6일 Vimass 이사회 회의록",
    body_text_congTy_codong_BienbanhopDaihoicodongbatthuongngay31082022:
      " - 2022년 8월 31일 비정기 주주총회 회의록",
    body_text_congTy_codong_BienbanhopHDQTVimassngay02082022:
      " - 2022년 8월 2일 Vimass 이사회 회의록",
    body_text_congTy_codong_HopdonghoptaccungcapdichvuthuvachihoViMASSQRVNngay11072022:
      " - 2022년 7월 11일 ViMASS QRVN과의 서비스 제공 협약 계약",
    body_text_congTy_codong_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - 2022년 7월 1일 직원용 장비 공급 결정",
    body_text_congTy_codong_BienbanhopHDQTngay31052022:
      " - 2022년 5월 31일 이사회 회의록",
    body_text_congTy_codong_DangkykinhdoanhlandaucuachinhanhNgheAnngay2752022:
      " - 2022년 5월 27일 Nghe An 지점 첫 등록",
    body_text_congTy_codong_DangkykinhdoanhlandaucuachinhanhQuangNinhngay20052022:
      " - 2022년 5월 20일 Quang Ninh 지점 첫 등록",
    body_text_congTy_codong_DangkykinhdoanhlandauchinhanhHuengay18052022:
      " - 2022년 5월 18일 Hue 지점 첫 등록",
    body_text_congTy_codong_DangkykinhdoanhlandauchinhanhHaiPhongngay07052022:
      " - 2022년 5월 7일 Hai Phong 지점 첫 등록",
    body_text_congTy_codong_DangkykinhdoanhlandauchinhanhCanThongay29042022:
      " - 2022년 4월 29일 Can Tho 지점 첫 등록",
    body_text_congTy_codong_BienbanhopHDQTngay28042022:
      " - 2022년 4월 28일 이사회 회의록",
    body_text_congTy_codong_BaocaohoatdongquyIvakehoach2022ngay26042022:
      " - 2022년 1분기 활동 보고서 및 2022년 계획, 2022년 4월 26일",
    body_text_congTy_codong_DangkykinhdoanhlandauchinhanhDaNangngay22032022:
      " - 2022년 3월 22일 Da Nang 지점 첫 등록",
    body_text_congTy_codong_Dangkykinhdoanhlanthu21ngay11032022:
      " - 2022년 3월 11일 21차 등록 (pdf)",
    body_text_congTy_codong_BienbanhopDaihoicodongthuongnien2022ngay22022022:
      " - 2022년 2월 22일 정기 주주 대회 회의록",
    body_text_congTy_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022:
      " - 2022년 2월 21일 정기 주주 대회에서 이사회 회장의 보고서",
    body_text_congTy_codong_BaocaokiemtoanVimass2021ngay27012022:
      " - 2021 Vimass 감사 보고서, 2022년 1월 27일",
    body_text_congTy_codong_BienbanhopHDQT2512022:
      " - 2022년 1월 25일 이사회 회의록 (pdf)",
    body_text_congTy_codong_Dangkykinhdoanhlanthu20ngay31122021:
      " - 20번째 사업 등록, 2021년 12월 31일 (pdf)",
    body_text_congTy_codong_BienbanhopHDQTVimassngay30112021:
      " - Vimass 이사회 회의록, 2021년 11월 30일 (pdf)",
    body_text_congTy_codong_BienbanhopHDQTVimassngay28102021:
      " - Vimass 이사회 회의록, 2021년 10월 28일 (pdf)",
    body_text_congTy_codong_Bangcandoiketoanvabaocaoketquakinhdoanh9thangdaunam202122102021:
      " - 2021년 9월까지의 회계 장부 및 영업 결과 보고서 2021년 10월 22일(pdf)",
    body_text_congTy_codong_Giayphepkinhdoanhsanphamdichvumatmadansu4102021:
      " - 민간 암호 제품 및 서비스 사업 허가증 2021년 10월 4일(pdf)",
    body_text_congTy_codong_BienbanhopHoidongquantriVimassngay2262021:
      " - 2021년 6월 22일 Vimass 경영자 회의록(pdf)",
    body_text_congTy_codong_BienbanhopDaihoidongcodongthuongnien2021ngay2742021:
      " - 2021년 정기 주주총회 회의록 2021년 4월 27일(pdf)",
    body_text_congTy_codong_Dangkykinhdoanhlanthu19ngay23032021:
      " - 19번째 사업 등록 2021년 3월 23일(pdf)",
    body_text_congTy_codong_Dangkykinhdoanhlanthu18ngay15102020:
      " - 18번째 사업 등록 2020년 10월 15일(pdf)",
    body_text_congTy_codong_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      " - 공공 CKS 인증 서비스 제공을 위한 직원 배정 2019년 4월 12일",
    body_text_congTy_codong_BienbanvanghiquyethopDHDCDthuongnien2019ngay442019:
      " - 2019년 정기 주주총회 회의록 및 결정 사항 2019년 4월 4일",
    body_text_congTy_codong_NghiquyetHoidongquantriVimassngay242019:
      " - Vimass 경영자 회의 결정 사항 2019년 4월 2일",
    body_text_congTy_codong_Giaymoihopdaihoicodongthuongnien2019ngay2132019:
      " - 2019년 정기 주주총회 초대장 2019년 3월 21일",
    body_text_congTy_codong_Danhsachemailcuacodongngay2132019:
      " - 주주 이메일 목록 2019년 3월 21일",
    body_text_congTy_codong_NghiquyetHDQTthongquaviecxincapgiayphepchungthucCKSngay2522019:
      " - CKS 인증 허가증 발급 요청에 대한 이사회 결정 2019년 2월 25일",
    body_text_congTy_codong_NghiquyetHoidongquantriVimassveviecvayvonngay3012019:
      " - Vimass 경영자 회의 대출 관련 결정 2019년 1월 30일",
    body_text_congTy_codong_GiayphepnhapkhauthietbiMMDSngay2812019:
      " - MMDS 장비 수입 허가증 발급일 2019년 1월 28일",
    body_text_congTy_codong_DKKDlan6cuachinhanhHCMngay2412019:
      " - 호치민 지점 6차 사업 등록 2019년 1월 24일",
    body_text_congTy_codong_BienbanhopHDQTlan1ngay2312019:
      " - 이사회 회의록 제1차 2019년 1월 23일",
    body_text_congTy_codong_NghiquyetHDQTso1201dQDHDQTHuydongvongop10ngay18122018:
      " - 이사회 결의 제1201호 이사회 결정 자본 조달 10% 2018년 12월 18일",
    body_text_congTy_codong_MauhopdongchuyennhuongcophanVimass:
      " - Vimass 주식 양도 계약서 양식 (doc)",
    body_text_congTy_codong_MaubienbanthanhlyHDCNPVGVimass:
      " - Vimass 종료 회의록 양식 (doc)",
    body_text_congTy_codong_DieulecongtycophandichvunendidongVietNam:
      " - 베트남 모바일 서비스 주식 회사 정관 (doc)",
    body_text_congTy_codong_Dangkykinhdoanhlan17ngay492018:
      " - 제17차 사업 등록 2018년 9월 4일",
    body_text_congTy_codong_Dangkykinhdoanhlan16ngay1362018:
      " - 제16차 사업 등록 2018년 6월 13일",
    body_text_congTy_codong_BienbanhopHoidongthanhvienthuongnienVimassngay952018:
      " - Vimass 정기 회원 이사회 회의록 2018년 5월 9일",
    body_text_congTy_codong_DangkykinhdoanhVimasslan15ngay442018:
      " - Vimass 제15차 사업 등록 2018년 4월 4일",
    body_text_congTy_codong_Giayphep41GPNHNNcuaNganhangnhanuoc1232018:
      " - 국립 은행의 허가증 41/GPNHNN 2018년 3월 12일",
    body_text_congTy_codong_DangkykinhdoanhVimasslan14ngay412018:
      " - Vimass 제14차 사업 등록 2018년 1월 4일",
    body_text_congTy_codong_Dangkykinhdoanhlan6ngay19122013:
      " - 제6차 사업 등록 2013년 12월 19일",
    body_text_congTy_codong_Dangkykinhdoanhlandaungay3152012:
      " - 최초 사업 등록 2012년 5월 31일",
    // #body_text_congty ban dieu hanh",
    body_text_congTy_bdh_BANDIEUHANH: " 이사회",
    body_text_congTy_bdh_ChutichHDQT: " 이사회 의장",
    body_text_congTy_bdh_Tonggiamdoc: " 총괄 이사",
    body_text_congTy_bdh_Photonggiamdocthuongtruc: " 상근 부 총괄 이사",
    body_text_congTy_bdh_Photonggiamdocphutrachtaichinh:
      " 재무 담당 부 총괄 이사",
    body_text_congTy_bdh_Photonggiamdocphutrachcacchinhanh:
      " 지점 담당 부 총괄 이사",
    body_text_congTy_bdh_Photonggiamdocphutrachthietbivathe:
      " 장비 및 카드 담당 부 총괄 이사",
    body_text_congTy_bdh_Truongphongmaychu: " 서버실 팀장",
    body_text_congTy_bdh_PhongWebDesktopMiniVUHF:
      " 웹, 데스크탑, 미니, VUHF 부서",
    body_text_congTy_bdh_TruongphonglaptrinhiPhone: " iPhone 프로그래밍 팀장",
    body_text_congTy_bdh_TruongphonglaptrinhAndroidVPhonevaVTablet:
      " Android, VPhone 및 VTablet 프로그래밍 팀장",
    body_text_congTy_bdh_Chanhvanphong: " 사무국장",
    body_text_congTy_bdh_TruongphongMaketing: " 마케팅 팀장",
    body_text_congTy_bdh_Danhsachcodongvavongopngay04052023:
      " - 2023년 5월 4일의 주주 및 기부금 목록",
    body_text_congTy_bdh_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      " - 2023년 2월 2일 Vimass QR VN에 대한 수납 및 지출 서비스 계약",
    body_text_congTy_bdh_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - 2023년 2월 1일 Vimass 금융 서비스 수수료 결정",
    body_text_congTy_bdh_QuyetdinhbonhiemPhotonggiamdocngay31012023:
      " - 2023년 1월 31일 부 총괄 이사 임명 결정",
    body_text_congTy_bdh_QuyetdinhbonhiemTruongphongThietbingay31012023:
      " - 2023년 1월 31일 장비 팀장 임명 결정",
    body_text_congTy_bdh_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - 2022년 7월 1일 CBNV에 대한 장비 공급 결정",
    body_text_congTy_bdh_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      " - 2022년 6월 30일 CBNV의 업무 미이행에 대한 처분 결정",
    body_text_congTy_bdh_Quydinhvelichhopngay28062022:
      " - 2022년 6월 28일 회의 일정 규정",
    body_text_congTy_bdh_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      " - TGĐ가 2022년 6월 23일에 본사 및 지사에서의 ViMASS 직원 채용에 관한 결정",
    body_text_congTy_bdh_QDcuaTGDvecackhoanchitaiCNngay03062022:
      " - TGĐ의 2022년 6월 3일 지점 내 지출에 관한 결정",
    body_text_congTy_bdh_QuydinhcuaTGDthaydoimucthunhapnhanvien02042022:
      " - TGĐ의 2022년 4월 2일 직원 급여수준 변경에 관한 규정",
    body_text_congTy_bdh_QuydinhveviectaosualuuVimassQR1822022:
      " - Vimass QR의 생성, 수정, 저장에 관한 규정 2022년 2월 18일",
    body_text_congTy_bdh_Quyetdinhvephidichvudiemthuongvadiemmay1122022:
      " - 2022년 2월 11일에 대한 서비스 수수료, 보상점수 및 운 좋은 점수에 관한 결정",
    body_text_congTy_bdh_QuyetdinhxuphatCBNVkhonghoanthanhnhiemvutoithieu2612022:
      " - 2022년 1월 26일에 대한 최소임무를 이행하지 않은 CBNV에 대한 처분 결정",
    body_text_congTy_bdh_BienbanhopHDQTVimassngay30112021pdf:
      " - 2021년 11월 30일 Vimass의 HĐQT 회의록 (pdf)",
    body_text_congTy_bdh_QuyetdinhcuaTongGiamDocveviectuanthutheoThongtuso232019TTNHNN:
      "- 총괄이사의 2019/TTNHNN 번호에 따른 준수 사항에 관한 결정",
    body_text_congTy_bdh_GopychoduthaoThongtusuadoiThongtu392014TTNHNNngay252019:
      "- 2019년 5월 2일에 제안된 2014/TT-NHNN 번호에 대한 수정안에 대한 의견 제시",
    body_text_congTy_bdh_Phancongcanbohotrokhachhangngay2542019:
      " - 2019년 4월 25일 고객 지원을 위한 직원 배정",
    body_text_congTy_bdh_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      " - 2019년 4월 12일 CKS 공공 인증 서비스 제공을 위한 직원 배정",
    // #body_text_congty hoi dong quan tri",
    body_text_congTy_hdqt_HOTEN: " 이름",
    body_text_congTy_hdqt_CHUCVU: " 직위",
    body_text_congTy_hdqt_ChutichHDQTkiemTonggiamdoccongtycophandichvunendidongVietNam:
      " 디지털 서비스 주식회사 비마스의 이사회 의장 겸 사장 -",
    body_text_congTy_hdqt_PhochutichHDQT: " 이사회 부의장",
    body_text_congTy_hdqt_ThanhvienHDQT: " 이사회 멤버",
    body_text_congTy_hdqt_HOIDONGQUANTRI: " 이사회",
    body_text_congTy_hdqt_truongBanKiemSoat: " 감사위원장",
    body_text_congTy_hdqt_thanhVienBanKiemSoat: " 감사위원 회원",
    // #body_text_congty chi nhanh",
    body_text_congTy_cn_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      " - 2023년 4월 17일 지점에서 결제 및 장비 분배를 위한 협력자에 관한 결정",
    body_text_congTy_cn_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      " - 2022년 사업활동 보고서, 2023년 2개월 결과 및 2023년 계획 보고서 2022년 3월 14일",
    body_text_congTy_cn_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      " - 2023년 상반기 사업활동 2개월 보고서 2022년 3월 14일",
    body_text_congTy_cn_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      " - 2022년 회계감사 보고서, 베트남 모바일 서비스 주식회사 2022년 3월 8일",
    body_text_congTy_cn_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - 2023년 2월 1일 Vimass 재무 서비스 수수료 결정",
    body_text_congTy_cn_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - 2022년 7월 1일 CBNV에게 장비 제공에 관한 결정",
    body_text_congTy_cn_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      " - 2022년 6월 30일 최소임무 미이행 CBNV에 대한 처분 결정",
    body_text_congTy_cn_Quydinhvelichhopngay28062022:
      " - 2022년 6월 28일 회의 일정에 관한 규정",
    body_text_congTy_cn_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      " - 2022년 6월 23일 비마스의 본사 및 지점에서 CBNV ViMASS의 채용에 관한 결정",
    body_text_congTy_cn_QDcuaTGDvecackhoanchitaiCNngay03062022:
      " - 2022년 6월 3일 지점에서의 지출에 관한 TGĐ의 결정",
    body_text_congTy_cn_KehoachphattrienChinhanh20222024:
      " - 2022년부터 2024년까지의 지점 개발 계획",
    body_text_dichvu_pdv_TT: "  주문하다",
    body_text_dichvu_pdv_PHIDICHVUTAICHINH: " 금융 서비스 요금",
    body_text_dichvu_pdv_PHITHUDOIVOIBENNHANTHANHTOAN:
      " 수취인에 대한 서비스 수수료",
    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQR:
      " Vimass 지갑, 다기능 카드를 사용하여 컬렉션 지갑에 지불하거나 VNPAY QR 스캔",
    body_text_dichvu_pdv_Ruttoanbotientuvichuyenthuvetaikhoannganhangluc6h8h10h12h14h16h18hsotientoithieu:
      " 오전 6시, 오전 8시, 오전 10시, 오후 12시, 오후 2시, 오후 4시, 오후 6시에 수금 지갑에서 은행 계좌로 모든 금액 인출: (% 금액, 최소 2센트)",
    body_text_dichvu_pdv_DungungdungnganhangquetVietQRthanhtoanngaydentaikhoannganhangsotientoithieu:
      " 은행 애플리케이션을 사용하여 VietQR을 스캔하고 은행 계좌로 즉시 지불(% 금액, 최소 3센트))",
    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang:
      " Vimass Wallet, 다기능 카드를 사용하여 은행 계좌로 즉시 결제",
    body_text_dichvu_pdv_PHITHUDOIVOIBENTHANHTOAN:
      " 지불인에 대한 서비스 수수료",
    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQRsotientoithieu:
      " Vimass Wallet, 다기능 카드를 사용하여 결제하고 수금 지갑으로 이체하거나 VNPAY QR 스캔(% 금액, 최소 250 vnd)",
    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang2:
      " Vimass Wallet, 다기능 카드를 사용하여 은행 계좌로 즉시 결제(% 금액, 최소 3센트)",
    body_text_dichvu_pdv_YEUCAUCHUYENTIENCHUYENTIEN: " 송금 요청 또는 송금",
    body_text_dichvu_pdv_Chuyentientuvidenvisotientoithieu200dongBaogomvicanhanvidoanhnghiepthedanangvichuyenthucuacuahang:
      " 월렛 간 이체(% 금액, 최소 250 VND. 개인 지갑, 비즈니스 지갑, 다기능 카드, 컬렉션 지갑 포함)",
    body_text_dichvu_pdv_Chuyentiennhanhdentaikhoannganhanghoacsothemoigiaodichtoida300trieudong:
      " 은행 계좌 또는 카드 번호로 송금(거래당 최대 3억 VND, % 금액, 최소 3센트)",
    body_text_dichvu_pdv_NaptienvaoviquaQRhoacquataikhoansotientoithieu:
      " QR 또는 계정을 통해 지갑 충전(% 금액, 최소 3센트)",
    body_text_dichvu_pdv_Ruttientuvivetaikhoannganhangmoigiaodichtoida300trieudong:
      " 지갑에서 은행 계좌로 돈을 인출(거래당 최대 3억 VND, % 금액, 최소 3센트)",
    body_text_dichvu_pdv_THUHOtheohopdong: " COLLECTION, HOUSEHOLD (계약중)",
    body_text_dichvu_pdv_ChihoVNPOST: " a) VNPOST에서 수금, 거래당 1000 VND",
    body_text_dichvu_pdv_KhidungungdungnganhangquetQRmattruocthesotientoithieu200dong:
      " b) 은행 애플리케이션 사용 시 카드 앞면의 QR 코드 스캔: 금액의 %, 최소 250 VND",
    body_text_dichvu_pdv_KhidungungdungQRVietnamquetQRmattruoctheThuhoquaThedanangsotientoithieu200dong:
      " c) QR 베트남 애플리케이션 사용 시 카드 앞면의 QR 코드를 스캔, 다기능 카드를 통해 수집: 금액의 %, 최소 250 VND",
    body_text_dichvu_pdv_CHIHOtheohopdong: " AUTHOZIED ExpENSE (계약에 의해)",
    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichduoi1trieudong:
      " d) 수신자는 Vimass 지갑, 거래 금액은 100만 VND 미만입니다.",
    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtu1trieuden20trieudong:
      " e) 수신자는 Vimass 지갑, 거래 금액은 1백만에서 2천만 VND입니다.",
    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtren20trieudong:
      " f) 수신자는 Vimass 지갑, 거래 금액은 2천만 VND 이상입니다.",
    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangduoi100000:
      " g) 받는 사람이 계정이며 월 거래 수가 100.000 미만입니다.",
    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu100000den200000:
      " h) 받는 사람은 계정이며, 월 거래 수는 100.000에서 200.000입니다.",
    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu200000den500000:
      " i) 수신자는 계정이며, 월간 거래 수는 200.000에서 500.000입니다.",
    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu500000den1000000:
      " j) 수신자는 계정이며, 월 거래 수는 500.000에서 1.000.000입니다.",
    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtren1000000:
      " k) 받는 사람이 계정이며, 월 거래 건수가 1.000.000 이상입니다.",
    body_text_dichvu_pdv_CONGTHANHTOANTHE: " 카드 결제 게이트",
    body_text_dichvu_pdv_Dungthenoidiathanhtoanchodonvihoatdongtronglinhvucytegiaoducgiaothongcongcongdichvucongthuekinhdoanhxangdau:
      " a) 의료, 교육, 대중 교통, 공공 서비스, 세금, 석유 사업 분야에서 운영되는 단위에 대해 국내 카드를 사용하여 지불",
    body_text_dichvu_pdv_DungthenoidiacuanganhangcoketnoithanhtoanthevoiVimass:
      " b) Vimass와 카드 결제 연결이 있는 은행의 국내 카드 사용",
    body_text_dichvu_pdv_Dungthenoidiacuanganhangchuaketnoi:
      " c) 비연계 은행의 국내 카드 사용",
    body_text_dichvu_pdv_DungtheVISAMasterCardJCBAMEX:
      " d) 카드 사용 VISA, MasterCard, JCB, AMEX",
    body_text_dichvu_pdv_TIETKIEMTRUCTUYEN: " 온라인 저장",
    body_text_dichvu_pdv_HuyDong: " 동원(국립은행 규정에 따름)",
    body_text_dichvu_pdv_Chovay: " 대출(국립은행 규정에 따름)",
    body_text_dichvu_pdv_Dangcapnhat:
      " 온라인 적금모금 및 결제 서비스가 업데이트 됩니다.",
    body_text_dichvu_pdv_Thoathuan: " 협상 가능",
    body_text_dichvu_pdv_MUCPHI: " 수수료",
    // #Phi chu ky so",
    body_text_dichvu_cks_STT: " 시퀀스 번호",
    body_text_dichvu_cks_phiVAT: " 수수료(부가가치세 포함)",
    body_text_dichvu_cks_diemThuong: " 승점",
    body_text_dichvu_cks_diemMay: " 바느질 포인트 LPoint",
    body_text_dichvu_cks_menhGia: "vnd",
    body_text_dichvu_cks_DICHVUCHUKYSOsudungtheSIMkysoVimass:
      "  DIGITAL SIGNATURE SERVICE (Vimass 디지털 서명 카드/SIM 사용)",
    body_text_dichvu_cks_CHUKYSOTHEONAM: "  연도별 디지털 서명",
    body_text_dichvu_cks_Giathuebaochukysocanhan1nam:
      "  1년간 개인전자서명 등록 가격",
    body_text_dichvu_cks_Giathuebaochukysocanhan2nam:
      "  2년간 개인전자서명 등록 가격",
    body_text_dichvu_cks_Giathuebaochukysocanhan3nam:
      "  3년간 개인전자서명 등록 가격",
    body_text_dichvu_cks_Giathuebaochukysotochuc1nam: "  전자서명 1년 구독료",
    body_text_dichvu_cks_Giathuebaochukysotochuc2nam: "  전자서명 2년 구독료",
    body_text_dichvu_cks_Giathuebaochukysotochuc3nam: "  전자서명 3년 구독료",
    body_text_dichvu_cks_Giahanchukysodanhchotochuc1nam:
      "  조직의 디지털 서명 1년 갱신",
    body_text_dichvu_cks_Giahanchukysodanhchotochuc2nam:
      "  2년간 조직의 디지털 서명 갱신",
    body_text_dichvu_cks_Giahanchukysodanhchotochuc3nam:
      "  3년간 조직의 디지털 서명 갱신",
    body_text_dichvu_cks_CHUKYSOTUNGLAN: "  매번 디지털 서명",
    body_text_dichvu_cks_Gia1lankyneuduoi1000lannamkhongduoi8lan:
      "  연간 1,000회 미만인 경우 1개당 가격(8회 이상)",
    body_text_dichvu_cks_Gia1lankyneutu1000den10000lannam:
      "  1.000~10.000회/년의 경우 1 기호의 가격",
    body_text_dichvu_cks_Gia1lankyneutu10000den100000lannam:
      "  10.000~100.000회/년의 경우 1 기호의 가격",
    body_text_dichvu_cks_Gia1lankyneutu100000den1000000lannam:
      "  100.000~1.000.000회/년의 경우 1개의 기호 가격",
    body_text_dichvu_cks_Gia1lankyneutren1000000lannam:
      "  연간 1,000,000회 이상인 경우 1개당 가격",
    body_text_dichvu_cks_CHUKYSOTUXA: "  원격에서 디지털 서명",
    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrong24gio:
      "  원격 서명 1회 가격, 24시간 동안 10회 이하",
    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrongthang:
      "  한 달에 10번 이하 원격 서명 1회 가격",
    body_text_dichvu_cks_Gia10lankytuxathang: "  가격 원격 로그인 10회/월",
    body_text_dichvu_cks_Gia20lankytuxathang: "  원격 서명 20회/월 가격",
    body_text_dichvu_cks_Gia50lankytuxathang: "  가격 원격 서명 50회/월",
    body_text_dichvu_cks_Gia100lankytuxathang: "  원격 서명 100회/월 가격",
    body_text_dichvu_cks_Giakytuxakhongqua200lannam:
      "  원격 서명 비용은 연간 200회 이하입니다.",
    body_text_dichvu_cks_DICHVUCHUKYSORemoteSigning:
      "  디지털 서명 서비스(원격 서명)",
    body_text_dichvu_cks_Chukyso24hKhoitaovakychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong24hketukhicap:
      "  24시간 디지털 서명 - 초기화 및 서명(디지털 거래를 수행하는 개인에 한함. 발급일로부터 24시간 이내에 초기화 및 서명 10회 이내)",
    body_text_dichvu_cks_Chukysothoihan1thangvoi10luotkychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong30ngayketukhicap:
      "  10개의 서명으로 1개월 동안 유효한 디지털 서명(디지털 거래를 수행하는 개인의 경우. 발급일로부터 30일 이내에 10회 이하로 초기화 및 서명)",
    body_text_dichvu_cks_Goibosung10luotkythang: "  추가 패키지 10 계약/월",
    body_text_dichvu_cks_Goibosung20luotkythang: "  월 20회 등록의 추가 패키지",
    body_text_dichvu_cks_Goibosung50luotkythang: "  추가 패키지 50 기호/월",
    body_text_dichvu_cks_Goibosung100luotkythang: "  월 100건의 추가 등록 팩",
    body_text_dichvu_cks_Chukyso1namkhongqua200luotnam:
      "  서명번호 1년(연간 200회 이내)",
    body_HopDong_text_thongbaogopvon:
      " - 2023년 8월 자본 기부 안내 및 2023년 9월 기부 권한",
    // #LinkAnhMain",
    main_images_chuongTrinhTaiTro: "img/8cuc/cuc2/thidiemtaitroKR.jpg",
    main_images_qrAMtHANH: "img/8cuc/cuc1/QRamthanh-k.jpg",
    main_images_theDaNang:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=theDaNang-K.jpg",
    main_images_diemravao:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=diemdinhdanh-K.jpg",
    main_images_diemThanhToan:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=diemThanhToan-K.jpg",
    main_images_home_ungDung: "img/8cuc/cuc1/giai-phap-cds-kr.jpg",
    main_images_home_giaoduc:
    "img/8cuc/cuc1/VPosK.jpg",
    main_images_home_yte: "img/8cuc/cuc1/Thuchiho-k.jpg",
    main_images_home_giaothong: "img/8cuc/cuc1/YteDichvucongGiaothong-k.jpg",
      main_images_home_GD_YTE: "img/8cuc/cuc1/Giaoduc-k.jpg",
    main_images_home_diemgiaodich:
      "img/8cuc/cuc1/diemthanhtoan-k.jpg",   
    main_images_thietBi:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695634077367mK9QF",
    main_images_phanMem:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695634096707IuWwF",

    main_images_dichVu: "img/8cuc/cuc1/thietbivadichvu-kr.jpg",
    main_images_khachHang:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=khachHang-K.jpg",
    main_images_congTy:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=16956337150206WOWF",
    // #LinkAnhBanner",
    main_images_bannerWeb1:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb1-K.jpg",
    main_images_bannerWeb2:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb2-K.jpg",
    main_images_bannerMobile1:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile1-K.jpg",
    main_images_bannerMobile2:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile2-K.jpg",
    // #HopDong",
    body_HopDong_text_title: " 계약",
    body_HopDong_text_hopDongDaKy: " 서명된 계약",
    body_HopDong_text_ThoathuanhoptacCMCVIMASS: " - CMC - VIMASS 협력 계약",
    body_HopDong_text_ThoathuanhoptacVimassPaytech:
      " - PAYTECH - VIMASS 서비스 협력 계약",
    body_HopDong_text_hopdonghoptackinhdoanhdichvuxacthucbangkhuonmatVIMASSvPSPACES:
      " - VIMASS - VPSPACES 얼굴인식 서비스 사업 협력 계약",
    body_HopDong_text_HoptacvoiVAIS:
      " - VIMASS - VAIS 음성인증 서비스 사업 협력 계약",
    body_HopDong_text_hopdonghoptacVimassNanosoft:
      " - VIMASS - NANOSOFT 협력 계약",
    body_HopDong_text_hopdongcungcapdichvuVimassTiem:
      " - VIMASS - TIEM 서비스 공급 계약",
    body_HopDong_text_thoathaphoptacthanhtoankhongdungtienmatvimassvafpt:
      " - VIMASS - FPT 교육 기업의 비현금 결제 및 금융 포용을 위한 협력 계약",
    body_HopDong_text_thoathuanhoptackhongdungtienmattaibenhvientpbvavimass:
      " - VIMASS - TPBANK 비현금 결제의 병원에 대한 배치 협력 계약",
    body_HopDong_text_hopdongbidvvimass: " - VIMASS - BIDV 자회사 협력 계약",
    body_HopDong_text_HopdongdichvuthuhoVpbankVimass:
      " - VIMASS - VPB 계약 징수 서비스 협력 계약",
    body_HopDong_text_DichVuChihovoiVPBankVimass:
      " - VIMASS - VPB 자회사 지급 서비스 협력 계약",
    body_HopDong_text_HopdongVimassIntergate: " - VIMASS - INTERGATE 협력 계약",
    body_HopDong_text_hopdongVimassNapas: " - VIMASS - NAPAS 협력 계약",
    body_HopDong_text_hoptacvimassvavnca: " - VIMASS - VINA CA 협력 계약",
    body_text_congTy_Hoatdongkinhdoanh: "- 2023년 경영활동",
    // #thietbi",
    body_text_thietbi_tb_baohanhhainam: " 2년 보증",
    body_text_thietbi_tb_baohanhmotnam: " 1년 보증",
    body_text_thietbi_tb_LinkNXP:
      " https://web.vimass.vn/vmbank/services/media/viewFile?id=NXP_Smart_Governance_Brochure.pdf",
    body_text_thietbi_tb_Ten: " 이름",
    body_text_thietbi_tb_Thietbibaohanh2nam: " 장치",
    body_text_thietbi_tb_Ungdung: " 응용",
    body_text_thietbi_tb_Tinhnangkythuat: " 기술적 특징",
    body_text_thietbi_tb_The2chip: " 2칩 카드",
    body_text_thietbi_tb_Gia100000160000dongthe:
      " 가격: 80,000 - 160,000 동/카드",
    body_text_thietbi_tb_Tuytheodaily: " (대리점에 따라 다름)",
    body_text_thietbi_tb_Tangkembaodungthe: " 카드 보관용 포켓 포함",
    body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh:
      " - NXP - 스마트 월드를 위한 안전한 연결",
    body_text_thietbi_tb_NXPDinhhinhchochinhphudientu: " NXP - 전자 정부 형성",
    body_text_thietbi_tb_UngdungcuachipNXPUCODE: " - NXP UCODE 칩의 응용",
    body_text_thietbi_tb_Chitiettunhasanxuat: " - NXP 반도체 회사",
    body_text_thietbi_tb_ChitiettunhasanxuatAdvanced:
      "  - Advanced Card Systems Ltd",
    body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile:
      " - MIFARE DESFire EV3 응용 키 및 파일",
    body_text_thietbi_tb_MIFAREDESFireEV3ICBlockdiagram:
      " - MIFARE DESFire EV3 IC 블록 다이어그램",
    body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB:
      " - Mifare DESFire EV3 8KB 칩에 대한 기술 문서",
    body_text_thietbi_tb_TailieukythuatveUCODE9: " - UCODE 9 기술 문서",
    body_text_thietbi_tb_TinhNangBaoMatEV3: " - EV3의 보안 기능",
    body_text_thietbi_tb_The3chipkyso: " 3칩 전자 서명 카드",
    body_text_thietbi_tb_Gia150000210000dongthe:
      " 가격: 130,000 - 210,000 동/카드",
    body_text_thietbi_tb_UngdungcuachipkysoACOS564: " - ACOS5-64",
    body_text_thietbi_tb_TinhnangkythuatveUCODE9: " - UCODE 9의 기술적 특징",
    body_text_thietbi_tb_TemUCODERFID: " UCODE RFID 스티커",
    body_text_thietbi_tb_GiagomVAT1200000dong: " 가격(VAT 포함): 1.500.000 VND",
    body_text_thietbi_tb_GiaThamKhao3000000dong:
      "       후원 판매 가격(VAT 제외): 1,200,000 VND",
    body_text_thietbi_tb_GiagomVAT30000den60000dong:
      " 가격 (VAT 포함): 20,000 - 60,000 동",
    body_text_thietbi_tb_Bankemthedanang1themuatoida2nhan:
      " 다기능 카드와 함께 제공 (최대 2개의 라벨 지원)",
    body_text_thietbi_tb_SudungdanotoxemmaycothethaythetheVETCEpassbihong:
      " 차량용 부착, 기기 사용 (VETC, Epass 등이 손상된 경우 대체 가능)",
    body_text_thietbi_tb_DungungdungQRVietnamdeghimaEPC:
      "  앱을 사용하여 EPC 코드 기록:",
    body_text_thietbi_tb_KhongtrungvoimaEPCdacocuaVimass:
      " - Vimass의 기존 EPC 코드와 중복되지 않음",
    body_text_thietbi_tb_PhaitrungvoimakhachdangsudungcuadonvikhacVETCEpass:
      " - 다른 기관이 사용 중인 고객의 코드와 일치해야 함 (VETC, Epass 등)",
    body_text_thietbi_tb_Kichthuocanten70x14mm: " - 안테나 크기: 70x14mm",
    body_text_thietbi_tb_ChatlieuGiaytrang: " - 재질: 백지",
    body_text_thietbi_tb_ThietbiphatUHFculy7mxuly1theUHFva20theUHF:
      " UHF 송신 장치, 7m 범위에서 UHF 카드 1개와 20개 처리",
    body_text_thietbi_tb_GiagomVAT2500000dong: " 가격 (VAT 포함): 2,500,000 동",
    body_text_thietbi_tb_thongsokythuat: " - 기술 사양",
    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy7mxuly1the:
      " - UHF 수신 송신 장치 기술 사양, 7m 범위에서 UHF 카드 1개 처리",
    body_text_thietbi_tb_ThongsokythuatthietthubiphatUHFculy7mxuly200the:
      " - UHF 송신 수신 장치 기술 사양, 7m 범위에서 UHF 카드 200개 처리",
    body_text_thietbi_tb_ThietbiphatUHFculy20mxuly1theUHFva200theUHF:
      " UHF 송신 장치, 20m 범위에서 UHF 카드 1개와 200개 처리",
    body_text_thietbi_tb_GiagomVAT4400000dong: " 가격(VAT 포함): 4.400.000 VND",
    body_text_thietbi_tb_thongsokythuatthietbithuphatUHF: " - UHF 송신기 사양",
    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly1the:
      " - 20m 범위의 UHF 송수신기 사양, 카드 1개 처리",
    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly200the:
      " - 200개의 카드를 처리하는 20m 범위의 UHF 송수신기 사양",
    body_text_thietbi_tb_VPhone22: " VPhone 22",
    body_text_thietbi_tb_GiagomVAT3000000dong: " 가격 (VAT 포함): 3,000,000 동",
    body_text_thietbi_tb_VPhone22GSMAssociation: " - VPhone 22 GSM 협회",
    body_text_thietbi_tb_thongsokythuatVPhone22: " - VPhone22 기술 사양",
    body_text_thietbi_tb_MT6762DCPU: " - MT6762D CPU",
    body_text_thietbi_tb_TechnicaldiagramMT6762: " - MT6762의 기술 다이어그램",
    body_text_thietbi_tb_CompareCPUMT6762DwithSC9863:
      " - MT6762D CPU와 SC9863 비교",
    body_text_thietbi_tb_UserManual: " - 사용자 매뉴얼",
    body_text_thietbi_tb_MotsodienthoaicoNFC: " 일부 NFC가 장착된 스마트폰:",
    body_text_thietbi_tb_SamsungGalaxyM22: " - Samsung Galaxy M22",
    body_text_thietbi_tb_SamsungGalaxyA32: " - Samsung Galaxy A32",
    body_text_thietbi_tb_DienthoaiSamsungGalaxyNFC:
      " - Samsung Galaxy NFC 스마트폰:",
    body_text_thietbi_tb_SamsungGalaxyS6trolen: " + Samsung Galaxy S6 이상",
    body_text_thietbi_tb_IPhone7trolen: " + IPhone 7 이상",
    body_text_thietbi_tb_VTablet22: " VTablet 22",
    body_text_thietbi_tb_XiaomiRedmi12: " 샤오미 레드미 12",
    body_text_thietbi_tb_Gia4290000: " 가격: 4,290,000 VND",
    body_text_thietbi_tb_ManhinhIPSLCD679FullHD: " - 화면: IPS LCD6.79풀 HD+",
    body_text_thietbi_tb_CamerasauChinh50MPPhu8MP2MPCameratruoc8MP:
      " - 후면 카메라: 메인 50MP 및 보조 8MP, 2MP; 전면 카메라: 8MP",
    body_text_thietbi_tb_RAM4GB: " - RAM: 4GB",
    body_text_thietbi_tb_Dungluongluutru128GB: " - 저장 용량:128GB",
    body_text_thietbi_tb_SamsungGalaxyA33: " 삼성 갤럭시 A33",
    body_text_thietbi_tb_gia5490000: " 가격: 5,490,000 VND",
    body_text_thietbi_tb_Manhinh64inchSuperAMOLEDFHD:
      " - 화면: 6.4인치, 슈퍼 AMOLED, FHD+,",
    body_text_thietbi_tb_Camerasau480MP80MP50MP20MPCameratruoc130MP:
      " - 후면 카메라: 48.0MP + 8.0MP + 5.0MP + 2.0MP; 전면 카메라: 13.0MP",
    body_text_thietbi_tb_RAM6GB: " - RAM: 6GB",
    body_text_thietbi_tb_iPhoneXcu: " 아이폰X(중고)",
    body_text_thietbi_tb_Gia6490000: " 가격: 6,490,000 VND",
    body_text_thietbi_tb_Manhinh58inch: " - 화면: 5.8인치",
    body_text_thietbi_tb_Camerasau12MPCameratruoc7MP:
      " - 후면 카메라: 12MP ; 전면 카메라: 7MP",
    body_text_thietbi_tb_RAM3GB: " - RAM: 3GB",
    body_text_thietbi_tb_Dungluongluutru64GB: " - 저장 용량: 64GB",
    body_text_thietbi_tb_httpscellphonescomvniphonex64gboldhtml:
      " - https://cellphones.com.vn/iphone-x-64-gb-old.html",
    body_text_thietbi_tb_iPhone11: " 아이폰 11",
    body_text_thietbi_tb_Gia10650000: " 가격: 10,650,000 VND",
    body_text_thietbi_tb_Manhinh61inch: " 화면: 6.1인치",
    body_text_thietbi_tb_Camerasau12MPCameratruoc12MP:
      " 후면 카메라: 12MP ; 전면 카메라: 12MP",
    body_text_thietbi_tb_Maytinhmini7000000: " 미니 계산기",
    body_text_thietbi_tb_Gia7000000: " 가격 (VAT 포함): 9,000,000 VND",
    body_text_thietbi_tb_VPhone23: " VPhone 23",
    body_text_thietbi_tb_dtphankhucre: " (저렴한 세그먼트 전화)",
    body_text_thietbi_tb_CPUCeleronN5105Upto29GHz4Cores4:
      " - CPU: Celeron N5105(최대 2.9GHz) 4 코어 4",
    body_text_thietbi_tb_Manhinh: " - 6.0인치 화면, 해상도 1440x3200",
    body_text_thietbi_tb_Camera: " - 카메라: 16MP 전면, 32MP 후면",
    body_text_thietbi_tb_Dungluong: " - 용량 8GB + 256GB(메모리 카드)",
    body_text_thietbi_tb_linkmuahangthamkhao: " 참조 구매 링크",
    body_text_thietbi_tb_VGAIntelUHDGraphics: " - VGA: 인텔 UHD 그래픽",
    body_text_thietbi_tb_Ram8GB: " - 램: 8GB",
    body_text_thietbi_tb_Ocung2TB: " - 하드 드라이브: 2TB",
    body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc11essentialkitatlascanyonceleronn5105bnuc11atkc40000variantid69490990:
      " - https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-11-essential-kit-atlas-canyon-celeron-nat5105 -bneron-nat=694990990",
    body_text_thietbi_tb_MaytinhminiGia15000000: " 미니 계산기",
    body_text_thietbi_tb_Gia15000000: " 가격: 15,000,000 VND",
    body_text_thietbi_tb_CPUIntelCorei71260PUpto48GHz12nhan16luong:
      " - CPU: Intel Core i7-1260P(최대 4.8GHz) 12코어 16스레드",
    body_text_thietbi_tb_VGAIrisXeGraphics: " - VGA: 아이리스 Xe 그래픽",
    body_text_thietbi_tb_httpsmemoryzonecomvnmaytinhminipcintelnuc12prowallstreetcanyoni71260prnuc12wshi70000variantid85683670:
      " https://memoryzone.com.vn/may-tinh-mini-pc-intel-nuc-12-pro-wall-street-canyon-i7-1260p-rnuc1 2w=70000?85683670",
    body_text_thietbi_tb_GiagomVAT3200000dong: " 가격 (VAT 포함): 3,200,000 동",
    body_text_thietbi_tb_ThongsokythuatVTablet22: " - VTablet 22의 기술 사양",
    body_text_thietbi_tb_ManHinh101inch19201200HD:
      " - 10.1인치 1920*1200 HD 화면,",
    body_text_thietbi_tb_Front5MPBack8MP: " - 전면 5MP + 후면 8MP 카메라",
    body_text_thietbi_tb_Dungluongluutru32GB: " - 저장 용량: 32GB",
    body_text_thietbi_tb_VReader: " VReader",
    body_text_thietbi_tb_GiagomVAT1850000dong: " 가격 (VAT 포함): 1,000,000 동",
    body_text_thietbi_tb_NXPPN7642AU: " - NXP-PN7642AU",
    body_text_thietbi_tb_nfc: " NFC",
    body_text_thietbi_tb_Giataitro80000:
      " 후원 프로그램 가격(VAT 포함): 80,000 VND/카드",
    body_text_thietbi_tb_Giataitro130000:
      " 스폰서 프로그램 가격(VAT 포함): 130,000 VND/카드",
    body_text_thietbi_tb_Giataitro20000:
      " 후원 프로그램 가격(VAT 포함): 20,000 VND/팀",
    body_text_thietbi_tb_NXPPN7462: " , NXP-PN7462",
    body_text_thietbi_tb_NXPAN12752: " , NXP-AN12752",
    body_text_thietbi_tb_NXPAN12057: " , NXP-AN12057",
    body_text_thietbi_tb_NXPAN10834: " , NXP-AN10834",
    body_text_thietbi_tb_ModulmaytinhNFCmahoaAES3DES:
      " : NFC 컴퓨터 모듈, AES 암호화, 3-DES.",
    body_text_thietbi_tb_ESPWROOM32: " - ESP-WROOM-32",
    body_text_thietbi_tb_ModulemaytinhnangluongthapketnoiWifiBluetoothRS485UARTWeigandvv:
      " : 저에너지 컴퓨터 모듈, Wifi, Bluetooth, RS485, UART, Weigand 등",
    body_text_thietbi_tb_FS01: " - FS-1",
    body_text_thietbi_tb_Cambienvantay5mucdobaomatluutrutoida1000mauvantay:
      " : 5단계 보안 수준의 지문 센서, 최대 1000개의 지문 샘플 저장.",
    body_text_thietbi_tb_AngtenNFCwifidieukhiendongmodongcuaBluetoothRS485UARTWeigandnguon220V12V2A:
      " - NFC 안테나, 와이파이, 도어 개폐 제어, 블루투스, RS485, UART, Weigand, 220V-12V, 2A 전원.",
    body_text_thietbi_tb_PhanmemtraodoidulieuvoithevadienthoaiketnoiWifiBluetoothRS485UARTWeiganddichvuchuyentienthanhtoandichvunganhang:
      " - 소프트웨어: 카드 및 전화와 데이터 교환, Wi-Fi 연결, Bluetooth, RS485, UART, Weigand, 송금 서비스, 결제.",
    body_text_thietbi_tb_ThenhomicroHikvisionHSTFC132GBClass1092MBs:
      " Hikvision HS-TF-C1 32GB Class 10 (92MB/s) 마이크로 메모리 카드",
    body_text_thietbi_tb_GiagomVAT139000dong: " 가격 (VAT 포함): 139,000 동",
    body_text_thietbi_tb_Thongtinchitiet: " 상세 정보",
    body_text_thietbi_tb_Maytinhmini: " 미니 컴퓨터",
    body_text_thietbi_tb_GiagomVAT9190000dong: " 가격 (VAT 포함): 9,190,000 동",
    body_text_thietbi_tb_BochiaTypeCra7congUSB30:
      " Type C 분배기, 7개 USB 3.0 포트",
    body_text_thietbi_tb_GiagomVAT329000dong: " 가격 (VAT 포함): 329,000 동",
    body_text_thietbi_tb_OcungSSDSamsung870QVO2TBSATAIII25inch:
      " 삼성 870 QVO 2TB SATA III 2.5인치 SSD 하드 드라이브",
    body_text_thietbi_tb_GiagomVAT4899000dong: " 가격 (VAT 포함): 4,899,000 동",
    body_text_thietbi_tb_RamLaptopKingstonKVR26S19S68KVR26S19S888GB1x8GBDDR42666MHz:
      " 킹스턴 노트북 램 (KVR26S19S6/8 / KVR26S19S8/8) 8GB (1x8GB) DDR4 2666MHz",
    body_text_thietbi_tb_GiagomVAT759000dong: " 가격 (VAT 포함): 759,000 동",
    body_text_thietbi_tb_CapdulieuUSB20noidai: " USB 2.0 연장 케이블",
    body_text_thietbi_tb_Gia5m190000dongGia10m290000dong:
      " 가격 5m: 190,000 동 - 가격 10m: 290,000 동",
    body_text_thietbi_tb_Thongtinchitietcap5m: " 5m 케이블의 상세 정보",
    body_text_thietbi_tb_Thongtinchitietcap10m: " 10m 케이블의 상세 정보",
    body_text_thietbi_tb_ThekysoACSACOS564: " ACS ACOS5-64 전자 서명 카드",
    body_text_thietbi_tb_GiagomVAT50000dong: " 가격 (VAT 포함): 50,000 동",
    body_text_thietbi_tb_Chinhsachbaomat: " - 보안 정책",
    body_text_thietbi_tb_DaunoikhongdayVimassUSBTypeA:
      " Vimass 무선 USB Type-A 어댑터",
    body_text_thietbi_tb_GiagomVAT100000dong: " 가격 (VAT 포함): 100,000 동",
    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe:
      " Vimass 유선 USB Type-A 1m 케이블, 1개의 카드 슬롯",
    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe1khedocSIM:
      " Vimass 유선 USB Type-A 1m 케이블, 1개의 카드 슬롯, 1개의 SIM 슬롯",
    body_text_congTy_cn_23517QuyetdinhvedailyvadongiathietbicuaVimass:
      " - Decision on agent and unit price of Vimass equipment dated 17/05/2023",
    body_text_congTy_hdh_ntt: " Tony Nguyen",
    body_text_congTy_hdh_nmv: " Phillip Nguyen",
    body_text_congTy_hdh_cvc: " Kevin Chu",
    body_text_congTy_hdh_tvt: " Tran Viet Trung",
    body_text_congTy_hdh_ntnh: " Nguyen Thi Ngoc Ha",
    body_text_congTy_hdh_dht: " Dinh Huy Toan",
    body_text_congTy_hdh_ndt: " Nguyen Duy Thinh",
    body_text_congTy_hdh_nvt: " Nguyen Van Tu",
    body_text_congTy_hdh_httt: " Hoang Thi Thien Trang",
    body_text_congTy_hdh_tvtinh: " Trinh Van Tinh",
    body_text_congTy_hdh_tnt: " Tran Nam Trung",
    body_text_congTy_hdh_tth: " Tran Thi Hien",
    body_text_congTy_hdh_lvt: " Le Viet Tan",
    body_text_congTy_hdh_thb: " Tran Hoai Bac",
    body_text_congTy_hdh_tvh: " Tran Viet Hung",
    body_text_thietbi_tb_MaytinCPUI7: " 컴퓨터 CPU i7",
    body_text_thietbi_tb_Gia22374000: " 가격(VAT 포함): 22,374,000 VND",
    body_text_thietbi_tb_MAINBOARD: "- 메인보드 ASUS PRIME B760M-A WIFI D4",
    body_text_thietbi_tb_CPUINTELCOREI7:
      " - CPU 인텔 코어 I7-12700F(최대 4.8GHZ, 12배, 25MB 캐시, 125W) - 소켓 인텔 LGA 1700)",
    body_text_thietbi_tb_TANNHIETKHI: " - DEEPCOOL AK400",
    body_text_thietbi_tb_RAMKINGTON:
      "- RAM 데스크탑 KINGSTON FURY BEAST RGB 32GB(2X16GB) DDR4 3200MHZ",
    body_text_thietbi_tb_SSDKingston:
      "- Kingston NV2 M.2 PCIe Gen4 x4 NVMe SSD 2TB",
    body_text_thietbi_tb_NGUONMAYTINH: "- 쿨러 마스터 컴퓨터 소스",
    body_text_thietbi_tb_VOCASE: " - CASE KENOO ESPORT FM800",
    body_text_thietbi_tb_baohanhbanam: " 3년 보증",
    body_text_ungdung_hethongthongtin: " 정보 시스템",
  },

  ja: {
    //バナー
    banner_web_1: " img/banner_new/Banner1/1-1920x200-JP.jpg",

    banner_web_2: " img/banner_new/Banner1/2-1920x1080-JP.jpg",
    banner_web_3: "img/banner_new/Banner1/3-1920x200-J.jpg",

    banner_phone_1: " img/banner_new/Banner1/1-500x200-JP.jpg",

    banner_phone_2: " img/banner_new/Banner1/2-500x200-JP.jpg",

    Slide_docTiep: "続きを読む",

    header_text_gioiThieu: " 導入",
    header_text_QRAmThanh: "QRサウンド",

    header_text_ThuTienBanHang: "音声 QR でお金を集め、商品を販売",

    header_text_huongDan: "チュートリアル",

    header_text_phiDichVu: " サービス料",

    header_text_taiChinh: " サービス料",

    header_text_lienHe: " 接触",

    header_text_hopDong: " 契約",

    header_text_chuKySo: " デジタル署名",

    header_text_hoaDonDienTu: "電子請求書",

    header_text_hopDongDienTu: "電子契約",
    header_text_linhVuc: "分野",

    header_text_sanPham: " 製品",

    header_text_viDienTu: " Vimass 電子ウォレット",

    header_text_theDaNangDauDoc: "多機能カード＆リーダー",

    header_text_dichVu: " サービス",

    header_text_khachHang: " お客様",

    header_text_qrThanhToan: "QR決済",

    header_text_congThanhToan: "支払いゲートウェイ",

    header_text_dichVuCong: " 公共サービス",
    header_text_nhanTienBangQRamThah:"音声 QR でお金を受け取る",
 header_text_banHangBangQRdong:"動的 QR を使用して販売",
 header_text_collectionChiHoQuaAPI:"API 経由の受け取りと支払い",
 header_text_giaiPhapChuyenDoiSo:"デジタル変換ソリューション",
 header_text_Gd_Yte_Gt_Dvc:"教育、健康、交通、公共サービス",
 header_text_diemThanhToan_DiemDinhDanh:"支払いポイントと識別ポイント",
 header_text_thietBi_Dichvu:"デバイスとサービス",

    body_text_congty_Hoatdongkinhdoanh2024:
      "～2024年度の事業活動（2024年10月1日）",

    body_text_congty_Hoatdongkinhdoanh: "- 2023年11月までの営業",

    header_text_theDaNang_TCNguoiNgheo_Tinhthanhpho: "州立病院",

    header_text_theDaNang_QuanHuyen: "地区病院",

    header_text_theDaNang_phoCap_boyte: "コミューン保健施設",

    header_text_theDaNang_phoCap: "普及",

    header_text_theDaNang_TCNguoiNgheo: "地区の資金不足",

    header_text_theDaNang_theDongThuongHieu: "提携カード",

    header_text_theDaNang_mamNonTieuHoc: " 小学校",

    header_text_theDaNang_thcsThpt: "セカンダリ",

    header_text_theDaNang_daiDong: "ダイドン中等教育",

    header_text_theDaNang_Thpt: " 高校",

    header_text_theDaNang_daiHocCaoDang: "  カレッジ",

    header_text_theDaNang_daiHoc: " 大学",

    header_text_theDaNang_bvTinhTp: "市立病院",

    header_text_theDaNang_bvBinhDuong: "ビンズオン病院",

    header_text_theDaNang_dichViCong: " 公共サービスの州,市",

    header_text_theDaNang_phoCap_phocapthedanang: "スマートカードの汎用化",

    header_text_theDaNang_phoCap_bogiaoduc: " 文部科学省",

    header_text_theDaNang_QuanHuyen_qThanhXuan: "タンスアン地区",

    header_text_theDaNang_QuanHuyen_xaDienBan: "ディエンバンタウン",

    header_text_theDaNang_QuanHuyen_ThachThat: "サッチ・ザット地区",

    header_text_theDaNang_QuanHuyen_xaSonTay: "息子 テイタウン",

    header_text_theDaNang_TCNguoiNgheo_CaoBang: "カオバン省",

    header_text_theDaNang_HuyenNgheo: "74 貧困地区",

    header_text_TraCuuThongKe: "統計",

    header_text_chuyenDoiSo_caiDat: " システム",

    header_text_chuyenDoiSo_GiaiPhap: "解決",

    header_text_saoKe: " 声明",

    header_text_chuyenDoiSo_QRdong: "ダイナミックQR",

    header_text_chuyenDoiSo_dichVu: " サービス",

    header_text_chuyenDoiSo_HTtudong: "自動システム",

    header_text_gd: " 取引",

    header_text_chuyenDoiSo_moHinhThanhToan: "パイロットモデル",

    body_text_thietbi_tb_GiagomVAT2200000ドン:
      " 価格(付加価値税を含む): 2,200,000 VND",

    body_text_thietbi_tb_Cambienvantay5mucdabaomatluutrutoida3000mauvantay:
      " -最大 3000 個の指紋サンプル (1500 人) を保存_",

    body_text_thietbi_tb_ip67: " - IP67 認定",

    header_text_diemThanhToan_ganNhat: " 近く",

    header_text_diemThanhToan_cuaHang: " 店",

    header_text_diemThanhToan_xangGas: " ガソリン",

    header_text_diemThanhToan_sucKhoe: " 健康",

    header_text_diemThanhToan_dienMay: " 電話",

    header_text_diemThanhToan_vanHoa: " 文化",

    header_text_diemThanhToan_trucTuyen: "オンライン",

    header_text_diemThanhToan_diChuyen: " 渋滞",

    header_text_diemThanhToan_anUong: "飲食店",

    header_text_diemThanhToan_giaoDuc: " 教育",

    header_text_theDaNang_CTTaiCho: "スポンサー",


    header_text_theDaNang_HTDonvi: "単位系",

    header_text_theDaNang_NganHangTaiCho: "コンパニオンバンク",

    header_text_ungDung_theDaNang: "ヴィマスカード",

    header_text_ungDung_gpChuyenDoiSo: "デジタル変革ソリューション",
    header_text_ungDung_htThanhToan: "決済システム",
    header_text_ungDung_htDinhDanh: "識別システム",

    header_text_ungDung_vCard: "Vカード",

    header_text_ungDung_vpass: " Vパス",

    header_text_ungDung_viVimass: "財布 Vimass",

    header_text_ungDung_qrベトナム: "QRベトナム",

    header_text_ungDung_diemravao: "識別ポイント",

    header_text_thietBi_thietBi: " デバイス",

    header_text_thietBi_Maychu: "サーバ",

    header_text_thietBi_daiLy: " エージェント",

    header_text_thietBi_phanPhoi: "分布",

    header_text_thietBi_htThanhToan: "支払い",

    header_text_thietBi_htMayChu: "サーバシステム",

    header_text_thietBi_htVpass: " Vpassシステム",

    header_text_HeThongTruSo: "本社",

    header_text_thietBi_htTraSoatThongKe: "統計を確認する",

    header_text_thietBi_htHoaDonDienTu: "電子請求書",

    header_text_thietBi_htHopDongDienTu: "電子契約",

    header_text_thietBi_htChuKySo: "  デジタル署名",

    body_text_thietbi_tb_GiabantaitroVphone:
      " スポンサー販売価格 (VAT を除く): 2,100,000 ドン",

    body_text_thietbi_tb_GiabantaitroVtab:
      " スポンサー販売価格 (VAT を除く): 2,240,000 ドン",

    header_text_phanMem_qlGiaoDich: "トランザクションの管理",

    header_text_phanMem_qlThongTin: "情報の管理",

    header_text_phanMem_qlToaDo: "座標の管理",

    header_text_khachHang_khachHang: " お客様",

    header_text_khachHang_doiTac: " 相棒",

    header_text_khachHang_tuyenDung: "求人",

    header_text_khachHang_hoiDap: " よくある質問",

    header_text_congTy_coDong: "株主",

    header_text_congTy_hoiDongQuanTri: " 取締役会",

    header_text_congTy_banKiemSoat: "監査役会",

    header_text_congTy_banDieuHanh: "執行委員会",

    header_text_congTy_chiNhanh: " 支店",

    footer_text_row_footer1_nganHang: "ベトナム国立銀行",

    footer_text_row_footer1_giayPhep: "決済仲介業許可41",

    footer_text_row_footer1_giayPhepXuatNhapKhau:
      "1.民生用暗号製品の輸出入ライセンス",

    footer_text_row_footer1_giayPhepKinhDoanh:
      "2.民生用暗号製品およびサービスの取引ライセンス",

    footer_text_row_footer1_downloadQrVietnam:
      " QR ベトナム アプリケーションをダウンロード",

    footer_text_row_footer1_downloadViViMass:
      "ダウンロード Vimasアプリケーション",

    footer_text_row_footer1_downloadVCard:
      " VCard アプリケーションをダウンロード",

    footer_text_row_footer1_xemそれら: " 続きを見る： ",

    footer_text_tenCty: "ベトナムモバイルベースサービス株式会社",

    footer_text_ctyQRVN : "QRベトナム合資会社",

    footer_text_hoiSoHaNoi:
      " 本社: 21 Le Van Luong Street, Nhan ,Golden Palm Building,5 階,505 号室 チン,タイン・スアン,ハノイ - オフィス 0243.9416205_ セール 0247_1069662, Email: cskh.vimass@gmail.com",
      footer_text_hoiSoHaNoiQRVN:       " 本社：5階、Golden Palm Building、21 Le Van Luong Street、Nhan Chinh、Thanh Xuan、Hanoi - オフィス0243.9416205。事業 0247.1069662, Email: cskh.vimass@gmail.com",
    footer_text_cnHCM:
      "ホーチミン支店: 4th Floor, 121 Hoang Hoa Tham ,区 6,ビンタイン地区 - 電話 0986609344",

    footer_text_cnDN:
      "ダナン支店: 9th Floor, 38yen Bai, Hai Chau District - Tel 0918568518",

    フッター_テキスト_cnCT:
      " できる トー支店: 4th Floor, 30A Hoang Van Thai, Hung Phu Ward, Cai Rang District - 電話 0907978098",

    footer_text_cnHP:
      "ハイフォン支店: 8 階,68 Pham Van Dong,Anh Dung Ward,Duong Kinh District - 電話 0971937026",

    footer_text_cnHue:
      " フエ支店: 28 Ly Thuong ヴィン・キエト ニン,フエ市 - 電話 0932524546",

    footer_text_cnQN:
      "クアン ニン支店: 7階, Viettel Building,70 Nguyen Van Cu,Ha Long City",

    footer_text_cnBDinh:
      "ビン ディン支店: No_ 37 Nguyen Hue, Hai Cang Ward, City_ Quy ニョン- 電話 0984922872",

    footer_text_cnNA:
      "ゲアン支店: 14th Floor, Tower A, PetroVietnam Tower, 7 Quang Trung ,ヴィン市 - 電話 0942707888",

    footer_text_cnTH:
      "タン ホア支店: 9th Floor, Golden Duc Phat Building, 9 Hac Thanh, Dien Bien Ward, Thanh Hoa City - 電話 0946469704",

    footer_text_cnBD:
      "ビンズオン支店: BW SCC オフィスビル 4 階,ビンズオン工業 - サービス - 都市複合施設,ホア フー区,市_ トゥ・ダウ・モット",

    footer_text_ctyTrucThuoc: "ヴィマス社の対象会社",

    footer_text_theDaNang: " スマートカード",

    footer_text_diemThanhToan: "支払いポイント",

    footer_text_ungDung: " 応用",

    footer_text_thietBi: "デバイス",

    footer_text_phanMem: "ソフトウェア",

    footer_text_dichVu: " サービス",

    footer_text_khachHang: "クライアント",

    footer_text_cty: " 会社",

    footer_text_chuongTrinhTaiTro: "スポンサーシッププログラム",

    footer_text_giaiPhapChuyenDoiSo: "デジタル変換",

    footer_text_diemGiaoDich: "トランザクションポイント",

    footer_text_diemRaVao: " 入口/出口ポイント",
    footer_text_banHangBangVietQR: "VietQRを使用した販売",

    item_footer_0: "音声QRによる代金回収",
    item_footer_1: "APIを通じた代金回収と支払い",
    item_footer_2: "電子ウォレット、決済ポイント、認証ポイント",
    item_footer_3: "認証、本人確認、電話なしでの支払い",
    item_footer_4: "教育、医療",
    item_footer_5: "交通、公共サービス",
    item_footer_6: "サーバー、機器、サービス",
    item_footer_7: "会社",

    body_title_congty_codong: "会社,株主,ライセンス",

    body_text_congty_codong_231027クイェットディンタンラップバンアントアントンティン:
      " - 2023 年 11 月 15 日付けのVimass情報システムに関する規則",

    body_text_congty_codong_23_9_20_Thong_bao_ve_viec_dang_ky_tang_von_dieu_le:
      " - 2023 年 9 月 20 日の定款資本登録のお知らせ",

    body_text_congty_codong_23_9_5_Quyet_dinh_phan_cong_nhiem_vu:
      " - 2023 年 12 月 1 日の任務の割り当ての決定",

    body_text_congty_codong_NghiQuyetHoiDongQuanTri16032023:
      " - 2023 年 6 月 13 日付けのVimas取締役会決議",

    body_text_congty_codong_BienbanhopHDQTVimassngay13062023:
      " - 2023 年 7 月 6 日のVimass取締役会議事録",
      body_text_congty_codong_BienbanhopHDQTVimassngay05082004:
      "-2023年8月5日Vimass董事會會議紀要", 
    body_text_congty_codong_HoatDongKinhDoanh15032023:
      " -今年最初の 6 か月の事業活動 11/07/2023",

    body_text_congty_codong_23_7_3_Quyet_dinh_ve_gia_ban_thiet_bi:
      " - 2023/03/07 装備販売価格の決定",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay20072023:
      " - 2022年7月20日臨時株主総会議事録",
  body_text_congty_codong_HoSoCapDoAnToanHTTTVIMASS: '- 2024 年 3 月 22 日の Vimass 本社情報システムの安全レベル プロファイル',
    body_text_congty_codong_Danhsachcodongvavongopngay04052023:
      " - 2024年10月03日付株主および出資者一覧",
      body_text_congty_codong_ChuCapHoiToanHoc:
      "- 2024 年 8 月 7 日付けのベトナム数学協会賞基金のスポンサーに関する理事会決議",
    body_text_congty_codong_Bien_ban_hop_HDQT:
      "- 2024 年 1 月 26 日の取締役会議事録",
      body_text_congty_codong_phongchongruatien:
 "- 자금세탁방지규정 공포 2023년 5월 1일",

 body_text_congty_codong_ThucHienQuyDinhChinhPhu:
 "- 2024년 7월 18일 정부 및 SBV에 따른 규정 시행을 공포하는 결정",
 body_text_congty_codong_QDBanHanhbotieuchi:
 "- 2024년 7월 18일 사기, 사기 또는 법률 위반이 의심되는 징후가 있는 전자 지갑을 식별하기 위한 일련의 기준을 공포하기로 결정",

 body_text_congty_codong_ThucHienXoaTaiKhanVi:
 "- 2022년 1월 1일 비활성 월렛 계정 삭제 결정",

 body_text_congty_codong_DVTGTT:
 "- 2024년 7월 18일 세계적 수준의 서비스 공급에 관한 위험관리 규정을 공포하는 것에 관한 사무국장의 결정",

    body_text_congty_codong_BienbanhopHDQTVimassngay17042023:
      " - 2023 年 4 月 17 日のVimass取締役会議事録",

    body_text_congty_codong_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      " - 2023 年 4 月 17 日に支払いポイントを作成し,機器を配布する協力者の決定",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay07042023:
      " - 2023年4月7日開催の2023年年次株主総会議事録",
      body_text_congty_codong_ThongtuNHNN:
 "- 2024년 7월 17일 발행된 베트남 중앙은행 시행규칙 No. 40/2024/TT-NHNN",
      body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2023ngay25042024:
      " - 2024年4月25日開催の2023年年次株主総会議事録",
    body_text_congty_codong_BienbanhopHDQTVimassngay17032023:
      " - 2023 年 3 月 17 日のVimas取締役会議事録",

    body_text_congty_codong_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      " - 2022 年の活動報告,2023 年の 2 か月の結果,および 2023 年の計画は 2022 年 3 月 14 日に発表されます。",

    body_text_congty_codong_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      " - 2022 年 3 月 14 日の 2023 年の最初の 2 か月の事業活動",

    body_text_congty_codong_Baocaokiemtoan2022CongTyCoPhanDichVuNenDiDongVietNamngay08032022:
      " - 監査報告書 2022,ベトナム モバイル ベース サービス株式会社,日付 08/03/2022",

    body_text_congty_codong_Baocaokiemtoan2023CongTyCoPhanDichVuNenDiDongVietNamn:
      "- 監査済み財務諸表 2023, ViMASS ., JSC (11/03/2024)",

    body_text_congty_codong_Quyetdinhvenhomcongtacvienngay22022023:
      " - 2023 年 2 月 22 日の協力者のグループに関する決定",

    body_text_congty_codong_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      " - Vimas QRVNに対する回収および支払いサービスの提供に関する契約(2023 年 2 月 2 日付け)",

    body_text_congty_codong_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - Vimassの金融サービス手数料に関する決定01/02/2023",

    body_text_congty_codong_QuyetdinhtrienkhaithunghiemthedanangtaiVietNamngay1712023:
      " - 2023 年 1 月 17 日にベトナムで多機能カードをテストする決定",

    body_text_congty_codong_Danhsachcodongvavongopngay16012023:
      " - 2023 年 1 月 16 日の株主および出資資本のリスト",

    body_text_congty_codong_QuyetdinhcapthevatemchocanbonhanvienVimassngay1612023:
      " - 2023 年 1 月 16 日にVimas従業員にカードとスタンプを発行することを決定",

    body_text_congty_codong_BienbanthoathuansudungtenvalogocuaHoihuunghiVietNamHungaryngay09122022:
      " - 2022 年 12 月 9 日にベトナム・ハンガリー友好協会の名前とロゴを使用することに合意した議事録",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay050224:
      "-2024 年 2 月 5 日に民間暗号製品の輸出入のライセンス",

    body_text_congty_codong_Giayphepxuatkhaunhapkhausanphammatmadansungay29112022:
      " - 2022 年 11 月 29 日に民間暗号製品の輸出入のライセンス",

    body_text_congty_codong_Dangkykinhdoanhlanthu22ngay11102022:
      " - 2022年11月10日 第22回事業者登録",

    body_text_congty_codong_Dangkykinhdoanhlanthu25:
      " - 25 件目の事業者登録 2023 年 5 月 10 日",

    body_text_congty_codong_QuyetdinhgiamgiolamCBNV:
      " - 2023 年 10 月 1 日から従業員 19 名の労働時間と収入を削減することを決定",

    body_text_congty_codong_Dangkykinhdoanhlanthu24ngay6723:
      " -第 24 回事業者登録 2023/06/07",

    body_text_congty_codong_BienbanhopHDQTVimassngay06102022:
      " - 2022 年 10 月 6 日のVimass取締役会議事録",

    body_text_congty_codong_BienbanhopDaihoicodongbatthuongngay31082022:
      " - 2022年8月31日臨時株主総会議事録",

    body_text_congty_codong_BienbanhopHDQTVimassngay02082022:
      " - 2022 年 8 月 2 日のVimass取締役会議事録",

    body_text_congty_codong_HopdonghoptaccungcapdichvuthuvachihoViMASSQRVNngay11072022:
      " - ViMASS QRVNに代わって回収および支払いサービスを提供する協力契約 ( 2022 年 11 月 7 日付け)",

    body_text_congty_codong_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - 2022 年 7 月 1 日に従業員に機器を提供する決定",

    body_text_congty_codong_BienbanhopHDQTngay31052022:
      " - 2022 年 5 月 31 日の取締役会議事録",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhNgheAnngay2752022:
      " - 2022 年 5 月 27 日にゲアン支店の最初の事業登録",

    body_text_congty_codong_DangkykinhdoanhlandaucuachinhanhQuangNinhngay20052022:
      " - Quangの最初の企業登録 2022年5月20日のニン支店",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHuengay18052022:
      " - 2022 年 5 月 18 日にフエ支店で最初の企業登録",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhHaiPhonngngay07052022:
      " - 2022 年 5 月 7 日にハイフォン支店で初めて企業登録",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhCanThongay29042022:
      " - 2022 年 4 月 29 日にカントー支店を初めて事業登録",

    body_text_congty_codong_BienbanhopHDQTngay28042022:
      " - 2022 年 4 月 29 日にカントー支店を初めて事業登録",

    body_text_congty_codong_BaocaohoatdongquyIvakehoach2022ngay26042022:
      " - 2022 年 4 月 26 日の第 1 四半期の活動と 2022 年の計画に関する報告",

    body_text_congty_codong_DangkykinhdoanhlandauchinhanhDaNangngay22032022:
      " - 2022 年 3 月 22 日にダナン支店で最初の事業者登録",

    body_text_congty_codong_Dangkykinhdoanhlanthu21ngay11032022:
      " - 2022/11/03 第21回事業者登録(pdf)",

    body_text_congty_codong_BienbanhopDaihoicodongthuongnien2022ngay22022022:
      " - 2022 年 2 月 22 日の 2022 年年次株主総会議事録",

    body_text_congty_codong_BaocaocuaCTHDQTtaiDHDCDthuongnien21022022:
      " - 2022年2月21日開催の定時株主総会における取締役会議長報告",

    body_text_congty_codong_BaocaokiemtoanVimass2021ngay27012022:
      " - 2022 年 1 月 27 日付けのVimass 2021 監査報告書",

    body_text_congty_codong_BienbanhopHDQT2512022:
      " -取締役会議事録 2022 年 1 月 25 日 (pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu20ngay31122021:
      "～ 2021年12月31日 第20回事業者登録について（pdf）",

    body_text_congty_codong_BienbanhopHDQTVimassngay30112021:
      " - 2021 年 11 月 30 日のVimass取締役会議事録(pdf)",

    body_text_congty_codong_BienbanhopHDQTVimassngay28102021:
      " - 2021/10/28 のVimas取締役会議事録(pdf)",

    body_text_congty_codong_Bangcandoiketoanvabaocaoketquakinhdoanh9thangdaunam202122102021:
      " - 2021 年最初の 9 か月の貸借対照表および損益計算書 2021 年 10 月 22 日(pdf)",

    body_text_congty_codong_Giayphepkinhdoanhsanphamdichvumatmadansu4102021:
      " -民間暗号化製品およびサービスの取引ライセンス (pdf) 2021 年 10 月 4 日",

    body_text_congty_codong_BienbanhopHoidongquantriVimassngay2262021:
      " - 2021 年 6 月 22 日のVimass取締役会議事録(pdf)",

    body_text_congty_codong_BienbanhopDaihoidongcodongthuongnien2021ngay2742021:
      " - 2021年4月27日開催の2021年定時株主総会議事録 (pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu19ngay23032021:
      " -第 19 回事業者登録 2021/03/23(pdf)",

    body_text_congty_codong_Dangkykinhdoanhlanthu18ngay15102020:
      " -第18回事業者登録 2020/10/15(pdf)",

    body_text_congty_codong_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      " - 2019 年 4 月 12 日にパブリック CKS 認証サービスを提供するスタッフを割り当て",

    body_text_congty_codong_BienbanvanghiquyethopDHDCDthuongnien2019ngay442019:
      " - 2019 年 4 月 4 日の 2019 年年次株主総会の議事録と決議",

    body_text_congty_codong_NghiquyetHoidongquantriVimassngay242019:
      " - 2019 年 2 月 4 日付けのVimas取締役会の決議",

    body_text_congty_codong_Giaymoihopdaihoicodongthuongnien2019ngay2132019:
      " - 2019年3月21日開催の2019年度定時株主総会へのご招待",

    body_text_congty_codong_Danhsachemailcuacodongngay2132019:
      " - 2019 年 3 月 21 日の株主の電子メールリスト",

    body_text_congty_codong_NghiquyetHDQTthongquaviecxincapgiayphepchungthucCKSngay2522019:
      " - 2019 年 2 月 25 日付けの CKS 認証証明書の申請を承認する取締役会の決議",

    body_text_congty_codong_NghiquyetHoidongquantriVimassveviecvayvonngay3012019:
      " - 2019 年 1 月 30 日の資本借入に関するVimass取締役会の決議",

    body_text_congty_codong_GiayphepnhapkhauthietbiMMDSngay2812019:
      " - 2019 年 1 月 28 日に MMDS 機器の輸入許可",

    body_text_congty_codong_DKKDlan6cuachinhanhHCMngay2412019:
      " - 2019 年 1 月 24 日,ホーチミン支店の第 6 回事業者登録",

    body_text_congty_codong_BienbanhopHDQTlan1ngay2312019:
      " - 2019 年 1 月 23 日の第 1 回取締役会議事録",

    body_text_congty_codong_NghiquyetHDQTso1201dQDHDQTHuydongvongop10ngay18122018:
      " -取締役会決議 No_1201d_QDHDQT 2018 年 12 月 18 日に出資額を 10% 引き上げる",

    body_text_congty_codong_MauhopdongchuyennhuongcophanVimas:
      " - Vimas株式譲渡契約書 (ドキュメント)",

    body_text_congty_codong_MaubienbanthanhlyHDCNPVGVimass:
      " -契約 PVG Vimassの清算議事録の形式(ドキュメント)",

    body_text_congty_codong_Dieulecongtycophandichvunendidongベトナム:
      " -ベトナムモバイルプラットフォームサービス株式会社の憲章 (ドキュメント)",

    body_text_congty_codong_Dangkykinhdoanhlan17ngay492018:
      " - 2018年4月9日 第17回事業者登録",

    body_text_congty_codong_Dangkykinhdoanhlan16ngay1362018:
      " -第 16 回事業者登録 2018 年 6 月 13 日",

    body_text_congty_codong_BienbanhopHoidongthanhvienthuongnienVimassngay952018:
      " - 2018 年 9 月 5 日のVimas年次理事会の議事録",

    body_text_congty_codong_DangkykinhdoanhVimasslan15ngay442018:
      " - 2018 年 4 月 4 日に第 15 回Vimas事業に登録されました",

    body_text_congty_codong_Giayphep41GPNHNNcuaNganhangnhanuoc1232018:
      " - 2018 年 3 月 12 日州立銀行のライセンス 41/GPNHNN",

    body_text_congty_codong_DangkykinhdoanhVimasslan14ngay412018:
      " -第 14 回Vimas事業者登録 (2018 年 4 月 1 日)",

    body_text_congty_codong_Dangkykinhdoanhlan6ngay19122013:
      " - 2013/12/19 第6回事業者登録",

    body_text_congty_codong_Dangkykinhdoanhlandaungay3152012:
      " - 2012 年 5 月 31 日に最初の事業者登録",

    body_text_congty_bdh_BANDIEUHANH: "執行委員会",

    body_text_congty_bdh_ChutichHDQT: "取締役会長",

    body_text_congty_bdh_Tonggiamdoc: "CEO （最高経営責任者）",

    body_text_congty_bdh_Photonggiamdocthuongtruc: "副CEO（副最高経営責任者）",

    body_text_congty_bdh_Photonggiamdocphutrachtaichinh: " 副社長 財務担当",

    body_text_congty_bdh_Photonggiamdocphutrachcacchinhanh: "支店担当副社長",

    body_text_congty_bdh_Photonggiamdocphutrachthietbivathe:
      " 副社長 機器・カード担当",

    body_text_congty_bdh_Truongphongmaychu: "サーバー部門の責任者",

    body_text_congty_bdh_PhongWebDesktopMiniVUHF:
      "Web ,デスクトップ,ミニ,VUHF部門",

    body_text_congty_bdh_TruongphonglaptrinhiPhone:
      " iPhone プログラミング部門の責任者",

    body_text_congty_bdh_TruongphonglaptrinhAndroidVPhonevaVTablet:
      " Android, VPhone ,およびVTabletプログラミング部門の責任者",

    body_text_congty_bdh_チャンヴァンフォン: " 事務長",

    body_text_congty_bdh_TruongphongMaketing: "マーケティング部門の責任者",

    body_text_congty_bdh_Danhsachcodongvavongopngay04052023:
      " - 2023 年 5 月 19 日の株主と出資のリスト",

    body_text_congty_bdh_HopdongcungcapdichvuthuvachihoVimassQRVNngay02022023:
      " - 2023/02/02 のVimas QRVNに対する回収および支払いサービスの提供に関する契約",

    body_text_congty_bdh_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - 2023 年 1 月 2 日のVimasの金融サービス手数料に関する決定",

    body_text_congty_bdh_QuyetdinhbonhiemPhotonggiamdocngay31012023:
      " - 2023 年 1 月 31 日の副 CEO の任命決定",

    body_text_congty_bdh_QuyetdinhbonhiemTruongphongThietbingay31012023:
      " - 2023/01/31 の設備部門責任者の任命に関する決定",

    body_text_congty_bdh_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - 2022 年 1 月 7 日の従業員への機器の提供に関する決定",

    body_text_congty_bdh_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      " - 2022 年 6 月 30 日の最低職務要件を満たさない従業員に対する懲戒処分の決定",

    body_text_congty_bdh_Quydinhvelichhopngay28062022:
      " - 2022/06/28 の会議スケジュールに関する規定",

    body_text_congty_bdh_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      " - 2022 年 6 月 23 日の本社および支店におけるViMASS従業員の採用に関する CEO の決定",

    body_text_congty_bdh_QDcuaTGDvecackhoanchitaiCNngay03062022:
      " - 2022 年 3 月 6 日の支店の経費に関する CEO の決定",

    body_text_congty_bdh_QuydinhcuaTGDthaydoimucthunhapnhanvien02042022:
      " - 2022 年 2 月 4 日の従業員収入レベルの変更に関する CEO の規則",

    body_text_congty_bdh_QuydinhveviectaosualuVimassQR1822022:
      " - 2022 年 2 月 18 日のVimas QRの作成,編集,保存に関する規制",

    body_text_congty_bdh_Quyetdinhvephidichvudiemthuongvadiemmay1122022:
      " - 2022 年 11 月 2 日のサービス料金,ボーナス ポイント,および抽選ポイントに関する決定",

    body_text_congty_bdh_QuyetdinhxuphatCBNVkhonghoanthanhnhiemvutoithieu2612022:
      " - 2022 年 1 月 26 日に最低職務要件を満たしていない従業員に対する懲戒処分の決定",

    body_text_congty_bdh_BienbanhopHDQTVimassngay30112021pdf:
      " - 2021/11/30 のVimas取締役会議事録(pdf)",

    body_text_congty_bdh_QuyetdinhcuaTongGiamDocveviectuanthutheoThongtuso232019TTNHNN:
      " -回覧番号 23/2019/TTNHNN の遵守に関する CEO の決定",

    body_text_congty_bdh_GopychoduthaoThongtusuadoiThongtu392014TTNHNNngay252019:
      " - 2019 年 2 月 5 日の回覧番号 39/2014/TTNHNN の修正草案に関するコメント",

    body_text_congty_bdh_Phancongcanbohotrokhachhangngay2542019:
      " - 2019 年 4 月 25 日に顧客をサポートするスタッフの配置",

    body_text_congty_bdh_PhancongcanbotrienkhaicungcapdichvuchungthucCKScongcongngay1242019:
      " - 2019 年 12 月 4 日に公開キー基盤 (PKI) サービスを導入するためのスタッフの割り当て",

    body_text_congty_hdqt_HOTEN: " 名前",

    body_text_congty_hdqt_CHUCVU: " 位置",

    body_text_congty_hdqt_ChutichHDQTkiemTonggiamdoccongtycophandichvunendidongベトナム:
      "ベトナムMobile Services Corporationの取締役会会長兼CEO",

    body_text_congty_hdqt_PhochutichHDQT: "取締役会副会長",

    body_text_congty_hdqt_ThanhvienHDQT: "役員",

    body_text_congty_hdqt_HOIDONGQUANTRI: "管理委員会",

    body_text_congty_hdqt_truongBanKiemSoat: "内部監査委員長",

    body_text_congty_hdqt_thanhVienBanKiemSoat: "内部監査委員会の委員",

    body_text_congty_cn_Quyetdinhvecongtacvientaodiemthanhtoanvaphanphoithietbingay17042023:
      " - 2023 年 4 月 17 日付の支払いポイントの作成とデバイス配布の協力者に関する決定",

    body_text_congty_cn_Baocaohoatdongnam2022ketqua2thang2023vakehoach2023ngay14032022:
      " - 2022 年の活動に関するレポート,2023 年の最初の 2 か月の結果,および 2023 年の計画 (2022 年 3 月 14 日付け)",

    body_text_congty_cn_Hoatdongkinhdoanh2thangdaunam2023ngay14032022:
      " - 2022 年 3 月 14 日付の 2023 年最初の 2 か月の事業活動",

    body_text_congty_cn_Baocaokiemtoan2022CongTyCoPhanDichVuDienThoaiDiDongVietNamngay08032022:
      " -監査報告書 2022,ベトナム モバイル サービス株式会社,2022 年 3 月 8 日付け",

    body_text_congty_cn_QuyetdinhvephidichvutaichinhcuaVimass01022023:
      " - Vimassの金融サービス手数料に関する決定について2023 年 2 月 1 日",

    body_text_congty_cn_QuyetdinhcungcaptrangthietbichoCBNVngay01072022:
      " - 2022 年 7 月 1 日付けの職員への備品提供に関する決定",

    body_text_congty_cn_QuyetdinhxuphatdoivoiCBNVkhongdapungnhiemvutoithieungay30062022:
      " - 2022 年 6 月 30 日付け,最低限の責任を果たさなかったスタッフに対する罰則に関する決定",

    body_text_congty_cn_Quydinhvelichhopngay28062022:
      " - 2022 年 6 月 28 日付の会議スケジュールに関する規則",

    body_text_congty_cn_QuyetdinhcuaTGDvetuyendungCBNVViMASStaiHoisovacacchinhanhngay23062022:
      " - 2022 年 6 月 23 日付,本社および支店におけるViMASSスタッフの採用に関する CEO の決定",

    body_text_congty_cn_QDcuaTGDvecackhoanchitaiCNngay03062022:
      " - 2022 年 6 月 3 日付の支店での支出に関する CEO の決定",

    body_text_congty_cn_KehoachphattrienChinhanh20222024:
      " - 2022 ～ 2024 年の支店開発計画",

    body_text_dichvu_pdv_TT: "TT ",

    body_text_dichvu_pdv_PHIDICHVUTAICHINH: "金融サービス料金",

    body_text_dichvu_pdv_PHITHUDOIVOIBENNHANTHANHTOAN: "支払先へのサービス料金",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQR:
      "  使用 Vimas Wallet,コレクションウォレットに支払うか,VNPAY QR をスキャンするための多機能カード",

    body_text_dichvu_pdv_Ruttoanbotientuvichuyenthuvetaikhoannganhangluc6h8h10h12h14h16h18hsotientoithieu:
      " 午前 6 時,午前 8 時,午前 10 時,午後 12 時,午後 2 時,午後 4 時,午後 6 時にコレクションウォレットから銀行口座にすべてのお金を引き出します: (% 金額,最小 2 セント)",

    body_text_dichvu_pdv_DungungdungnganhangquetVietQRthanhtoanngaydentaikhoannganhangsotientoithieu:
      "銀行アプリケーションを使用してVietQRをスキャンし,すぐに銀行口座に支払います (% 金額,最小 3 セント))",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang:
      "  使用 Vimas Wallet,銀行口座にすぐに支払える多機能カード",

    body_text_dichvu_pdv_PHITHUDOIVOIBENTHANHTOAN: "支払者へのサービス料金",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoandenvichuyenthuhoacquetVNPAYQRsotientoithieu:
      " Vimas Wallet,多機能カードを使用して支払いを行い,コレクションウォレットに送金するか,VNPAY QR をスキャンしてください (% 金額,最小 250 vnd)",

    body_text_dichvu_pdv_DungViVimassThedanangthanhtoanngaydentaikhoannganhang2:
      " 使用 Vimas Wallet,銀行口座にすぐに支払う多機能カード (% 金額,最小 3 セント)",

    body_text_dichvu_pdv_YEUCAUCHUYENTIENCHUYENTIEN:
      "送金または送金のリクエスト",

    body_text_dichvu_pdv_Chuyentientuvidenvisotientoithieu200dongBaogomvicanhanvidoanhnghiepthedanangvichuyenthucuacuahang:
      " ウォレットからウォレットへの送金 (% 金額,最低 250 VND  個人ウォレット,ビジネス ウォレット,多機能カード,コレクション ウォレットを含む)",

    body_text_dichvu_pdv_Chuyentiennhanhdentaikhoannganhanghoacsothemoigiaodichtoida300trieudon:
      "銀行口座またはカード番号への送金 (取引ごとに最大 3 億 VND ,金額の%,最小 3 セント)",

    body_text_dichvu_pdv_NaptienvaoviquaQRhoacquataikhoansotientoithieu:
      " QR またはアカウント経由でウォレットにチャージします (金額の %,最小 3 セント)",

    body_text_dichvu_pdv_Ruttientuvivetaikhoannganhangmoigiaodichtoida300trieudong:
      "財布から銀行口座にお金を引き出します (取引ごとに最大 3 億 VND,金額の %,最小 3 セント)",

    body_text_dichvu_pdv_THUHOtheohopdong: "コレクション,世帯（契約中）",

    body_text_dichvu_pdv_ChihoVNPOST:
      " a ) VNPOST で徴収,トランザクションごとに 1000 VND",

    body_text_dichvu_pdv_KhidungungdungnganhangquetQRmattruocthesotientoithieu200dong:
      " b) 銀行アプリケーションを使用する場合は,カードの表面にある QR コードをスキャンします: 金額の %,少なくとも 200 VND",

    body_text_dichvu_pdv_KhidungungdungQRVietnamquetQRmattruoctheThuhoquaThedanangsotientoithieu200dong:
      " c ) QR ベトナム アプリケーションを使用する場合,カードの表面にある QR コードをスキャンします。多機能カードで徴収: 金額の %,最低 250 VND",

    body_text_dichvu_pdv_CHIHOテオホプドン: " 承認された経費 (契約による)",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichduoi1trieudong:
      " d) 受取人はVimasウォレットで,取引額は 100 万 VND 未満です",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtu1trieuden20trieudong:
      " e) 受取人はVimasウォレット,取引額は 100 万から 2,000 万 VND",

    body_text_dichvu_pdv_BennhanlaviVimassgiatrigiaodichtren20trieudon:
      " f) 受取人はVimasウォレット,取引額は 2,000 万 VND 以上です",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangduoi100000:
      " g) 受信者はアカウントで,月あたりの取引数は 100.000 未満です。",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu100000den200000:
      " h) 受信者はアカウントで,月あたりのトランザクション数は 100,000 から 200,000 です。",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu200000den500000:
      " i ) 受信者はアカウントで,月あたりのトランザクション数は 200,000 から 500,000 です。",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtu500000den1000000:
      " j) 受信者はアカウントで,月あたりのトランザクション数は 500,000 から 1,000,000 です。",

    body_text_dichvu_pdv_Bennhanlataikhoansogiaodichmotthangtren1000000:
      " k) 受信者はアカウントで,1 か月あたりのトランザクション数は 1,000,000 を超えています",

    body_text_dichvu_pdv_CONGTHANHTOANTHE: "カード決済ゲート",

    body_text_dichvu_pdv_Dungthenoidiathanhtoanchodonvihoatdongtronglinhvucytegiaoducgiaothongcongcongdichvucongthuekinhdoanhxangdau:
      "a) 医療,教育,公共交通機関,公共サービス,税金,石油事業の分野で活動するユニットの支払いに国内カードを使用する",

    body_text_dichvu_pdv_DungthenoidiacuanganhangcoketnoithanhtoanthevoiVimass:
      " b) Vimassとカード決済提携している銀行の国内カードを使用する",

    body_text_dichvu_pdv_Dungthenoidiacuanganhangchuaketnoi:
      " c) 提携していない銀行の国内カードを使用する",

    body_text_dichvu_pdv_DungtheVISAMasterCardJCBAMEX:
      " d) カードは VISA,MasterCard,JCB,AMEX をご利用ください",

    body_text_dichvu_pdv_TIETKIEMTRUCTUYEN: "オンライン保存",

    body_text_dichvu_pdv_HuyDong: "動員（国家銀行の規定による）",

    body_text_dichvu_pdv_Chovay: "融資（州立銀行の規定による）",

    body_text_dichvu_pdv_Dangcapnhat:
      "オンライン貯金の収納および支払いサービスが更新されます",

    body_text_dichvu_pdv_Thoathuan: " 交渉可能",

    body_text_dichvu_pdv_MUCPHI: "  手数料",

    body_text_dichvu_cks_STT: " シーケンス番号",

    body_text_dichvu_cks_phiVAT: "料金（付加価値税を含む）",

    body_text_dichvu_cks_diemThuong: " Vポイント",

    body_text_dichvu_cks_diemMay: " 縫製点LPoint ",

    body_text_dichvu_cks_menhGia: " vnd ",

    body_text_dichvu_cks_DICHVUCHUKYSOsudungtheSIMkysoVimas:
      "デジタル署名サービス（ Vimasデジタル署名カード/SIM使用）",

    body_text_dichvu_cks_CHUKYSOTHEONAM: "年ごとのデジタル署名",

    body_text_dichvu_cks_Giathuebaochukysocanhan1nam:
      "個人電子署名登録1年間の価格",

    body_text_dichvu_cks_Giathuebaochukysocanhan2nam:
      "個人電子署名登録2年間料金",

    body_text_dichvu_cks_Giathuebaochukysocanhan3nam: "個人電子署名3年登録価格",

    body_text_dichvu_cks_Giathuebaochukysotochuc1nam:
      " 1 年間のデジタル署名サブスクリプションの価格",

    body_text_dichvu_cks_Giathuebaochukysotochuc2nam:
      " 2 年間のデジタル署名サブスクリプションの価格",

    body_text_dichvu_cks_Giathuebaochukysotochuc3nam:
      " 3 年間のデジタル署名サブスクリプションの価格",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc1nam:
      "組織のデジタル署名の 1 年間の更新",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc2nam:
      "組織向けデジタル署名の 2 年間更新",

    body_text_dichvu_cks_Giahanchukysodanhchotochuc3nam:
      "組織向けデジタル署名の 3 年間更新",

    body_text_dichvu_cks_CHUKYSOTUNGLAN: "毎回デジタル署名",

    body_text_dichvu_cks_Gia1lankyneuduoi1000lannamkhongduoi8lan:
      "年間1,000回未満（8回以上）の場合は1サインあたりの料金",

    body_text_dichvu_cks_Gia1lankyneutu1000den10000lannam:
      " 1.000 ～ 10.000 回/年の場合の 1 サインの価格",

    body_text_dichvu_cks_Gia1lankyneutu10000den100000lannam:
      " 10,000 ～ 100,000 回/年の場合の 1 サインの価格",

    body_text_dichvu_cks_Gia1lankyneutu100000den1000000lannam:
      " 100,000 ～ 1,000,000 回/年の場合の 1 サインの価格",

    body_text_dichvu_cks_Gia1lankyneutren1000000lannam:
      " 1,000,000 回/年を超える場合の 1 サインの価格",

    body_text_dichvu_cks_CHUKYSOTUXA: "リモートからのデジタル署名",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrong24gio:
      "リモート署名の料金は 1 回,24 時間で 10 回まで",

    body_text_dichvu_cks_Gia1lankytuxakhongqua10lantrongthang:
      "月に 10 回までの 1 回のリモート署名の料金",

    body_text_dichvu_cks_Gia10lankytuxathang:
      "リモートサインイン10 回/月の価格",

    body_text_dichvu_cks_Gia20lankytuxathang:
      "月あたり 20 回のリモート署名の料金",

    body_text_dichvu_cks_Gia50lankytuxathang: "リモートサイン50回/月の料金",

    body_text_dichvu_cks_Gia100lankytuxathang: " 100 件のリモート署名/月の料金",

    body_text_dichvu_cks_Giakytuxakhongqua200lannam:
      "リモート署名の料金は年間 200 回以下です",

    body_text_dichvu_cks_DICHVUCHUKYSOリモート署名:
      "デジタル署名サービス（リモート署名）",

    body_text_dichvu_cks_Chukyso24hKhoitaovakychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong24hketukhicap:
      " 24 時間デジタル署名 - 初期化と署名 (デジタル トランザクションを実行する個人向け_ 発行日から 24 時間以内に初期化と署名は 10 回まで)",

    body_text_dichvu_cks_Chukysothoihan1thangvoi10luotkychocanhanthuchiencacgiaodichsoKhoitaovakykhongqua10lantrongvong30ngayketukhicap:
      "デジタル署名は 10 回の署名で 1 か月間有効です (デジタル取引を実行する個人の場合_ 発行日から 30 日以内に初期化と署名は 10 回まで)",

    body_text_dichvu_cks_Goibosung10luotkythang: "追加パッケージ 10 サイン/月",

    body_text_dichvu_cks_Goibosung20luotkythang:
      " 20 サインアップ/月の追加パッケージ",

    body_text_dichvu_cks_Goibosung50luotkythang: "追加パッケージ 50サイン/月",

    body_text_dichvu_cks_Goibosung100luotkythang:
      " 100 サインアップ/月の追加パック",

    body_text_dichvu_cks_Chukyso1namkhongqua200luotnam:
      "署名回数 1年間（年間200回以内）",

    main_images_chuongTrinhTaiTro: "img/8cuc/cuc2/taitrothidiemJP.jpg",
    main_images_qrAMtHANH: "img/8cuc/cuc1/QRamthanh-j.jpg",

    main_images_theDaNang:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695627265332XTN1F",

    main_images_diemravao:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695627296108f09IF",

    main_images_diemThanhToan:
      " https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695627320226VORSF",

    // main_images_home_ungDung : "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=17065165099555GJoF",

    main_images_home_ungDung: "img/8cuc/cuc1/giai-phap-cds-j.jpg",

    main_images_home_giaoduc:
    "img/8cuc/cuc1/VPosJ.jpg",

    main_images_home_yte: "img/8cuc/cuc1/Thuchiho-j.jpg",

    main_images_home_giaothong: "img/8cuc/cuc1/YteDichvucongGiaothong-j.jpg",
      main_images_home_GD_YTE: "img/8cuc/cuc1/Giaoduc-j.jpg",
      main_images_home_diemgiaodich:
      "img/8cuc/cuc1/diemthanhtoan-j.jpg",

    main_images_thietBi:
      "img/8cuc/cuc8/thietbivadichvuJP.jpg",

    main_images_phanMem:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630404090P1EKF",

    main_images_dichVu:
      "img/8cuc/cuc1/thietbivadichvu-e.jpg",

    main_images_khachHang:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=1695630435108WOgsF",

    main_images_congTy:
      "img/8cuc/cuc1/congtyJP.jpg",

    main_images_bannerWeb1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb1-E.jpg",

    main_images_bannerWeb2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerWeb2-E.jpg",

    main_images_bannerMobile1:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile1-E.jpg",

    main_images_bannerMobile2:
      "https://web.vimass.vn/VimassMedia/services/VMMedia/getImage?id=bannerMobile2-E.jpg",

    body_HopDong_text_title: " 契約",

    body_HopDong_text_hopDongDaKy: "契約書に署名",

    body_HopDong_text_ThoathuanhoptacCMCVIMASS: " - CMC - ヴィマス協力協定",

    body_HopDong_text_ThoathuanhoptacVimassPaytech:
      " -PAYTECH-VIMASSサービス協力契約",

    body_HopDong_text_hopdonghoptackinhdoanhdichvuxacthucbangkhuonmatVIMASSvPSPACES:
      " - VIMASS - VPSPACES 顔認識サービスに関する業務協力協定",

    body_HopDong_text_HoptacvoiVAIS:
      " - VIMASS - VAIS 音声認証サービスに関する業務協力協定",

    body_HopDong_text_hopdonghoptacVimassNanosoft:
      " - VIMASS - NANOSOFT 協力協定",

    body_HopDong_text_hopdongcungcapdichvuVimassTiem:
      " - VIMASS - TIEM サービス供給契約書",

    body_HopDong_text_thoathaphoptacthanhtoankhongdungtienmatvimassvafpt:
      " - Vimass - FPT 教育会社に対する非現金支払いおよび金銭的包含に関する FPT 協力協定",

    body_HopDong_text_thoathuanhoptackhongdungtienmattaibenhvientpbvavimass:
      " - VIMASS - VIMASS 病院での非現金支払いの導入に関する TPBANK 合意",

    body_HopDong_text_hopdongbidvvimass: " - VIMASS - BIDV 子会社協力協定",

    body_HopDong_text_HopdongdichvuthuhoVpbankVimass:
      " - VIMASS - 回収サービスに関する VPB 契約",

    body_HopDong_text_DichVuChihovoiVPBankVimas:
      " -補助支払サービスに関する - VIMASS - VPB 契約",

    body_HopDong_text_HopdongVimassIntergate: " -ヴィマス - ゲート間協力協定",

    body_HopDong_text_hopdongVimassNapas: " -ヴィマス - ナパス協力協定",

    body_HopDong_text_hoptacvimassvavnca: " - VIMASS - VINA CA 協力協定",

    body_HopDong_text_thongbaogopvon:
      "～2023年8月出資及び2023年9月出資権のお知らせ",

    body_text_thietbi_tb_TailieukythuatveDNA: " - DNA に関する技術文書",

    body_text_thietbi_tb_baohanhhainam: "2年保証",

    body_text_thietbi_tb_baohanhmotnam: "1年保証",

    body_text_thietbi_tb_LinkNXP:
      " https://vimass.vn/vmbank/services/media/viewFile?id=NXP_Smart_Governance_Brochure.pdf",

    body_text_thietbi_tb_Ten: "  名前",

    body_text_thietbi_tb_Thietbibaohanh2nam: "デバイス",

    body_text_thietbi_tb_Ungdung: "  応用",

    body_text_thietbi_tb_Tinhnangkythuat: "技術的特徴",

    body_text_thietbi_tb_The2chip: "スマートカード（2チップ）",

    body_text_thietbi_tb_Gia100000160000dongthe:
      " 価格: 80.000 - 160.000 VND/カード",

    body_text_thietbi_tb_Tuytheodaily: " (ディーラーによって異なります)",

    body_text_thietbi_tb_Tangkembaodungthe: "ギフトカードバッグ",

    body_text_thietbi_tb_NXPKetnoiantoanchomotthegioithongminh:
      " -よりスマートな世界のための安全な接続",

    body_text_thietbi_tb_NXPDinhhinhchochinhphudientu:
      "NXP が電子政府市場を形作る",

    body_text_thietbi_tb_UngdungcuachipNXPUCODE:
      " -チップ NXP UCODE のアプリケーション",

    body_text_thietbi_tb_Chitiettunhasanxuat: " - NXP Semiconductors 社",

    body_text_thietbi_tb_ChitiettunhasanxuatAdvanced:
      " - Advanced Card Systems Ltd",

    body_text_thietbi_tb_MIFAREDESFireEV3ApplicationKeyFile:
      " - MIFARE DESFire EV3 アプリケーション キーとファイル",

    body_text_thietbi_tb_MIFAREDESFireEV3ICブロック図:
      " - MIFARE DESFire EV3 IC ブロック図",

    body_text_thietbi_tb_TailieukythuatveChipMifareDESFireEV38KB:
      " - Mifareの技術ドキュメント DESFire EV3 チップ 8KB",

    body_text_thietbi_tb_TailieukythuatveUCODE9: " - UCODE 9 の技術的特徴",

    body_text_thietbi_tb_TinhNangBaoMatEV3: " - EV3 の機能と利点",

    body_text_thietbi_tb_The3chipkyso: "スマートカード（3チップ）",

    body_text_thietbi_tb_Gia150000210000dongthe:
      " 価格: 130.000 - 210.000 VND/カード",

    body_text_thietbi_tb_UngdungcuachipkysoACOS564: " - ACOS5-64",

    body_text_thietbi_tb_TinhnangkythuatveUCODE9: " - UCODE 9 の技術的特徴",

    body_text_thietbi_tb_TemUCODERF: "タグRFID",

    body_text_thietbi_tb_GiagomVAT30000den60000dong:
      " 価格(付加価値税を含む): 20.000 - 60.000 VND",

    body_text_thietbi_tb_GiagomVAT1200000dong:
      " 価格(付加価値税を含む): 1,500,000 VND",

    body_text_thietbi_tb_GiaThamKhao3000000dong:
      " スポンサー販売価格 (VAT を除く): 1,200,000 VND",

    body_text_thietbi_tb_giaThamKhao: "参考価格",

    body_text_thietbi_tb_giaThamKhao43: " 参考価格: 4.390.000 VND",

    body_text_thietbi_tb_giaThamKha549: " 参考価格: 5.490.000 VND",

    body_text_thietbi_tb_giaThamKhao649: " 参考価格: 6.490.000 VND",

    body_text_thietbi_tb_giaThamKhao10790: " 参考価格: 10.790.000 VND",

    body_text_thietbi_tb_Bankemthedanang1themuatoida2nhan:
      "マルチファンクションカード付きで販売（1枚のカードで最大2枚のラベルを購入）",

    body_text_thietbi_tb_SudungdanotoxemmaycothethaythetheVETCEpassbihong:
      "車のステッカー,時計の機械を使用します（壊れた VETC, Epass ___ カードを交換できます）",

    body_text_thietbi_tb_DungungdungQRVietnamdeghimaEPC:
      "モバイルアプリを使用して EPC コードを作成します:",

    body_text_thietbi_tb_KhongtrungvoimaEPCdacocuaVimass:
      " - Vimasの既存の EPC コードと同じではありません",

    body_text_thietbi_tb_PhaitrungvoimakhachdangsudungcuadonvikhacVETCEpass:
      " -他のユニット (VETC, Epass ___)で使用されている顧客コードと一致する必要があります",

    body_text_thietbi_tb_Kichthuocanten70x14mm: " - アンテナサイズ: 70x14mm",

    body_text_thietbi_tb_ChatlieuGiaytrang: " - 素材: コート紙",

    body_text_thietbi_tb_ThietbiphatUHFculy7mxuly1theUHFva20theUHF:
      " 7m の通信範囲を備えた UHF トランシーバーは,1 枚の UHF カードと 20 枚の UHF カードを処理します",

    body_text_thietbi_tb_GiagomVAT2500000ドン:
      " 価格(付加価値税を含む): 2,500,000 VND",

    body_text_thietbi_tb_thongsokythuat: " - UHF 送信機の仕様",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy7mxuly1the:
      " -通信距離 7m,カード 1 枚対応の UHF トランシーバーの仕様",

    body_text_thietbi_tb_ThongsokythuatthietthubiphatUHFculy7mxuly200the:
      " -通信距離7m,200枚カード対応のUHFトランシーバーの仕様",

    body_text_thietbi_tb_ThietbiphatUHFculy20mxuly1theUHFva200theUHF:
      " UHFトランシーバー 20 メートルの範囲は 1 枚の UHF カードと 200 枚の UHF カードを処理します",

    body_text_thietbi_tb_GiagomVAT4400000ドン:
      " 価格(付加価値税を含む): 4,400,000 VND",

    body_text_thietbi_tb_thongsokythuatthietbithuphatUHF: " - UHF 送信機の仕様",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly1the:
      " -通信距離 20m,カード 1 枚対応の UHF トランシーバーの仕様",

    body_text_thietbi_tb_ThongsokythuatthietbithuphatUHFculy20mxuly200the:
      " -通信範囲20m,200枚のカードに対応したUHFトランシーバーの仕様",

    body_text_thietbi_tb_VPhone22: " VPhone 22",

    body_text_thietbi_tb_GiagomVAT3000000ドン:
      " 価格(付加価値税を含む): 3.000.000 VND",

    body_text_thietbi_tb_VPhone22GSMA関連付け:
      " - VPhone 22 GSM アソシエーション",

    body_text_thietbi_tb_thongsokythuatVPhone22: " - 仕様 VPhone22",

    body_text_thietbi_tb_MT6762DCPU: " - MT6762D CPU",

    body_text_thietbi_tb_TechnicaldiagramMT6762: " - 技術図 MT6762",

    body_text_thietbi_tb_CompareCPUMT6762DwithSC9863:
      " - CPU MT6762D と SC9863 を比較",

    body_text_thietbi_tb_UserManual: " - ユーザーマニュアル",

    body_text_thietbi_tb_MotsodienthoaicoNFC:
      " 一部の携帯電話にはNFC があります: ",

    body_text_thietbi_tb_SamsungGalaxyM22: " - Samsung Galaxy M22",

    body_text_thietbi_tb_SamsungGalaxyA32: " - Samsung Galaxy A32",

    body_text_thietbi_tb_DienthoaiSamsungGalaxyNFC:
      " - Samsung 製の NFC を搭載した Samsung Galaxy S 携帯電話",

    body_text_thietbi_tb_SamsungGalaxyS6trolen: " - Galaxy S6 以降",

    body_text_thietbi_tb_iPhone7trolen:
      " - iPhone 7 以降の NFC を搭載した iPhone",

    body_text_thietbi_tb_VTablet22: " Vタブレット22",

    body_text_thietbi_tb_XiaomiRedmi12: "Xiaomi Redmi 12",

    body_text_thietbi_tb_Gia4290000: " 価格: 4,290,000 VND",

    body_text_thietbi_tb_ManhinhIPSLCD679FullHD: " -画面: IPS LCD6_79Full HD+",

    body_text_thietbi_tb_CamerasauChinh50MPPhu8MP2MPCameratruoc8MP:
      " -リアカメラ: メイン 50 MP およびセカンダリ 8 MP,2 MP; フロントカメラ: 8 MP",

    body_text_thietbi_tb_RAM4GB: " - RAM: 4 GB",

    body_text_thietbi_tb_Dungluongluutru128GB: " - ストレージ容量: 128 GB",

    body_text_thietbi_tb_SamsungGalaxyA33: "サムスンギャラクシーA33",

    body_text_thietbi_tb_gia5490000: " 価格: 5,490,000 VND",

    body_text_thietbi_tb_Manhinh64inchSuperAMOLEDFHD:
      " -画面: 6.4 インチ,Super AMOLED,FHD+,",

    body_text_thietbi_tb_Camerasau480MP80MP50MP20MPCameratruoc130MP:
      " -背面カメラ: 48_0 MP + 8_0 MP + 5_0 MP + 2.0 MP ; 前面カメラ: 13_0 MP",

    body_text_thietbi_tb_RAM6GB: " - RAM: 6 GB",

    body_text_thietbi_tb_iPhoneXcu: "iPhone X（中古）",

    body_text_thietbi_tb_Gia6490000: " 価格: 6,490,000 VND",

    body_text_thietbi_tb_Manhinh58inch: " - 画面: 5_8 インチ",

    body_text_thietbi_tb_Camerasau12MPCameratruoc7MP:
      " -リアカメラ: 12MP ; フロントカメラ: 7MP",

    body_text_thietbi_tb_RAM3GB: " - RAM: 3 GB",

    body_text_thietbi_tb_Dungluongluutru64GB: " - ストレージ容量: 64GB",

    body_text_thietbi_tb_httpscellphonescomvniphonex64gboldhtml:
      " - https://cellphones.com.vn/iphone-x-64-gb-old_html",

    body_text_thietbi_tb_iPhone11: " iPhone11",

    body_text_thietbi_tb_Gia10650000: " 価格: 10,650,000 VND",

    body_text_thietbi_tb_Manhinh61inch: " 画面: 6_1 インチ",

    body_text_thietbi_tb_Camerasau12MPCameratruoc12MP:
      " リアカメラ: 12MP ; フロントカメラ: 12MP",

    body_text_thietbi_tb_Maytinhmini7000000: "ミニ電卓",

    body_text_thietbi_tb_Gia7000000: " 価格 (付加価値税を含む): 9,000,000 VND",

    body_text_thietbi_tb_VPhone23: " VPhone 23",

    body_text_thietbi_tb_dtphankhucre: " (格安セグメント電話)",

    body_text_thietbi_tb_Manhinh: " - 6_0 インチ画面,解像度 1440x3200",

    body_text_thietbi_tb_Camera: " - カメラ: フロント 16MP,リア 32MP",

    body_text_thietbi_tb_Dungluong: " - 容量 8GB + 256GB (メモリカード)",

    body_text_thietbi_tb_linkmuahangthamkhao: " 参照購入リンク",

    body_text_thietbi_tb_CPUCeleronN5105Upto29GHz4Cores4:
      " - CPU: Celeron N5105 (最大 2.9GHz) 4 コア 4",

    body_text_thietbi_tb_VGAIntelUHDグラフィックス:
      " - VGA: インテル UHD グラフィックス",

    body_text_thietbi_tb_Ram8GB: " - RAM: 8GB",

    body_text_thietbi_tb_Ocung2TB: " - ハードドライブ: 2TB",

    body_text_thietbi_tb_MaytinhminiGia15000000: "ミニ電卓",

    body_text_thietbi_tb_Gia15000000: " 価格: 15,000,000 VND",

    body_text_thietbi_tb_CPUIntelCorei71260PUpto48GHz12nhan16luong:
      " - CPU: Intel Core i7-1260P (最大 4_8GHz) 12 コア 16 スレッド",

    body_text_thietbi_tb_VGAIrisXeGraphics: " - VGA: Iris Xeグラフィックス",

    body_text_thietbi_tb_GiagomVAT3200000dong:
      " 価格(付加価値税を含む): 3.200.000 VND",

    body_text_thietbi_tb_nfc: "NFC ",

    body_text_thietbi_tb_ThongsokythuatVTablet22: " -仕様VTablet 22",

    body_text_thietbi_tb_Giataitro80000:
      " スポンサーシッププログラム価格 (付加価値税を含む): 80,000 VND/カード",

    body_text_thietbi_tb_Giataitro130000:
      " スポンサープログラム価格 (付加価値税を含む): 130,000 VND/カード",

    body_text_thietbi_tb_Giataitro20000:
      " スポンサーシッププログラム価格 (付加価値税を含む): 20,000 VND/1 件",

    body_text_thietbi_tb_ManHinh101inch19201200HD:
      " - 10.1 インチ 1920*1200 HD スクリーン,",

    body_text_thietbi_tb_Front5MPBack8MP: " - 5MP フロント + 8MP バックカメラ",

    body_text_thietbi_tb_Dungluongluutru32GB: " - ストレージ容量: 32GB",

    body_text_thietbi_tb_VReader: " VReader ",

    body_text_thietbi_tb_GiagomVAT1850000dong:
      " 価格(付加価値税を含む): 1.850.000 VND",

    body_text_thietbi_tb_NXPPN7642AU: " - NXP-PN7642AU",

    body_text_thietbi_tb_NXPPN7462: " , NXP-PN7462",

    body_text_thietbi_tb_NXPAN12752: " , NXP-AN12752",

    body_text_thietbi_tb_NXPAN12057: " , NXP-AN12057",

    body_text_thietbi_tb_NXPAN10834: " , NXP-AN10834",

    body_text_thietbi_tb_ModulmaytinhNFCmahoaAES3DES:
      " : NFC コンピューター モジュール,AES 暗号化,3-DES_",

    body_text_thietbi_tb_ESPWROOM32: " - ESP-WROOM-32",

    body_text_thietbi_tb_ModulemaytinhnangluongthapketnoiWifiBluetoothRS485UARTWeigandvv:
      " :低エネルギーコンピュータモジュール, Wifi ,Bluetooth,RS485,UART, Weigand など_",

    body_text_thietbi_tb_FS01: " -FS-1",

    body_text_thietbi_tb_Cambienvantay5mucdabaomatluutrutoida1000mauvantay:
      ": 5 レベルのセキュリティを備えた指紋センサー,最大 1000 個の指紋サンプルを保存_",

    body_text_thietbi_tb_AngtenNFCwifidieukhiendongmodongcuaBluetoothRS485UARTWeigandnguon220V12V2A:
      " - NFC アンテナ, WiFi ,ドア開閉制御,Bluetooth,RS485,UART, Weigand ,220V-12V,2A 電源_",

    body_text_thietbi_tb_PhanmemtraodoidulieuvoithevadienthoaiketnoiWifiBluetoothRS485UARTWeiganddichvuchuyentienthanhtoandichvunganhang:
      " -ソフトウェア: カードや電話とのデータ交換, Wifiの接続,Bluetooth,RS485,UART, Weigand ,送金サービス,支払い_",

    body_text_thietbi_tb_ThenhomicroHikvisionHSTFC132GBClass1092MB:
      " Hikvision HS-TF-C1 32GB クラス 10 マイクロ メモリ カード (92MB/s)",

    body_text_thietbi_tb_GiagomVAT139000dong:
      " 価格(付加価値税を含む): 139.000 VND",

    body_text_thietbi_tb_Thongtinchitiet: " 詳細",

    body_text_thietbi_tb_Maytinhmini: "PCミニ",

    body_text_thietbi_tb_GiagomVAT9190000dong:
      "参考価格 (付加価値税を含む): 9_190.000 VND",

    body_text_thietbi_tb_BochiaTypeCra7congUSB30:
      "Type C スプリッターから 7 つの USB 3_0 ポートへ",

    body_text_thietbi_tb_GiagomVAT329000dong:
      " 参考価格 (VAT 含む): 329.000 VND",

    body_text_thietbi_tb_OcungSSDSamsung870QVO2TBSATAIII25inch:
      "Samsung 870 QVO 2TB SATA III 2.5 インチ SSD ハードドライブ",

    body_text_thietbi_tb_GiagomVAT4899000dong:
      " 参考価格 (VAT 含む): 4.899.000 VND",

    body_text_thietbi_tb_RamLaptopKingstonKVR26S19S68KVR26S19S888GB1x8GBDDR42666MHz:
      "Ramラップトップ キングストン (KVR26S19S6/8 / KVR26S19S8/8) 8GB (1x8GB) DDR4 2666MHz",

    body_text_thietbi_tb_GiagomVAT759000dong:
      " 参考価格 (VAT 含む): 759.000 VND",

    body_text_thietbi_tb_CapdulieuUSB20noidai: "延長USB 2.0データケーブル",

    body_text_thietbi_tb_Gia5m190000dongGia10m290000dong:
      " 参考価格 5 メートル: 190.000 ドン - 価格 10 メートル: 290.000 ドン",

    body_text_thietbi_tb_Thongtinchitietcap5m: " 5m_キャップの詳細",

    body_text_thietbi_tb_Thongtinchitietcap10m: " 10m_キャップの詳細",

    body_text_thietbi_tb_ThekysoACSACOS564: "ACS ACOS5-64 デジタル署名カード",

    body_text_thietbi_tb_GiagomVAT50000dong:
      " 価格(付加価値税を含む): 50.000 VND",

    body_text_thietbi_tb_Chinhsachbaomat: " - セキュリティポリシー",

    body_text_thietbi_tb_DaunoikhongdayVimassUSBTypeA:
      " Vimas USB Type-A ワイヤレス カード リーダー",

    body_text_thietbi_tb_GiagomVAT100000dong:
      " 価格(付加価値税を含む): 100.000 VND",

    body_text_thietbi_tb_Daunoicoday1mVimasUSBTypeA1khedocthe:
      "有線コネクタ 1m Vimas USB Type-A 1 カードリーダースロット",

    body_text_thietbi_tb_Daunoicoday1mVimassUSBTypeA1khedocthe1khedocSIM:
      " 有線コネクタ 1m Vimas USB Type-A カード リーダー スロット 1 つ,SIM リーダー スロット 1 つ",

    body_text_congty_cn_23517QuyetdinhvedailyvadongiathietbicuaVimass:
      " - 2023 年 5 月 17 日付けのVimass機器の代理店および単価の決定",

    body_text_congty_hdh_ntt: "トニー・グエン",

    body_text_congty_hdh_nmv: "フィリップ・グエン",

    body_text_congty_hdh_cvc: "ケビン・チュー",

    body_text_congty_hdh_tvt: "チャン・ベト・チュン",

    body_text_congty_hdh_ntnh: "グエン ティ・ゴック・ハ",

    body_text_congty_hdh_dht: "ディン ユイ トーアン",

    body_text_congty_hdh_ndt: "グエン デュイ シン",

    body_text_congty_hdh_nvt: "グエン・ヴァン・トゥ",

    body_text_congty_hdh_httt: "ホアン ティ ティエン・トラン",

    body_text_congty_hdh_tvtinh: "チン・ヴァン・ティン",

    body_text_congty_hdh_tnt: "チャンナムチュン",

    body_text_congty_hdh_tth: "トラン ティ・ヒエン",

    body_text_congty_hdh_lvt: "ル・ベトタン",

    body_text_congty_hdh_thb: "トラン ホアイバック",

    body_text_congty_hdh_tvh: "トラン・ベト・フン",

    body_text_thietbi_tb_MaytinCPUI7: "コンピュータCPU i7",

    body_text_thietbi_tb_Gia22374000:
      " 価格 (付加価値税を含む): 22,374,000 VND",

    body_text_thietbi_tb_MAINBOARD: "- メインボード ASUS PRIME B760M-A WIFI D4",

    body_text_thietbi_tb_CPUINTELCOREI7:
      " - CPU INTEL CORE I7-12700F (最大 4_8GHZ,12 回,25MB キャッシュ,125W) - ソケット INTEL LGA 1700)",

    body_text_thietbi_tb_TANNHIETKHI: " - ディープクール AK400",

    body_text_thietbi_tb_RAMKINGTON:
      "- RAM デスクトップ キングストン フューリー ビースト RGB 32GB (2X16GB) DDR4 3200MHZ",

    body_text_thietbi_tb_SSDKingston:
      "- キングストン NV2 M_2 PCIe Gen4 x4 NVMe SSD 2TB",

    body_text_thietbi_tb_NGUONMAYTINH: "- クーラーマスターコンピューターソース",

    body_text_thietbi_tb_VOCASE: " - CASE KENOO ESPORT FM800",

    body_text_thietbi_tb_baohanhbanam: "3年保証",

    body_text_ungdung_hethongthongtin: " 情報システム",
    header_text_theDaNang_ctThiDiem: "パイロットスクール",
 header_text_theDaNang_ctTaiTro: "スポンサースクール",
  },
};
